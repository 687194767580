import React, { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router";
import { IonAvatar, IonButton, IonContent, IonGrid, IonInput, IonItem, IonLabel, IonPage, } from "@ionic/react";
import { Header } from "../../components/generic/Index";

import "./PersonalData.css";

interface OwnProps extends RouteComponentProps { };

const PersonalData: React.FC<OwnProps> = ({ history }: any) => {

    return (
        <IonPage>
            <Header
                title="Datos personales"
                titleColor="primary"
                isBackButton={true}
                menuColor="primary"
            />
            <IonContent>
                <IonGrid className="grid-personaldata">
                    <IonAvatar className="avatar-personaldata">
                        <img alt="Silhouette of a person's head" src="https://ionicframework.com/docs/img/demos/avatar.svg" />
                    </IonAvatar>

                    <IonItem lines="none">
                        <IonLabel className="label-personaldata" position="stacked" color="medium">Nombre:</IonLabel>
                        <IonInput className="input-personaldata" autoCapitalize="words" autocomplete="name" clearInput enterkeyhint="next" inputMode="text" pattern="text" type="text"></IonInput>
                    </IonItem>

                    <IonItem lines="none">
                        <IonLabel className="label-personaldata" position="stacked" color="medium">Correo:</IonLabel>
                        <IonInput className="input-personaldata" autoCapitalize="off" autocomplete="email" clearInput enterkeyhint="next" inputMode="email" pattern="email" type="email"></IonInput>
                    </IonItem>

                    <IonItem lines="none">
                        <IonLabel className="label-personaldata" position="stacked" color="medium">Celular:</IonLabel>
                        <IonInput className="input-personaldata" autoCapitalize="off" autocomplete="tel" clearInput enterkeyhint="next" inputMode="tel" pattern="tel" type="tel"></IonInput>
                    </IonItem>

                    <IonItem lines="none">
                        <IonLabel className="label-personaldata" position="stacked" color="medium">Fecha de nacimiento:</IonLabel>
                        <IonInput className="input-personaldata" autoCapitalize="off" autocomplete="bday" clearInput enterkeyhint="next" inputMode="search" pattern="date" type="date"></IonInput>
                    </IonItem>

                    <hr /><hr /><hr />

                    <IonButton className="nextbutton-personaldata" shape="round" color="primary" routerDirection="forward">Siguiente</IonButton>

                </IonGrid>

            </IonContent>

        </IonPage >
    )
};

export default PersonalData;