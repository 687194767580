import React from 'react';

const MyLogo: React.FC = () => {

  return <img
    style={{
      display: 'block',
      marginLeft: 'auto',
      marginRight: 'auto',
      height: '12rem'
    }}

    alt='company logo'
    src='assets/img/icon_move.svg' />
};

export default MyLogo;
