import React from "react";
import { IonCard, IonCardContent, IonCardTitle, IonNote, IonSkeletonText } from "@ionic/react";

const MyClubCardSkeleton: React.FC = () => {

    return (
        <>
            <IonCard
                color={"light"}
                style={{
                    boxShadow: 'none',
                    backgroundColor: '',
                    position: 'relative',
                    height: '5.5rem',
                    marginBottom: '1.5rem'
                }}
            >
                <IonCardContent
                    style={{
                        position: 'absolute',
                        top: '0px',
                        textTransform: 'capitalize',
                        zIndex: 99
                    }}
                >
                    <IonCardTitle>
                        <IonSkeletonText animated style={{ width: '25vw', height: '24px' }} />
                    </IonCardTitle>
                    <IonNote>
                        <IonSkeletonText animated style={{ width: '60vw', height: '10px' }} />
                    </IonNote>
                </IonCardContent>
            </IonCard>

            <IonCard
                color={"light"}
                style={{
                    boxShadow: 'none',
                    backgroundColor: '',
                    position: 'relative',
                    height: '5.5rem',
                    marginBottom: '1.5rem'
                }}
            >
                <IonCardContent
                    style={{
                        position: 'absolute',
                        top: '0px',
                        textTransform: 'capitalize',
                        zIndex: 99
                    }}
                >
                    <IonCardTitle>
                        <IonSkeletonText animated style={{ width: '25vw', height: '24px' }} />
                    </IonCardTitle>
                    <IonNote>
                        <IonSkeletonText animated style={{ width: '60vw', height: '10px' }} />
                    </IonNote>
                </IonCardContent>
            </IonCard>

            <IonCard
                color={"light"}
                style={{
                    boxShadow: 'none',
                    backgroundColor: '',
                    position: 'relative',
                    height: '5.5rem',
                    marginBottom: '1.5rem'
                }}
            >
                <IonCardContent
                    style={{
                        position: 'absolute',
                        top: '0px',
                        textTransform: 'capitalize',
                        zIndex: 99
                    }}
                >
                    <IonCardTitle>
                        <IonSkeletonText animated style={{ width: '25vw', height: '24px' }} />
                    </IonCardTitle>
                    <IonNote>
                        <IonSkeletonText animated style={{ width: '60vw', height: '10px' }} />
                    </IonNote>
                </IonCardContent>
            </IonCard>
        </>


    )
};

export default MyClubCardSkeleton;