import {
  IonInput, IonItem,
  IonIcon
} from '@ionic/react';
import React from 'react';
import './MInput.css';

interface MInputProps {
  iconSlot?: string,
  iconSize?: string,
  iconColor?: string,
  iconInputClass?: string,
  icon: any,
  isSecondIcon?: boolean,
  secondIconSlot?: string,
  secondIconSize?: string,
  secondIconColor?: string,
  onclickSecondIcon?: () => any,
  secondIcon?: any,
  itemClass?: string,
  inputClass?: string,
  placeholder: string,
  inputType?: any,
  inputmode?: any,
  handleChangeValue?: (returnValue: any) => any,
  handleKeyPress?: (returnValue: any) => any,
  value?: string,
  inputID?: string,
  inputName?: string,
  clearInput?: boolean,
  isClearOnEdit?: boolean,
  isReadOnly?: boolean,
  isRequired?: boolean,
  isSpellcheck?: boolean,
  isAutocapitalize?: any,
  isAutocomplete?: any,
  isAutocorrect?: any,
  isAutofocus?: boolean,
  isDisabled?: boolean,
  enterkeyhint?: any,
  inputref?: () => any,
  minLength?: number,
  maxLength?: number,
};

const MInput: React.FC<MInputProps> = ({

  iconSlot = 'start',
  iconSize = 'default',
  iconColor = 'primary',
  iconInputClass = '',
  icon,
  isSecondIcon = false,
  secondIconSlot = 'end',
  secondIconSize = 'default',
  secondIconColor = 'primary',
  onclickSecondIcon = () => { },
  secondIcon = undefined,
  itemClass = 'defaultInput',
  inputClass = '',
  placeholder,
  inputType = 'text',
  inputmode = 'text',
  handleChangeValue = (returnValue: any) => { },
  handleKeyPress = (returnValue: any) => { },
  value = '',
  inputID = '',
  inputName = '',
  clearInput = false,
  isClearOnEdit = false,
  isReadOnly = false,
  isRequired = false,
  isSpellcheck = false,
  isAutocapitalize = 'off',
  isAutocomplete = 'off',
  isAutocorrect = 'off',
  isAutofocus = false,
  isDisabled = false,
  enterkeyhint = undefined,
  minLength = undefined,
  maxLength = undefined,
  inputref = () => { }

}) => {

  const _handleChangeValue = (e: any) => {
    handleChangeValue(e.detail.value!);
  }

  const _handleKeyPress = (e: any) => {
    handleKeyPress(e.key);
  }

  return (
    <IonItem lines='none' className={itemClass}>
      <IonIcon
        className={iconInputClass}
        slot={iconSlot}
        color={iconColor}
        icon={icon}
        size={iconSize}>
      </IonIcon>
      <IonInput className={inputClass}
        type={inputType}
        inputMode={inputType}
        placeholder={placeholder}
        value={value}
        onIonChange={(e) => _handleChangeValue(e)}
        id={inputID}
        name={inputName}
        clearInput={clearInput}
        clearOnEdit={isClearOnEdit}
        readonly={isReadOnly}
        required={isRequired}
        spellCheck={isSpellcheck}
        autoCapitalize={isAutocapitalize}
        autocomplete={isAutocomplete}
        autoCorrect={isAutocorrect}
        autofocus={isAutofocus}
        disabled={isDisabled}
        enterkeyhint={enterkeyhint}
        minlength={minLength}
        maxlength={maxLength}
        onKeyPress={(e) => _handleKeyPress(e)}>
      </IonInput>
      {isSecondIcon &&
        <IonIcon
          slot={secondIconSlot}
          color={secondIconColor}
          icon={secondIcon}
          size={secondIconSize}
          onClick={onclickSecondIcon}
        ></IonIcon>
      }
    </IonItem>
  );
};

export default MInput;