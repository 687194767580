import React from "react";

import "./ModalImage.css"

const ModalImage: React.FC<any> = ({ toggle, children }) => {
    // useEffect(() =>{},[image,alt])

    return (
        <div className="container-image-modal">
            {children}
            <div onClick={toggle} className="close-icon">
                <img src="/assets/icon/close.svg" alt="close icon" />
            </div>
        </div>
    )
}

export default ModalImage;