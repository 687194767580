import React from "react";
import { IonLabel, IonNote, IonRow } from "@ionic/react"

interface MyTitleProps {
    title?: string,
    titleColor?: "primary" | 'secondary' | 'tertiary' | 'success' | 'warning' | 'danger' | 'light' | 'medium' | 'dark' | 'none' | undefined,
    subtitle?: string,
    subtitleColor?: "primary" | 'secondary' | 'tertiary' | 'success' | 'warning' | 'danger' | 'light' | 'medium' | 'dark' | 'none' | undefined,
}

const MyTitle: React.FC<MyTitleProps> = ({

    title,
    titleColor = 'primary',
    subtitle,
    subtitleColor = 'medium',
}) => {

    return (
        <div style={{ margin: '1rem' }}>
            <IonRow>
                <IonLabel
                    children={title}
                    color={titleColor}
                    style={{
                        margin: 'auto',
                        fontSize: '34px',
                        fontWeight: '700',
                        textAlign: 'center'
                    }} />
            </IonRow>

            <IonRow>
                <IonNote
                    children={subtitle}
                    color={subtitleColor}
                    style={{
                        margin: 'auto',
                    }}
                />
            </IonRow>
        </div>
    )
};

export default MyTitle;