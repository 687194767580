import server from "../api/server";
export const checkToken = async (token: string) => {
    const headers = {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`,
    };

    try {
        const response = await server.post(
            `/auth/validate-token`,
            "",
            { headers: headers },
        );

        let data = response.data;

        if (data.statusCode == 200) {
            return true;
        }
    } catch (error: any) {
        if (error.response.status == 401) {
            // Close session
            throw new Error("Token expired");
        }
    }
}