import { IonCard, IonImg, IonCardContent, IonCardTitle, IonNote, IonList } from "@ionic/react";
import React from "react";

interface ClubInfo {
  id: number;
  picture: { imageString: string };
  name: string;
  address: string;
  hasConcurrence: boolean;
}

interface MyClubCardProps {
  info: ClubInfo[];
  showClub: (id: any) => void;
}

const MyClubCard: React.FC<MyClubCardProps> = ({
  info,
  showClub
}) => {

  return (

    <IonList>
      {info.map((club: ClubInfo) => (
        <IonCard
          key={club.id}
          onClick={() => showClub(club.id)}
          color={"medium"}
          style={{
            boxShadow: 'none',
            backgroundColor: '',
            position: 'relative',
            height: '5.5rem',
            marginBottom: '1.5rem'
          }} >
          <IonImg
            src={club.picture?.imageString}
            style={{ filter: 'brightness(0.4)' }}
          />

          <IonCardContent
            style={{
              position: 'absolute',
              top: '0px',
              textTransform: 'capitalize',
              zIndex: 99
            }}
          >
            <IonCardTitle
              children={club.name.toLowerCase()}
              color={"light"}
            />
            <IonNote
              children={club.address.toLowerCase()}
              style={{
                fontSize: '10px',
                color: 'white'
              }}
            />
          </IonCardContent>
        </IonCard>
      ))}
    </IonList>
  )
};

export default MyClubCard;