import {
  IonContent, IonPage, IonButton, IonGrid, IonRow, IonCol, IonIcon,
  IonAlert, IonLoading
} from '@ionic/react';
import React, { useState, useContext, useEffect } from 'react';
import Logo from '../../components/generic/MyLogo/Logo';
import { lockClosedOutline, eyeOutline, eyeOffOutline, alertCircleOutline } from 'ionicons/icons';
import { Header, MInput } from '../../components/generic/Index';
import { RouteComponentProps } from 'react-router-dom';
import { AppContext } from '../../data/state';
import { userIndividualPremium } from '../../Helpers/DevHelpers';
import server from '../../api/server';

import './SignIn.css';

interface OwnProps extends RouteComponentProps { };

const ChangePassword: React.FC<OwnProps> = ({ history }) => {
  const { state, dispatch } = useContext(AppContext);
  const [password, setPassword] = useState('');
  const [secondIcon, setSecondIcon] = useState(eyeOutline);
  const [isPasswordShow, setIsPasswordShow] = useState(false);
  const [inputPasswordType, setInputPasswordType] = useState('password');

  const [oldPassword, setOldPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [confirmPasswordShowPass, setConfirmPasswordShowPass] = useState(eyeOutline);
  const [isConfirmPasswordShow, setIsConfirmPasswordShow] = useState(false);
  const [inputConfirmPasswordType, setInputConfirmPasswordType] = useState('password');
  const [loginError, setLoginError] = useState(false);
  const [mismatchMess, setMismatchMess] = useState('');
  const [passMismatch, setPassMismatch] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [errorAtSaving, setErrorAtSaving] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [oldPasswordIcon, setOldPasswordIcon] = useState(eyeOutline);
  const [isShowOldPassword, setIsShowOldPassword] = useState(false);
  const [inputOldPasswordType, setInputOldPasswordType] = useState('password');

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${state.token}`
  };

  useEffect(() => {
    if (state.comeFromLogin) {
      setOldPassword(state.tempPassword);
    }
    if (state.comeFromLogin) {
      let menu = document.getElementById('moveMenu');
      menu?.setAttribute('disabled', 'disabled');
    }

  }, []);

  function showOldPassword() {
    if (!isShowOldPassword) {
      setOldPasswordIcon(eyeOffOutline);
      setInputOldPasswordType('text');
    } else {
      setOldPasswordIcon(eyeOutline);
      setInputOldPasswordType('password');
    }
    setIsShowOldPassword(!isShowOldPassword);
  };

  function showPassword() {
    if (!isPasswordShow) {
      setSecondIcon(eyeOffOutline);
      setInputPasswordType('text');
    } else {
      setSecondIcon(eyeOutline);
      setInputPasswordType('password');
    }
    setIsPasswordShow(!isPasswordShow);
  };

  function showConfirmPassword() {
    if (!isConfirmPasswordShow) {
      setConfirmPasswordShowPass(eyeOffOutline);
      setInputConfirmPasswordType('text');
    } else {
      setConfirmPasswordShowPass(eyeOutline);
      setInputConfirmPasswordType('password');
    }
    setIsConfirmPasswordShow(!isConfirmPasswordShow);
  };

  const _clickLogin = async (e: any) => {
    if ((password === confirmPassword) && oldPassword != '') {
      await updatePassword();
    }
    else {
      setMismatchMess("Las contraseñas no coinciden");
      setPassMismatch(true);
    }
  };

  const updatePassword = async () => {
    setShowLoading(true);
    const data = {
      Password: oldPassword,
      NewPassword: password
    };
    await server.put('/auth/change-password', JSON.stringify(data)
      , { headers: headers })
      .then((res) => {
        const response = res.data;
        dispatch({
          type: 'set-temp-password',
          tempPassword: ''
        });
        dispatch({
          type: 'set-come-from-login',
          comeFromLogin: false
        });
        setShowLoading(false);
        setOldPassword('');
        setPassword('');
        setConfirmPassword('');
        history.replace('/profile');
      })
      .catch((error) => {
        setErrorMessage('Error al cambiar contraseña');
        setErrorAtSaving(true);
      });
    setShowLoading(false);
  }

  return (
    <IonPage color='dark'>
      <Header color='dark'
        isBackButton={!state.comeFromLogin}
        headerClassname='ion-no-border'
        title={!state.comeFromLogin ? 'Cambio de contraseña' : ''}
        defaultHref='/configurations'
      />
      <IonContent color='dark'>
        <div className="centerContainer-withHeader">
          <Logo />

          {loginError && <IonGrid>
            <IonRow>
              <IonCol size='1' />
              <IonCol size='10'>
                <IonIcon icon={alertCircleOutline} color='primary' />
                &nbsp; Las contraseñas no coinciden.
              </IonCol>
              <IonCol size='1' />
            </IonRow>
          </IonGrid>}

          <br />
          <MInput
            inputID='oldPassword'
            inputName='old password'
            iconSize='small'
            iconInputClass='changePasswordIcon'
            icon={lockClosedOutline}
            isSecondIcon={true}
            secondIconSize='small'
            secondIcon={oldPasswordIcon}
            onclickSecondIcon={showOldPassword}
            itemClass='darkInput'
            inputClass='innerDarkInput'
            placeholder='Contraseña Actual'
            value={oldPassword}
            handleChangeValue={setOldPassword}
            inputType={inputOldPasswordType}
            isDisabled={state.comeFromLogin}
          />

          <MInput
            inputID='password'
            inputName='password'
            iconSize='small'
            iconInputClass='changePasswordIcon'
            icon={lockClosedOutline}
            isSecondIcon={true}
            secondIconSize='small'
            secondIcon={secondIcon}
            onclickSecondIcon={showPassword}
            itemClass='darkInput'
            inputClass='innerDarkInput'
            placeholder='Nueva Contraseña'
            value={password}
            handleChangeValue={setPassword}
            inputType={inputPasswordType}
          />

          <MInput
            inputID='confirmPassword'
            inputName='confirmPassword'
            iconSize='small'
            iconInputClass='changePasswordIcon'
            icon={lockClosedOutline}
            isSecondIcon={true}
            secondIconSize='small'
            secondIcon={confirmPasswordShowPass}
            onclickSecondIcon={showConfirmPassword}
            itemClass='darkInput'
            inputClass='innerDarkInput'
            placeholder='Confirmar Nueva Contraseña'
            value={confirmPassword}
            handleChangeValue={setConfirmPassword}
            inputType={inputConfirmPasswordType}
          />

          <br />
          <IonButton className='submitButton' shape='round' expand='block' color='primary'
            disabled={confirmPassword === '' || password === ''}
            onClick={(e) => _clickLogin(e)}
          >
            Cambiar Contraseña
          </IonButton>
        </div>
        <IonAlert
          isOpen={passMismatch}
          onDidDismiss={() => { }}

          header={'Error!'}
          message={mismatchMess}
          buttons={[{
            text: 'Aceptar',
            role: 'Ok',
            handler: () => setPassMismatch(false)
          }]}
          backdropDismiss={false}
        />
        <IonLoading
          cssClass='my-custom-class'
          isOpen={showLoading}
          onDidDismiss={() => { }}
          message={'Guardando...'}
          duration={0}
        />
        <IonAlert
          isOpen={errorAtSaving}
          onDidDismiss={() => { }}
          cssClass='my-custom-class'
          header={'Error!'}
          message={errorMessage}
          buttons={[{
            text: 'Aceptar',
            role: 'Ok',
            handler: () => setErrorAtSaving(false)
          }]}
          backdropDismiss={false}
        />

      </IonContent>
    </IonPage>
  );
};

export default ChangePassword;
