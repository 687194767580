import React, { useState, useContext, useEffect } from "react";
import { IonPage, IonRow, IonIcon, IonLabel, IonItem, IonCard, IonList, IonAccordionGroup, IonAccordion, IonButton, useIonAlert, } from "@ionic/react";
import { MyContent, MyLoading, MyTitle } from "../../components/generic/Index";
import { bicycle, chevronForwardOutline, location, timeOutline, star, people, call, logoUsd, water, } from "ionicons/icons";
import { RouteComponentProps, useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";
import { isPlatform } from "@ionic/react";
import { v4 as uuidv4 } from "uuid";
import { AppContext } from "../../data/state";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination } from "swiper";
import "swiper/swiper-bundle.css";
import Header from "../../components/generic/Header/Header";
import ClubConcurrence from "./ClubConcurrence";
import ModalImage from "../../components/generic/ModalImage/ModalImage";
import server from "../../api/server";

import "./Club.css";

isPlatform("ios"); // returns true when running on a iOS device

SwiperCore.use([Pagination]);

interface OwnProps extends RouteComponentProps { }

const Club: React.FC<OwnProps> = ({ history }) => {
  const location = useLocation();
  let { id } = useParams<{ id: any }>();
  const { state } = useContext(AppContext);
  const refSwiperView: any = React.useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const toggleLoading = () => { setIsLoading(prevState => !prevState); };
  const [club, setClub] = useState(undefined);
  const [clubName, setClubName] = useState("");
  const [clubAddress, setClubAddress] = useState("");
  const [populationList, setPopulationList] = useState(true);
  const [planList, setPlanList] = useState(true);
  const [contactList, setContactList] = useState(true);
  const [clubPhoneNumber, setClubPhoneNumber] = useState("");
  const [clubImages, setClubImages] = useState([]);
  const [showSchedule, setShowSchedule] = useState(true);
  const [scheduleList, setScheduleList] = useState([]);
  const [image, setImage] = useState("");
  const [showModalImage, setShowModalImage] = useState(false);
  const [totalImages, setTotalImages] = useState(0);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [pools, setPools] = useState([]);
  const [hasConcurrence, setHasConcurrence] = useState(false);
  const [present] = useIonAlert();

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    getClubInfoByID();
  }, []);

  const getImagesFromIdPage = async (id: any, idPage: any) => {
    try {
      const res = await server.get(`/club/${id}/images/${idPage}`);
      const response = res.data;
      setClubImages((prevImages) => [...prevImages, ...response.data.images]);
      setTotalImages(response.data.totalImages);
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  const getClubInfoByID = async () => {
    setIsLoading(true);
    try {
      const res = await server.get(`/club/${id}`);
      const response = res.data;

      setClub(response.data);
      setClubName(response.data.name);
      setClubPhoneNumber(response.data.phoneNumber);
      setClubAddress(response.data.address);
      setHasConcurrence(response.data.hasConcurrence);
      setPools(response.data.pools);

      for (let i = 0; i < 2; i++) {
        await getImagesFromIdPage(id, i);
      }

      try {
        const res = await server.get(`/club/${id}/schedule`)
        const response = res.data;
        setScheduleList(response.data);
      } catch(error: any) {
        console.log(error);
      }
    } catch (error: any) {
      console.log(error);
    }
    setIsLoading(false);
  };

  const renderClubImages = (image: string) => {
    setImage(image);
    setShowModalImage(true);
  };

  const chatWhatsapp = () => {
    // Open chat in whatsapp in ionic
    window.open(`whatsapp://send?phone=${clubPhoneNumber}`, "_system");
  };

  function padTo2Digits(num: any) {
    return String(num).padStart(2, "0");
  }

  function reservePool(pool: any) {
    if (!state.isLoggedin) {
      present({
        header: "Error",
        message: "Debes iniciar sesión para reservar una piscina",
        buttons: ["OK"],
      });
      return;
    }
    present(pool.description, [
      {
        text: "Cancelar",
        role: "cancel",
        cssClass: "secondary",
        handler: (blah) => {
          console.log("Confirm Cancel");
        },
      },
      {
        text: "OK",
        role: "confirm",
        handler: () => {
          history.push(`/clublist/${id}/reserve/${pool.calendarID}`);
        },
      },
    ]);
  }

  return (
    <IonPage>
      {!showModalImage && isPlatform("ios") ? (
        <>
          <Header
            title={clubName}
            titleColor="primary"
            isBackButton={true}
            defaultHref="/clublist"
          />
        </>
      ) : (
        <>
          {!showModalImage && (
            <Header
              title={clubName}
              titleColor="primary"
              defaultHref="/clublist"
            />
          )}
        </>
      )}

      <MyContent>

        <div
          className="slide-box"
          style={{ display: `${showModalImage ? "none" : ""}` }}
        >
          <Swiper
            pagination
            onSwiper={(swiper) => {
              refSwiperView.current = swiper;
            }}
            onSlideChange={async (e) => {
              // Add loading when slide change
              if (
                e.activeIndex === clubImages.length - 1 &&
                totalImages > clubImages.length
              ) {
                setIsLoading(true);
                await getImagesFromIdPage(id, clubImages.length);
                setIsLoading(false);
              }
              setCurrentImageIndex(e.activeIndex);
            }}
          >
            {clubImages !== null &&
              clubImages.map((element: any) => {
                return (
                  <SwiperSlide
                    key={`slide-${uuidv4()}`}
                    className="img-container-slider"
                  >
                    <img
                      src={element.imageString}
                      alt={element.clubID}
                      className="upper-corners-rounded"
                      onClick={() => renderClubImages(element.imageString)}
                    />
                    {/* Create loader for images request */}
                  </SwiperSlide>
                );
              })}
          </Swiper>
        </div>

        {showModalImage && (
          <ModalImage toggle={() => setShowModalImage((value) => !value)}>
            <Swiper
              pagination
              initialSlide={currentImageIndex}
              onSlideChange={async (e) => {
                // Add loading when slide change
                if (
                  e.activeIndex === clubImages.length - 1 &&
                  totalImages > clubImages.length
                ) {
                  setIsLoading(true);
                  await getImagesFromIdPage(id, clubImages.length);
                  setIsLoading(false);
                }
                refSwiperView.current.slideTo(e.activeIndex);
                setCurrentImageIndex(e.activeIndex);
              }}
            >
              {clubImages !== null &&
                clubImages.map((element: any) => {
                  return (
                    <SwiperSlide key={`slide-${uuidv4()}`}>
                      <img
                        src={element.imageString}
                        alt={element.clubID}
                        className="image-modal-swiper"
                        onClick={() => renderClubImages(element.imageString)}
                      />
                      {/* Create loader for images request */}
                    </SwiperSlide>
                  );
                })}
            </Swiper>
          </ModalImage>
        )}

          <MyTitle subtitle="Información General" subtitleColor="dark" />

        <IonCard>
          <IonAccordionGroup animated expand="compact">
            <IonItem lines="none" className="small-text" onClick={() => history.push(`/clublist/${id}/map`)}>
              <IonIcon color="primary" icon={location} className="left-icon" />{clubAddress.toLocaleLowerCase()}
              <IonIcon icon={chevronForwardOutline} />
            </IonItem>

            <IonAccordion value="2">
              <IonItem
                slot="header"
                lines="none"
                color="none"
                className="small-text"
              >
                <IonIcon color="primary" icon={call} className="left-icon" />
                <IonLabel>Contacto</IonLabel>
              </IonItem>

              <IonList slot="content" onClick={chatWhatsapp}>
                <div className="wrapper-list">
                  <div className="wrapper-icon-whatsapp">
                    <img src="/assets/icon/whatsapp.svg" alt="whatsapp" />
                    <span className="label-Whatsapp">Whatsapp</span>
                  </div>
                  <IonLabel color="primary">{clubPhoneNumber}</IonLabel>
                </div>
              </IonList>
            </IonAccordion>

            <IonAccordion value="3">
              <IonItem
                slot="header"
                lines="none"
                button
                className="small-text"
              >
                <IonIcon
                  color="primary"
                  icon={timeOutline}
                  className="left-icon"
                />
                <IonLabel>Horario</IonLabel>
              </IonItem>

              <IonList slot="content">
                {scheduleList !== null &&
                  scheduleList.map((element: any) => {
                    const opening = new Date(
                      `August 19, 1975 ${element.opening}`
                    );
                    const closing = new Date(
                      `August 19, 1975 ${element.closing}`
                    );
                    return (
                      <IonItem
                        lines="none"
                        key={uuidv4()}
                      >
                        <IonLabel>
                          <p>{element.day}</p>
                        </IonLabel>
                        <IonLabel>
                          <p>
                            {padTo2Digits(opening.getHours()) +
                              ":" +
                              padTo2Digits(opening.getMinutes())}{" "}
                            -{" "}
                            {padTo2Digits(closing.getHours()) +
                              ":" +
                              padTo2Digits(closing.getMinutes())}{" "}
                            hrs.
                          </p>
                        </IonLabel>
                      </IonItem>
                    );
                  })}
              </IonList>
            </IonAccordion>

            <IonAccordion hidden={hasConcurrence === false} value="4" color="primary">
              <IonItem slot="header" lines="none" className="small-text">
                <IonIcon
                  color="primary"
                  icon={people}
                  className="left-icon"
                />
                <IonLabel>Concurrencia</IonLabel>
              </IonItem>

              {
                hasConcurrence &&
                  <div className="ion-padding" slot="content">
                    <IonList>
                      <ClubConcurrence clubId={id} />
                    </IonList>
                  </div>
              }
            </IonAccordion>

            <IonItem
              lines="none"
              onClick={() => history.push(`/clublist/${id}/services`)}
              className="small-text"
            >
              <IonIcon color="primary" icon={star} className="left-icon" />
              Servicios
              <IonIcon icon={chevronForwardOutline} />
            </IonItem>

            <IonItem
              lines="none"
              onClick={() =>
                history.push(`/clublist/${id}/plans`, { clubName })
              }
              className="small-text"
            >
              <IonIcon color="primary" icon={logoUsd} className="left-icon" />
              Planes
              <IonIcon icon={chevronForwardOutline} />
            </IonItem>

            <IonItem
              lines="none"
              onClick={() =>
                history.push(`/clublist/${id}/lessons`, { clubName })
              }
              className="small-text"
            >
              <IonIcon color="primary" icon={bicycle} className="left-icon" />
              Clases
              <IonIcon icon={chevronForwardOutline} />
            </IonItem>

            {pools.length !== 0 && (
              <>
                <IonAccordion hidden value="10" color="primary">
                  <IonItem
                    slot="header"
                    lines="none"
                    button
                    className="small-text"
                  >
                    <IonIcon
                      color="primary"
                      icon={water}
                      className="left-icon"
                    />
                    Alberca
                  </IonItem>

                  <div
                    className="ion-padding grid-buttons-pool"
                    slot="content"
                  >
                    <>
                      {pools.map((pool: any) => {
                        return (
                          // Create button with pool name
                          <IonButton
                            key={uuidv4()}
                            onClick={() => reservePool(pool)}
                          >
                            {pool.name}
                          </IonButton>
                        );
                      })}
                    </>
                  </div>
                </IonAccordion>
              </>
            )}
          </IonAccordionGroup>
        </IonCard>

      </MyContent>

      <MyLoading
        isOpen={isLoading}
        toggleLoading={toggleLoading}
      />

    </IonPage>
  );
};

export default Club;
