import React from "react";
import { IonLoading, IonicSafeString, SpinnerTypes } from "@ionic/react";

import './MyLoading.css';

interface MyLoadingProps {
    isOpen?: boolean;
    toggleLoading: () => void;
    message?: string | IonicSafeString;
    duration?: number,
    spinner?: SpinnerTypes | null,
}

const MyLoading: React.FC<MyLoadingProps> = ({
    isOpen = false,
    toggleLoading,
    message = 'Cargando...',
    duration = 0,
    spinner = 'crescent',

}) => {

    return (

        <IonLoading
            isOpen={isOpen}
            cssClass='loading-style'
            animated
            message={message}
            duration={duration}
            spinner={spinner}
            keyboardClose
            showBackdrop
            //onDidDismiss={toggleLoading}
        />
    )
};

export default MyLoading;