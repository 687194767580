import React from "react";
import {
  IonAccordion,
  IonCard,
  IonItem,
  IonNote,
  IonSkeletonText,
} from "@ionic/react";

interface PaymentItemSkeletonProps {
  count: number;
}

const PaymentItemSkeleton: React.FC<PaymentItemSkeletonProps> = ({ count }) => {
  const skeletons = [];


  for (let i = 0; i < count; i++) {
    <IonCard></IonCard>

    skeletons.push(
      <IonCard
        key={i}
        color="light"
        style={{
          boxShadow: "none",
          backgroundColor: "",
          position: "relative",
          height: "5.5rem",
          marginBottom: "10px",
        }}
      >
        <IonAccordion readonly>
          <IonItem slot="header" color={'light'} lines="none">
            <IonSkeletonText animated style={{ width: "25vw", height: "24px" }} />
          </IonItem>

          <div slot="content" className="ion-padding">
          </div>
        </IonAccordion>

        <IonItem color={'light'} lines="none">
          <IonNote>
            <IonSkeletonText animated style={{ width: "50vw", height: "10px" }} />
            <IonSkeletonText animated style={{ width: "50vw", height: "10px" }} />
          </IonNote>
        </IonItem>
        
      </IonCard>
    );
  }

  return <>{skeletons}</>;
};

export default PaymentItemSkeleton;
