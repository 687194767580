import React, { useEffect, useState, useContext, } from "react";
import { RouteComponentProps } from "react-router";
import { IonItem, IonLabel, IonPage, IonSelect, IonSelectOption, IonTextarea, IonInput, IonList, IonListHeader, IonCard } from "@ionic/react";
import { Header, MyButton, MyContent, MyLoading, MyTitle } from "../../components/generic/Index";
import server from "../../api/server";
import { UserTraining } from "../../Models/Training/UserTraining";
import { AppContext } from "../../data/state";

interface OwnProps extends RouteComponentProps { };

const Conditioning: React.FC<OwnProps> = ({ history }: any) => {
    const { state } = useContext(AppContext);

    const [isLoading, setIsLoading] = useState(false);
    const toggleLoading = () => { setIsLoading(prevState => !prevState); };
    const [trainigObjectives, setTrainigObjectives] = useState([]);
    const [objectiveID, setObjectiveID] = useState(Number);
    const [observations, setObservations] = useState("");
    const [hasTrainedBefore, setHasTrainedBefore] = useState(false);
    const [trainingDays, setTrainingDays] = useState("");
    const [trainingHours, setTrainingHours] = useState("");
    const [isPregnant, setIsPregnant] = useState(false);
    const [pregnantWeeks, setPregnantWeeks] = useState("");
    const [isMale, setIsMale] = useState(false);

    const [hasInjuries, setHasInjuries] = useState("");
    const [injuries, setInjuries] = useState("");
    const [hasChronicIllness, setHasChronicIllness] = useState("");
    const [chronicIllness, setChronicIllness] = useState("");
    const [hasAllergies, setHasAllergies] = useState("");
    const [allergies, setAllergies] = useState("");
    const [fcr, setFcr] = useState("");

    useEffect(() => {
        getTrainingObjetives();

        if (state && state.user.gender === "HOMBRE") {
            setIsMale(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getTrainingObjetives = async () => {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${state.token}`,
        };

        await server
            .get(`/customer/training/training-objectives`, { headers: headers })
            .then(async (res: any) => {
                const response = res.data;
                if (response.statusCode !== 200) {
                    console.log("error")
                    return false;
                }
                setTrainigObjectives(response.data);
            })
            .finally(() => {
            })
            .catch((error) => {
                console.log(error)
            })
    };

    const handleFcrChange = (value: any) => {
        const numericValue = parseInt(value);
        if (!isNaN(numericValue) && numericValue >= 50 && numericValue <= 220) {
            setFcr(value);
        }
    };

    const createRoutine = async () => {
        toggleLoading();

        const data: UserTraining = {
            objectiveID: objectiveID,
            observations: observations,
            hasTrainedBefore: hasTrainedBefore,
            trainingDays: trainingDays,
            trainingHours: trainingHours,
            fcr: fcr,
            isPregnant: isPregnant,
            pregnantWeeks: isPregnant ? pregnantWeeks : null,
            injuries: injuries,
            chronicIllness: chronicIllness,
            allergies: allergies,
        };

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${state.token}`,
        };

        await server
            .post(`/customer/training/user-training`, JSON.stringify(data), { headers: headers })
            .then((res) => {
                const response = res.data;
                if (response.statusCode !== 200) {
                    console.log('error');
                    return false;
                }
                //message all ok
                history.replace("/createRoutine");
                toggleLoading();
            })
            .catch((error) => {
                //message error
                console.warn(error);
                toggleLoading();
            })
    };

    return (
        <IonPage>
            <Header
                title="Acondicionamiento"
                titleColor="primary"
                isBackButton={true}
                defaultHref="/routine"
            >
            </Header>

            <MyContent>

                <MyTitle title="Nueva rutina" />

                <IonCard style={{ margin: 0 }} >
                    <IonList lines="inset">

                        <IonListHeader children={'Información basica'} />

                        <IonItem>
                            <IonLabel
                                position="stacked"
                                color={'primary'}
                                children={'Objetivo'}
                            />
                            <IonSelect
                                onIonChange={(e) => setObjectiveID(e.target.value)}
                                value={objectiveID}
                                placeholder="Selecciona un objetivo"
                                interface='popover'
                                interfaceOptions={{ subHeader: 'Selecciona un objetivo' }}
                            >
                                {trainigObjectives.length !== 0 &&
                                    trainigObjectives.map((item: any) => {
                                        return (
                                            <IonSelectOption value={item.id} key={item.id} >{item.objective}</IonSelectOption>
                                        )
                                    })}
                            </IonSelect>
                        </IonItem>

                        <IonItem>
                            <IonLabel
                                position="stacked"
                                color={'primary'}
                                children={'Objetivos especificos'}
                            />
                            <IonTextarea
                                onIonChange={(e) => setObservations(e.detail.value!)}
                                placeholder="Cuentanos un poco sobre lo que deseas conseguir"
                                value={observations}
                                autoGrow
                                clearOnEdit={true} />
                        </IonItem>

                        <hr />

                        <IonListHeader children={'Cuestionario de salud'} />

                        <IonItem>
                            <IonLabel
                                position="stacked"
                                color={'primary'}
                                children={'¿Has entrenado antes?'}
                            />
                            <IonSelect
                                onIonChange={(e) => setHasTrainedBefore(e.target.value)}
                                value={hasTrainedBefore}
                                placeholder="Selecciona una opción"
                                interface='popover'
                                interfaceOptions={{ subHeader: 'Selecciona una opción' }}
                            >
                                <IonSelectOption value={"true"} children={'Si'} />
                                <IonSelectOption value={"false"} children={'No'} />
                            </IonSelect>
                        </IonItem>

                        <IonItem>
                            <IonLabel
                                position="stacked"
                                color={'primary'}
                                children={'¿Cuantos dias a la semana piensas entrenar?'}
                            />
                            <IonSelect
                                onIonChange={(e) => setTrainingDays(e.target.value)}
                                value={trainingDays}
                                placeholder="Selecciona una opción"
                                interface='popover'
                                interfaceOptions={{ subHeader: 'Selecciona una opción' }}
                            >
                                <IonSelectOption value={"1 a 2"} children={'1 a 2 dias'} />
                                <IonSelectOption value={"3 a 4"} children={'3 a 4 dias'} />
                                <IonSelectOption value={"5 a 6"} children={'5 a 6 dia'} />
                            </IonSelect>
                        </IonItem>

                        <IonItem>
                            <IonLabel
                                position="stacked"
                                color={'primary'}
                                children={'¿Cuanto tiempo?'}
                            />
                            <IonSelect
                                onIonChange={(e) => setTrainingHours(e.target.value)}
                                value={trainingHours}
                                placeholder="Selecciona una opción"
                                interface='popover'
                                interfaceOptions={{ subHeader: 'Selecciona una opción' }}
                            >
                                <IonSelectOption value={"30m a 60m"} children={'30 a 60 min.'} />
                                <IonSelectOption value={"60m a 90m"} children={'60 a 90 min.'} />
                                <IonSelectOption value={"90m a 120m"} children={'90 a 120 min.'} />
                            </IonSelect>
                        </IonItem>

                        <IonItem>
                            <IonLabel
                                position="stacked"
                                color={'primary'}
                                children={'Frecuencia cardiaca en reposo'}
                            />
                            <IonInput
                                onIonChange={(e) => handleFcrChange(e.detail.value)}
                                value={fcr}
                                placeholder="En latidos por minuto"
                                clearInput
                                type="number"
                                inputMode="numeric"
                                min={50}
                                max={220}
                                step="1"
                                enterkeyhint="next"
                            ></IonInput>
                        </IonItem>
                        {
                            !isMale ?
                                <IonItem>
                                    <IonLabel
                                        position='stacked'
                                        color='primary'
                                        children={'¿Estas embarazada?'}
                                    />
                                    <IonSelect
                                        onIonChange={(e) => setIsPregnant(e.target.value)}
                                        value={isPregnant}
                                        placeholder="Selecciona una opción"
                                        interfaceOptions={{ subHeader: 'Selecciona una opción' }}
                                        interface='popover' >
                                        <IonSelectOption value={true} children={'Si'} />
                                        <IonSelectOption value={false} children={'No'} />
                                    </IonSelect>
                                </IonItem>
                                : null
                        }
                        {
                            !isMale && isPregnant ?
                                <IonItem>
                                    <IonLabel
                                        position='stacked'
                                        color='primary'
                                        children={'¿Cuantas semanas de gestación?'}
                                    />
                                    <IonSelect
                                        onIonChange={(e) => setPregnantWeeks(e.target.value)}
                                        value={pregnantWeeks}
                                        placeholder="Selecciona una opción"
                                        interfaceOptions={{ subHeader: 'Selecciona una opción' }}
                                        interface='popover'
                                    >
                                        <IonSelectOption value={"0 a 12"} children={'0 a 12 semanas'} />
                                        <IonSelectOption value={"13 a 24"} children={'13 a 24 semanas'} />
                                        <IonSelectOption value={"25 a 36"} children={'25 a 36 semanas'} />
                                        <IonSelectOption value={"37 a mas"} children={'37 a más'} />
                                    </IonSelect>
                                </IonItem>
                                : null
                        }

                        <IonItem>
                            <IonLabel
                                position='stacked'
                                color='primary'
                                children={'¿Alguna lesión?'}
                            />
                            <IonSelect
                                onIonChange={(e) => setHasInjuries(e.target.value)}
                                value={hasInjuries}
                                placeholder="Selecciona una opción"
                                interfaceOptions={{ subHeader: 'Selecciona una opción' }}
                                interface='popover'
                            >
                                <IonSelectOption value={"true"} children={'Si'} />
                                <IonSelectOption value={"false"} children={'No'} />
                            </IonSelect>
                        </IonItem>

                        {hasInjuries === "true" && (
                            <IonItem>
                                <IonLabel
                                    position='stacked'
                                    color='primary'
                                    children={'¿Cual?'}
                                />
                                <IonInput
                                    onIonChange={(e) => setInjuries(e.detail.value!)}
                                    value={injuries}
                                    placeholder="Ejemplo: Rodilla izquierda, Tobillo derecho"
                                    clearInput type="text"
                                    inputMode="text"
                                    pattern="text"
                                    enterkeyhint="next">
                                </IonInput>
                            </IonItem>
                        )}

                        <IonItem>
                            <IonLabel
                                position='stacked'
                                color='primary'
                                children={'¿Alguna enfermedad cronica?'}
                            />
                            <IonSelect
                                onIonChange={(e) => setHasChronicIllness(e.target.value)}
                                value={hasChronicIllness}
                                placeholder="Selecciona una opción"
                                interfaceOptions={{ subHeader: 'Selecciona una opción' }}
                                interface='popover'
                            >
                                <IonSelectOption value={"true"} children={'Si'} />
                                <IonSelectOption value={"false"} children={'No'} />
                            </IonSelect>
                        </IonItem>

                        {hasChronicIllness === "true" && (
                            <IonItem>
                                <IonLabel
                                    position='stacked'
                                    color='primary'
                                    children={'¿Cual?'}
                                />
                                <IonInput
                                    onIonChange={(e) => setChronicIllness(e.detail.value!)}
                                    value={chronicIllness}
                                    placeholder="Ejemplo: Diabetes, Hipertensión, Asma"
                                    clearInput type="text"
                                    inputMode="text"
                                    pattern="text"
                                    enterkeyhint="next">
                                </IonInput>
                            </IonItem>
                        )}

                        <IonItem>
                            <IonLabel
                                position='stacked'
                                color='primary'
                                children={'¿Alguna alergia?'}
                            />
                            <IonSelect onIonChange={(e) => setHasAllergies(e.target.value)}
                                value={hasAllergies}
                                placeholder="Selecciona una opción"
                                interfaceOptions={{ subHeader: 'Selecciona una opción' }}
                                interface='popover'
                            >
                                <IonSelectOption value={"true"} children={'Si'} />
                                <IonSelectOption value={"false"} children={'No'} />
                            </IonSelect>
                        </IonItem>

                        {hasAllergies === "true" && (
                            <IonItem>
                                <IonLabel
                                    position='stacked'
                                    color='primary'
                                    children={'¿Cual?'}
                                />
                                <IonInput
                                    onIonChange={(e) => setAllergies(e.detail.value!)}
                                    value={allergies}
                                    placeholder="Ejemplo: Látex, Desinfectantes, Polvo"
                                    clearInput
                                    type="text"
                                    inputMode="text"
                                    pattern="text"
                                    enterkeyhint="next"
                                ></IonInput>
                            </IonItem>
                        )}
                    </IonList>

                </IonCard>

                <hr />
                <hr />

                <MyButton
                    title="Continuar"
                    expand="block"
                    onClick={() => createRoutine()}
                />

            </MyContent>

            <MyLoading
                isOpen={isLoading}
                toggleLoading={toggleLoading}
            />

        </IonPage>
    )
};

export default Conditioning;
