import React, { useEffect, useState, useContext } from "react";
import { IonAccordion, IonAccordionGroup, IonCard, IonChip, IonItem, IonLabel, IonPage } from "@ionic/react";
import { MyFloatingButton, MyTitle, MyModal, Header, MyContent, MyLoading, MyToast } from "../../components/generic/Index";
import { RouteComponentProps } from "react-router";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination } from "swiper";
import { AppContext } from "../../data/state";
import { analytics } from "ionicons/icons";
import { resulstDataNutrition } from "../../data/Nutrition/ResultsDictionary";
import server from "../../api/server";
import { useLocation } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

SwiperCore.use([Pagination]);

interface OwnProps extends RouteComponentProps { };

const Results: React.FC<OwnProps> = ({ history }) => {
  const { state } = useContext(AppContext);

  const headers = { 'Content-Type': 'application/json', 'Authorization': `Bearer ${state.token}` };

  const location = useLocation();

  const [isLoading, setIsLoading] = useState(false);
  const toggleLoading = () => { setIsLoading(prevState => !prevState); };

  const [sessionId, setSessionId] = useState(0);
  const [sessionType, setSessionType] = useState(0);
  const [sessionNumber, setSessionNumber] = useState("");
  const [sessionDate, setSessionDate] = useState("");
  const [toastMessage, setToastMessage] = useState("");
  const [isToastOpen, setIsToastOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [toastColor, setToastColor] = useState("");

  const [userGender, setUserGender] = useState(state.user.gender);
  const [mouldingData, setMouldingData] = useState<any[]>([]);
  const [photos, setPhotos] = useState<any[]>([]);

  useEffect(() => {
    if (location.state && location.state.sessionId) {
      (async () => {
        await getData(location.state.sessionId);
        setSessionId(location.state.sessionId);
        await setSessionNumber(location.state.sessionNumber);
        setSessionDate(location.state.sessionDate);
        await getPhotos(location.state.sessionId);
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getData = async (id: any) => {
    setIsLoading(true);

    try {
      const request = await server.get(`/customer/nutrition/consult/${id}`,
        { headers:headers });

        const response = request.data.data;

        if(userGender === "HOMBRE") {
          delete response.womenAthlete;
        } else {
          delete response.menAthlete;
        }
        delete response.bodyComposition;
        delete response.somatype;
        delete response.arm;
        delete response.calf;
        delete response.thigh;

        setSessionType(response.information.consultType.id);
        if(response.information.consultType.id === 2) {
          delete response.folds;
          delete response.diameters;
        }

        setMouldingData(response);
        setIsLoading(false);
    } catch (error: any) {
      console.warn(error);
      setMouldingData([]);
      setIsLoading(false);
    }
  };

  const getPhotos = async (sessionId: any) => {
    setIsLoading(true);
    try {
      const headers = {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${state.token}`,
      };

      const request = await server.get(`/customer/nutrition/photos/consult/${sessionId}`,
        { headers });
      const response = request.data;

      setPhotos(response.data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const checkTypeOf = (data: any, name: any) => {
    if (data === "string") {
      return data;
    }

    if (name === "icc") {
      return data.toFixed(2);
    }

    return data.toFixed(1);
  };

  const checkICERisk = (ICE: any) => {
    if (userGender == "HOMBRE") {
      if (ICE < 52.5) {
        return "Bajo riesgo";
      } else {
        return "Alto riesgo";
      }
    } else {
      if (ICE < 53.5) {
        return "Bajo riesgo";
      } else {
        return "Alto riesgo";
      }
    }
  };

  const checkICC_Risk = (ICC: any) => {
    if (userGender == "HOMBRE") {
      if (ICC < 0.9) {
        return "Bajo riesgo";
      } else {
        return "Alto riesgo";
      }
    } else {
      if (ICC < 0.75) {
        return "Bajo riesgo";
      } else {
        return "Alto riesgo";
      }
    }
  };

  const getAdornment = (data: any, key: any, value: any) => {
    if (data === "I") {
      if (key === "ice") {
        return checkICERisk(value);
      } else if (key === "icc") {
        return checkICC_Risk(value);
      }
    } else {
      return data;
    }
    return "";
  };

  const getColor = (text: any) => {
    if (text === "Bajo riesgo") {
      return "primary";
    } else if (text === "Alto riesgo") {
      return "danger";
    }

    return "primary";
  };

  const goToReport = () => {
    history.push('/nutrition/report', {
      sessionId: sessionId
    });
  };

  return (
    <IonPage>
      <Header
        title="Resultados"
        titleColor="primary"
        isBackButton={true}
        defaultHref="/nutrition"
      />
      <MyContent >
        <MyTitle
          title={`Sesion #${sessionNumber}`}
          subtitle={sessionDate}
        />
        <IonCard>
          {
            mouldingData && Object.entries(mouldingData as any).map(([key, value], index) => (
              key !== "information" && resulstDataNutrition.has(key) && value !== null && (
                <IonAccordionGroup key={uuidv4()} animated multiple expand="compact" >
                  <IonAccordion key={uuidv4()} value={key} >
                    <IonItem slot="header" lines="none">
                      <IonLabel
                        key={uuidv4()}
                        children={resulstDataNutrition.get(key).get("name")}
                      />
                    </IonItem>
                    <div key={uuidv4()} className="ion-padding" slot="content" color="dark">
                      {
                        Object.entries(value as any).map(([k, val], index) => (
                          val !== null && resulstDataNutrition.get(key).get(k) !== undefined && (
                            <IonItem key={uuidv4()} lines="none" >
                              <IonLabel
                                key={uuidv4()}
                                children={resulstDataNutrition.get(key).get(k).part}
                                style={{ fontSize: '15px' }}
                              />
                              <IonChip
                                key={uuidv4()}
                                children={val ? checkTypeOf(val, k) : ""}
                                color={"primary"}
                              />
                              {
                                resulstDataNutrition.get(key).get(k).unity !== "NA" &&
                                <IonChip
                                  key={uuidv4()}
                                  children={getAdornment(resulstDataNutrition.get(key).get(k).unity, k, val)}
                                  color={getColor(getAdornment(resulstDataNutrition.get(key).get(k).unity, k, val))}
                                />
                              }
                            </IonItem>
                          )
                        ))
                      }
                    </div>
                  </IonAccordion>
                </IonAccordionGroup>
              )
            ))
          }
          {
            photos.length !== 0 ?
              <IonAccordionGroup animated multiple expand="compact" >
                <IonAccordion onClick={() => setIsModalOpen(true)} readonly>
                  <IonItem slot="header" lines="none">
                    <IonLabel>Evidencia fotografica</IonLabel>
                  </IonItem>
                </IonAccordion>
              </IonAccordionGroup>
            :
              <MyTitle
                subtitle="Sesion sin fotos."
              />
          }
        </IonCard>
      </MyContent>
      {
        photos.length !== 0 ?
          <>
            <MyModal
              isOpen={isModalOpen}
              onDidDismiss={() => setIsModalOpen(false)}
              title="Evidencia fotografica"
            >
              <Swiper
                slidesPerView={1}
                pagination={true}
                history={{ key: "slide", }}
                grabCursor={true}
              >
                {
                  photos.map((item, index) => (
                    <SwiperSlide data-history={index} key={index}>
                      <div className="cardio-name-device ion-text-center" key={index}>{item._photoType.photoType}</div>
                      <img src={item.photoString} key={index} />
                    </SwiperSlide>
                  ))
                }
              </Swiper>
            </MyModal>
          </>
          : null
      }
      {
        sessionType !== 2 && sessionType !== 0 ?
          <MyFloatingButton
            icon={analytics}
            onClick={goToReport}
          />
        : null
      }
      <MyLoading
        isOpen={isLoading}
        toggleLoading={toggleLoading}
        duration={1000}
      />
      <MyToast
        isOpen={isToastOpen}
        onDismiss={() => setIsToastOpen(false)}
        message={toastMessage}
        color={toastColor}
      />
    </IonPage>
  );
};

export default Results;
