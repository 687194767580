import {
  IonContent, IonPage, IonButton,
  IonText, IonSelect, IonSelectOption,
  IonGrid, IonRow, IonCol,
  IonLoading, IonAlert, isPlatform,
  useIonViewWillEnter
} from '@ionic/react';
import React, { useContext, useEffect, useState } from 'react';
import { personOutline } from 'ionicons/icons';
import { Header, MInput } from '../../components/generic/Index';
import { RouteComponentProps, Link } from 'react-router-dom';
import { Keyboard } from '@capacitor/keyboard';
import { Club } from '../../Models/Club';
import server from '../../api/server';
import { DoSet } from '../../DBHandler/User';
import { AppContext } from '../../data/state';

import './SignUp.css';

interface OwnProps extends RouteComponentProps { };

const ForgortPassword: React.FC<OwnProps> = ({ history }) => {
  const { state, dispatch } = useContext(AppContext);
  const [user, setUser] = useState('');
  const [sucursal, setSucursal] = useState('');
  const [sucursalID, setSucursalID] = useState('');
  const [showLoading, setShowLoading] = useState(false);
  const [showFoundAlert, setShowFoundAlert] = useState(false);
  const [wasFound, setWasFound] = useState(false);
  const [showSearchingClubs, setShowSearchingClubs] = useState(true);
  const [showErrorAtSearch, setShowErrorAtSearch] = useState(false);
  const [notWasFoundMessage, setNotWasFoundMessage] = useState('No pudimos encontrar tu usuario, por favor vuelve a intentarlo o acude a una sucursal');
  const [sucursales, setSucursales] = useState<Club[]>([]);

  const wasFoundHeader = '¡Exito!';
  const wasFoundMessage = 'Te enviaremos un correo electrónico al correo registrado';
  const notWasFoundHeader = '¡Oops!';

  const searchForNewClubs = async () => {
    setShowSearchingClubs(true);
    await server.get('/club').then(async (res) => {
      const response = res.data;
      console.log(response);
      if (response.message != null || response.message != undefined) {
        // TODO review what is the error and dump into internal db for logs
        setShowErrorAtSearch(true);
        return false;
      }
      let clubsArr: Club[] = [];
      const fillClubsPromise = new Promise(async (resolve, reject) => {
        for await (let club of response.data) {
          clubsArr.push(club);
        }
        setSucursales(clubsArr);
        resolve();
      });
      await Promise.all([fillClubsPromise]);
    }).catch(() => {
      setShowErrorAtSearch(true);
    });
    setShowSearchingClubs(false);
  };

  useIonViewWillEnter(() => {
    searchForNewClubs();
  });

  useEffect(() => {
    let menu = document.getElementById('moveMenu');
    menu?.setAttribute('disabled', 'disabled');
  }, []);

  const _onSelect = (e: any) => {
    const suc = e.split('-');
    setSucursalID(suc[0]);
    setSucursal(e);
  }

  const _onSendData = async () => {
    setShowLoading(true);
    const userData = {
      userId: parseInt(user),
      clubId: parseInt(sucursalID),
    }
    const headers = {
      'Content-Type': 'application/json',
    }
    await server.post('/auth/reset-password', userData
    ).then((res) => {
      const response = res.data;
      if (response.statusCode != 200) {
        console.log('error');
        return false;
      }
      setWasFound(true);
      DoSet('SavedUserData', userData);
      dispatch({
        type: 'set-user-saved',
        userSaved: userData
      });

    }).catch((error) => {
      if (error.response) {
        const response = error.response;
        if (response) {
          setNotWasFoundMessage('No pudimos encontrar tu usuario, por favor vuelve a intentarlo o acude a una sucursal');
        } else {
          setShowErrorAtSearch(true);
        }
      }
      setWasFound(false);
      return false;
    });
    setShowFoundAlert(true);
    setShowLoading(false);
  };

  const _onDismissAlert = async () => {
    setShowLoading(false);
    setUser('');
    setSucursal('');
    const p1 = new Promise(async (resolve, reject) => {
      await setShowFoundAlert(false);
      await setTimeout(() => {
        resolve();
      }, 100);
    });
    await Promise.all([p1]).then(() => {
      if (wasFound)
        history.goBack();
    });
  };

  const _onDismissNoInternet = async () => {
    const p1 = new Promise(async (resolve, reject) => {
      await setShowErrorAtSearch(false);
      await setTimeout(() => {
        resolve();
      }, 100);
    });
    await Promise.all([p1]).then(() => {
      history.replace('/home');
    });
  };

  const handleKeyPress = (e: any) => {
    if (isPlatform('hybrid')) {
      if (e === 'Enter') {
        Keyboard.hide();
      }
    }
  }

  return (
    <IonPage >
      <Header title='Recuperar contraseña'
        titleColor='primary'
        headerClassname='ion-no-border'
        defaultHref='/signIn'
      />
      <IonContent >
        <div className="centerContainer-withHeader">
          <br />
          <MInput
            inputID='user'
            inputName='user'
            inputType='number'
            iconSize='large'
            icon={personOutline}
            placeholder='Número de socio'
            value={user}
            handleChangeValue={setUser}
            enterkeyhint={'next'}
            handleKeyPress={handleKeyPress}
          />

          <IonGrid>
            <IonRow>
              <IonCol size='3'></IonCol>
              <IonCol size='6'>
                <IonSelect id='clubsSelect' value={sucursal} mode='ios' placeholder='Sucursal'
                  onIonChange={e => _onSelect(e.detail.value)}
                  interface='popover'>
                  {sucursales.map((suc) => {
                    return <IonSelectOption key={suc.name} value={suc.id + '-' + suc.name}>
                      {suc.name}
                    </IonSelectOption>
                  })}
                </IonSelect>
              </IonCol>
              <IonCol size='3'></IonCol>
            </IonRow>
            <br />
            <IonRow>
              <IonCol size='2'></IonCol>
              <IonCol size='8'>
                <IonText>
                  <p className='ion'>
                    Debes estar previamente registrado en la app.
                  </p>
                </IonText>
              </IonCol>
              <IonCol size='2'></IonCol>
            </IonRow>

          </IonGrid>

          <br />
          <IonButton shape='round' expand='block' color='primary'
            disabled={sucursal === '' || user === ''}
            onClick={() => _onSendData()}>
            Recuperar contraseña
          </IonButton>

          <IonLoading
            cssClass='my-custom-class'
            isOpen={showLoading}
            onDidDismiss={() => setShowLoading(false)}
            backdropDismiss={false}
            message={'Validando...'}
            duration={0}
          />

          <IonLoading
            cssClass='my-custom-class'
            isOpen={showSearchingClubs}
            onDidDismiss={() => { }}
            message={'Actualizando las sucursales...'}
            duration={0}
          />

          <IonAlert
            isOpen={showFoundAlert}
            onDidDismiss={() => setShowFoundAlert(false)}
            cssClass='my-custom-class'
            header={wasFound ? wasFoundHeader : notWasFoundHeader}
            message={wasFound ? wasFoundMessage : notWasFoundMessage}
            buttons={[{
              text: 'Aceptar',
              role: 'Ok',
              handler: () => _onDismissAlert()
            }]}
            backdropDismiss={false}
          />

          <IonAlert
            isOpen={showErrorAtSearch}
            onDidDismiss={() => { }}
            cssClass='my-custom-class'
            header={'Error!'}
            message={'Parece que no pudimos establecer conexión con el servidor, intentalo de nuevo más tarde'}
            buttons={[{
              text: 'Aceptar',
              role: 'Ok',
              handler: () => _onDismissNoInternet()
            }]}
            backdropDismiss={false}
          />

        </div>

      </IonContent>
    </IonPage>
  );
};

export default ForgortPassword;
