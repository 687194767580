import React from 'react';
import { IonCard, IonCardContent, IonItem, IonIcon, IonText } from '@ionic/react'; // Asegúrate de importar los componentes adecuados
import { helpOutline } from 'ionicons/icons';


interface MyNoDataAvailableProps {
    icon?: any,
    text?: string,
}

const IconStyle = {
    fontSize: '2rem',
    textAlign: 'center',
    marginLeft: 'auto',
    marginRight: 'auto',
};

const textStyle = {
    textAlign: 'center',
    marginLeft: 'auto',
    marginRight: 'auto',
}


const NoDataAvailable: React.FC<MyNoDataAvailableProps> = ({
    icon = helpOutline,
    text = 'Placeholder text'
}) => {

    return (
        <IonCard button>
            <IonCardContent>
                <IonItem color="none" lines="none">
                    <IonIcon color="primary" icon={icon} style={IconStyle} />
                </IonItem>
                <IonItem color="none" lines="none">
                    <IonText style={textStyle}>
                        {text}
                    </IonText>
                </IonItem>
            </IonCardContent>
        </IonCard>
    );
}

export default NoDataAvailable;
