import {
  IonContent, IonIcon, IonItem,
  IonLabel, IonList, IonListHeader,
  IonMenu, IonMenuToggle,
  IonFooter, IonToolbar,
  IonButton,
} from '@ionic/react';

import React, { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { locationOutline, personOutline, cogOutline, walletOutline, barbellOutline, nutritionOutline } from 'ionicons/icons';
import { AppContext } from '../../../data/state';

import './Menu.css';

interface AppPage {
  url: string;
  icon: string;
  title: string;
}

const appPages: AppPage[] = [
  {
    title: 'Perfil',
    url: '/profile',
    icon: personOutline
  },
  {
    title: 'Entrenamiento',
    url: '/routine',
    icon: barbellOutline
  },
  {
    title: 'Nutrición',
    url: '/nutrition',
    icon: nutritionOutline
  },
  {
    title: 'Sucursales',
    url: '/clublist',
    icon: locationOutline
  },
  {
    title: 'Pagos',
    url: '/payments/history',
    icon: walletOutline
  },
  {
    title: 'Configuración',
    url: '/configurations',
    icon: cogOutline
  },
];

const Menu: React.FC = () => {
  const location = useLocation();
  const { state, dispatch } = useContext(AppContext);

  const closeMenu = () => {
    //@ts-ignore
    document.getElementById('moveMenu')?.close();
  };

  return (

    <IonMenu id='moveMenu' contentId="main" type="overlay">
      <IonContent>

        <IonList id="inbox-list">
          <br />

          <IonListHeader className='menuHeader'>Move Wellness</IonListHeader>
          {appPages.map((appPage, index) => {
            return (
              <IonMenuToggle key={index} autoHide={false}>
                <IonItem className={location.pathname === appPage.url ? 'menu-item selected' : 'menu-item '}
                  routerLink={appPage.url} routerDirection="none" lines="none" detail={false}>
                  <IonIcon color='primary' slot="start" icon={appPage.icon} className="menu-icon-item"/>
                  <IonLabel className="menu-label">{appPage.title}</IonLabel>
                </IonItem>
              </IonMenuToggle>
            );
          })}
        </IonList>

      </IonContent>

      <IonFooter className='ion-no-border fixed-footer ion-text-center'>
        <IonToolbar >
          <IonButton routerLink='/access' routerDirection="none" onClick={() => closeMenu()}>
            Generar Acceso al club
          </IonButton>
        </IonToolbar>
      </IonFooter>

    </IonMenu>
  );
};

export default Menu;
