import React, { useEffect, useState, useContext, useRef, } from "react";
import { RouteComponentProps } from "react-router";
import { IonCard, IonCardHeader, IonCardSubtitle, IonContent, IonFooter, IonHeader, IonItem, IonLabel, IonList, IonModal, IonPage, IonSelect, IonSelectOption, IonTitle, IonToolbar, IonAccordionGroup, IonAccordion, IonChip, IonButtons, IonCheckbox, IonListHeader, IonIcon } from "@ionic/react";
import { OverlayEventDetail } from '@ionic/core/components';
import { MyToast, Header, MyContent, MyTitle, MyButton, MyLoading } from "../../components/generic/Index";
import { AppContext } from "../../data/state";
import server from "../../api/server";
import moment from "moment";
import { barbellSharp } from "ionicons/icons";

interface OwnProps extends RouteComponentProps { };

const nameDays = ["Lunes", "Martes", "Miercoles", "Jueves", "Viernes", "Sabado", "Domingo"];
const trainingObjtiveAcronym = { 1: "+S", 2: "+F", 3: "+M" };
const MAXCHIPSNUMBER = 4;
var mapDays : any = [];
var weeksByRoutineAux = new Map();

const CreateRoutine: React.FC<OwnProps> = ({ history }) => {
  const { state } = useContext(AppContext);
  const headers = { 'Content-Type': 'application/json', 'Authorization': `Bearer ${state.token}` };

  const [isLoading, setIsLoading] = useState(false);
  const toggleLoading = () => { setIsLoading(prevState => !prevState); };
  const modalAddExercise = useRef<HTMLIonModalElement>(null);
  const [isModalAddExerciseOpen, setIsModalAddExerciseOpen] = useState(false);

  const [toastMessage, setToastMessage] = useState("");
  const [isToastOpen, setIsToastOpen] = useState(false);
  const [toastColor, setToastColor] = useState("");

  const [objectiveID, setObjectiveID] = useState();
  const [trainingCategoryList, setTrainingCategoryList] = useState([]);
  const [selectedCheckboxes, setSelectedCheckboxes] = useState<any>([]);
  const [selectedDay, setSelectedDay] = useState(Number);

  const [trainingSessionId, setTrainingSessionId] = useState<any>("");
  const [trainingSessionNumber, setTrainingSessionNumber] = useState<any>("");
  const [trainingSession, setTrainingSession] = useState<any>([]);

  const [currentSelectedWeek, setCurrentSelectedWeek] = useState<any>([]);

  const [weeksByRoutine, setWeeksByRoutine] = useState<any>();
  const [weeksOnDBCount, setWeeksOnDBCount] = useState(0);

  const [trainigObjectives, setTrainigObjectives] = useState([]);

  const [coachName, setCoachName] = useState("");

  useEffect(() => {
    weeksByRoutineAux.clear();

    (async () => {
      await getObjetiveId();
      await getTrainingObjetives();
      await getUserRoutinelist();
      await getTrainingCategoryList();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getObjetiveId = async () => {
    toggleLoading();

    try {
      const res = await server.get(`/customer/training/user-training`, 
        { headers: headers });

      const response = res.data;
      setCoachName(response.data.coachName);
      setObjectiveID(response.data.objectiveID);
      getPeriod(response.data.objectiveID);

      toggleLoading();
    } catch (error: any) {
      toggleLoading();
      setToastMessage("Hubo un problema de conexión, intente de nuevo más tarde.");
      setToastColor("danger");
      setIsToastOpen(true);
      console.log(error);
    }
  };

  const getUserRoutinelist = async () => {
    //Get User Sessions by given UserTrainingID
    try {
      const res = await server.get(`/customer/training/user-session`, 
        { headers: headers });
      const response = res.data;
      dataFormat(response.data);
      setWeeksOnDBCount(response.data.length);
    } catch (error: any) {
      setToastMessage("Hubo un problema de conexión, intente de nuevo más tarde.");
      setToastColor("danger");
      setIsToastOpen(true);
      console.log(error);
    }
  };

  const formatDate = (date: any) => moment(date).format("DD/MM");

  const dataFormat = (routines: any) => {
    routines &&
      routines.map((item: any) => {
        var days : any = [];
        item.categories?.map((exercises: any) => {
          if (days[exercises.day] === undefined) {
            days[exercises.day] = {
              chipsID: [exercises.trainingCategory.id],
              chipsAcronym: [exercises.trainingCategory.acronym + ', '],
              stringDate: formatDate(exercises.sessionDate),
            }
          } else {
            days[exercises.day].chipsID.push(exercises.trainingCategory.id);
            days[exercises.day].chipsAcronym.push(exercises.trainingCategory.acronym + ', ');
          }
        });

        days.forEach((item, index) => {
          var editLast = item.chipsAcronym[item.chipsAcronym.length - 1].slice(0, -2);
          item.chipsAcronym[item.chipsAcronym.length - 1] = editLast;
        });

        const weekName = `${trainingObjtiveAcronym[item.trainingSession.objectiveID]},
                            ${item.trainingSession.name}`;

        weeksByRoutineAux.set(item.id, {
          name: weekName,
          trainingSessionID: item.trainingSession.id,
          mapDays: days,
          isDisabled: true,
        });
      });

    setWeeksByRoutine(weeksByRoutineAux);
  };

  const getPeriod = async (id: any) => {
    //toggleLoading();
    try {
      const res = await server.get(
        `/customer/training/training-session?ObjectiveId=${id}`, 
        { headers: headers }
      );
      
      const response = res.data;
      setTrainingSession(response.data);
    } catch (error: any) {
      setToastMessage("Hubo un problema de conexión, intente de nuevo más tarde.");
      setToastColor("danger");
      setIsToastOpen(true);
      console.log(error);
    }
  };

  const getTrainingCategoryList = async () => {
    //Get List of Training Categories. E.g. Cardio, Functional, etc
    try {
      const res = await server.get(
        `/customer/training/training-category`, { headers: headers }
      );

      const response = res.data;
      setTrainingCategoryList(response.data);
    } catch (error: any) {
      setToastMessage("Hubo un problema de conexión, intente de nuevo más tarde.");
      setToastColor("danger");
      setIsToastOpen(true);
      console.log(error);
    }
  };

  const getTrainingObjetives = async () => {
    try {
      const res = await server.get(
        `/customer/training/training-objectives`, { headers: headers }
      );

      const response = res.data;
      setTrainigObjectives(response.data);
    } catch (error: any) {
      console.log(error)
    }
  };

  const handleAddRoutine = (id: any, sessionNumber: any) => {
    weeksByRoutineAux = weeksByRoutine;
    if (!id || id === 0) {
      setToastMessage("Primero debe agregar un periodo de entrenamiento.");
      setToastColor("danger");
      setIsToastOpen(true);
    } else {
      weeksByRoutineAux.set(weeksByRoutine.size, {
        name: sessionNumber,
        trainingSessionID: id,
        mapDays: [],
      });

      setWeeksByRoutine(new Map(weeksByRoutineAux));
      setToastMessage("Periodo agregado correctamente.");
      setToastColor("primary");
      setIsToastOpen(true);
    }
  };

  const removeCurrentWeek = (currentWeekIndex: number) => {
    weeksByRoutineAux = weeksByRoutine;
    weeksByRoutineAux.delete(currentWeekIndex);
    setWeeksByRoutine(new Map(weeksByRoutineAux));
  };

  const createRoutine = async () => {
    //toggleLoading();
    var newIndex = 0;
    var dataToSend = { "sessionRoutines": [] };

    weeksByRoutine &&
      Array.from(weeksByRoutine.entries()).map(([key, item], index) => {
        if (index >= weeksOnDBCount) {
          var routineDaysAux = Object.entries(item.mapDays).map(([day, data]) => {
            return {
              "day": day,
              "trainingCategoryID": data.chipsID,
            };
          });

          if (dataToSend["sessionRoutines"][newIndex] === undefined) {
            dataToSend["sessionRoutines"][newIndex] = {
              "trainingSessionID": item.trainingSessionID,
              "routineDays": routineDaysAux,
            }
          } else {
            dataToSend["sessionRoutines"][newIndex].routineDays.push(routineDaysAux);
          }
          newIndex++;
        }
      });

    try {
      const res =  await server.post(`/customer/training/user-session`, JSON.stringify(dataToSend), 
        { headers: headers });
      await auto();
      setTimeout(() => {
        history.goBack();
      }, 1100);
    } catch (error: any) {
      setToastMessage("Hubo un problema al realizar la acciòn. Inténtalo de nuevo mas tarde.");
      setToastColor("danger");
      setIsToastOpen(true);
      console.log(error);
    }
  };

  const auto = async () => {
    //toggleLoading();
    try {
      const res = await server.post(
        `/customer/training/session-routine/auto`, 
        JSON.stringify, 
        { headers: headers });
        
      const response = res.data;
      setToastMessage("Rutina creada correctamente.");
      setToastColor("primary");
      setIsToastOpen(true);
    } catch (error: any) {
      setToastMessage("Hubo un problema al realizar la acciòn. Inténtalo de nuevo mas tarde.");
      setToastColor("danger");
      setIsToastOpen(true);
      console.log(error);
    }
  };

  const openAddModal = (isDisabled: boolean, week: any, indexWeek: number, indexDay: number) => {
    if (!isDisabled) {
      week.index = indexWeek;
      setSelectedCheckboxes([]);

      if (weeksByRoutine.get(indexWeek).mapDays[indexDay] !== undefined) {
        setSelectedCheckboxes(weeksByRoutine.get(indexWeek).mapDays[indexDay].chipsID);
      }
      setCurrentSelectedWeek(week);
      setSelectedDay(indexDay);
      setIsModalAddExerciseOpen(true);
    }
  };

  const isCheckboxChecked = (id: any) => selectedCheckboxes.includes(id);

  const handleCheckboxChange = (id: any) => {
    const isChecked = selectedCheckboxes.includes(id);
    if (isChecked) {
      setSelectedCheckboxes(selectedCheckboxes.filter((item: any) => item !== id));
    } else {
      setSelectedCheckboxes([...selectedCheckboxes, id]);
    }
  };

  function onWillDismiss(ev: CustomEvent<OverlayEventDetail>) {
    setIsModalAddExerciseOpen(false);
  };

  function saveLocalDate() {
    weeksByRoutineAux = weeksByRoutine;
    var props = ['id', 'acronym'];

    var result = trainingCategoryList.filter(function (o1) {
      return selectedCheckboxes.some(function (o2) {
        return o1.id === o2;          // assumes unique id
      });
    }).map(function (o) {
      // use reduce to make objects with only the required properties
      // and map to apply this to the filtered array as a whole
      return props.reduce(function (newo, acronym) {
        newo[acronym] = o[acronym];
        return newo;
      }, {});
    });

    result.map((item) => {
      if (mapDays[selectedDay] === undefined) {
        mapDays[selectedDay] = {
          chipsAcronym: [item.acronym + ", "],
          chipsID: [item.id],
        };
      } else {
        mapDays[selectedDay].chipsAcronym.push(item.acronym + ", ");
        mapDays[selectedDay].chipsID.push(item.id);
      }
    });

    var editLast = mapDays[selectedDay].chipsAcronym[mapDays[selectedDay].chipsAcronym.length - 1].slice(0, -2);
    mapDays[selectedDay].chipsAcronym[mapDays[selectedDay].chipsAcronym.length - 1] = editLast;

    Object.entries(mapDays).map(([k, v]) => {
      weeksByRoutineAux.get(currentSelectedWeek.index).mapDays[k] = v;
    });

    mapDays = [];
    setWeeksByRoutine(new Map(weeksByRoutineAux));
    setSelectedCheckboxes([]);
    setIsModalAddExerciseOpen(false);
  };

  const onChangeSelectWeek = (id: any) => {
    setTrainingSessionId(prev => { return id });

    const item = trainingSession.find(item => item.id === id);
    setTrainingSessionNumber(prev => { return item.name });
    handleAddRoutine(id, item.name);
  };

  const handleObjectiveSelect = (id: any) => {
    setObjectiveID(id);
    getPeriod(id);
  };

  const fixIndexDay = (indexDay: number) => {
    if (indexDay === 6) {
      return 0;
    }

    return indexDay + 1;
  };

  return (
    <IonPage>
      <Header
        title="Crear rutina"
        titleColor="primary"
        isBackButton={true}
        defaultHref="/routine"
      >
      </Header>
      <MyContent >
        <MyTitle title="Crear rutina" />
        <IonCard style={{ marginLeft: 0, marginRight: 0 }}>
          <IonCardHeader>
            <IonCardSubtitle>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <IonIcon color="primary" className="left-icon" icon={barbellSharp} />
                <IonLabel color="dark">Coach</IonLabel>
              </div>
            </IonCardSubtitle>
          </IonCardHeader>
          <IonList lines="inset">
            <IonItem lines="none">
              <IonLabel
                style={{ textTransform: 'capitalize' }}
                color="dark"
                children={coachName ? coachName.toLowerCase() : "No tienes asignado un instructor."}
              />
            </IonItem>
            <IonItem >
              <IonLabel
                position='stacked'
                color='primary'
                children={'Objetivo'}
              />
              <IonSelect
                interfaceOptions={{ subHeader: 'Selecciona un objetivo' }}
                placeholder="Selecciona un objetivo"
                onIonChange={(e) => handleObjectiveSelect(e.target.value)}
                value={objectiveID}
                interface='popover'>
                {
                  trainigObjectives.length !== 0 &&
                  trainigObjectives.map((item: any) => {
                    return (
                      <IonSelectOption
                        value={item.id}
                        key={item.id}
                        children={item.objective}
                      />
                    )
                  })
                }
              </IonSelect>
            </IonItem>
            <IonItem >
              <IonLabel
                position="stacked"
                color="primary"
                children={'Agregar periodo'}
              />
              <IonSelect
                interfaceOptions={{ subHeader: 'Selecciona una semana' }}
                placeholder="Selecciona una semana"
                onIonChange={(e) => onChangeSelectWeek(e.target.value)}
                value={trainingSessionId}
                interface="popover"
              >
                {trainingSession.length !== 0 &&
                  trainingSession.map((item: any) => {
                    return (
                      <IonSelectOption
                        key={item.id}
                        value={item.id}
                        children={item.name}
                      />
                    )
                  })}
              </IonSelect>
            </IonItem>
          </IonList>
        </IonCard>
        <IonCard style={{ marginLeft: 0, marginRight: 0 }}>
          <IonAccordionGroup animated expand="compact">
            {
              weeksByRoutine &&
              Array.from(weeksByRoutine.entries()).map(([indexWeekRoutine, item]) => {
                return (
                  <IonAccordion
                    key={indexWeekRoutine}
                    value={item}>
                    <IonItem
                      slot="header"
                      lines="none">
                      <IonLabel children={item.name} />
                    </IonItem>
                    <div slot="content">
                      <IonList>
                        {nameDays.map((date, indexDay) => (
                          <IonItem
                            color="none"
                            lines="inset"
                            key={indexDay + 1}
                            onClick={
                              () =>
                                openAddModal(
                                  item.isDisabled,
                                  item,
                                  indexWeekRoutine,
                                  indexDay + 1
                                )
                            }
                          >
                            {
                              item.mapDays[indexDay + 1] !== undefined &&
                                item.mapDays[indexDay + 1].chipsAcronym.length > 0 ?
                                <>
                                  <IonLabel>Dia {indexDay + 1}</IonLabel>
                                  <IonChip color="primary">
                                    {item.mapDays[indexDay + 1].chipsAcronym}
                                  </IonChip>
                                </>
                                : <IonLabel>Dia {indexDay + 1}</IonLabel>
                            }
                          </IonItem>
                        ))}
                      </IonList>
                      {
                        !item.isDisabled &&
                        <IonFooter style={{ marginTop: '1vh', marginBottom: '1vh' }} className="ion-no-border">
                          <IonToolbar color={"none"} >
                            <MyButton
                              title="Eliminar periodo"
                              expand="full"
                              color="danger"
                              fill="clear"
                              onClick={() => removeCurrentWeek(indexWeekRoutine)}
                            />
                          </IonToolbar>
                        </IonFooter>
                      }
                    </div>
                  </IonAccordion>
                )
              })}
          </IonAccordionGroup>
        </IonCard>
        <MyButton
          title="Guardar y volver"
          color="primary"
          expand="block"
          isDisabled={weeksByRoutineAux.size === 0 ? true : false}
          onClick={createRoutine}
        />
      </MyContent>
      <IonModal
        isOpen={isModalAddExerciseOpen}
        ref={modalAddExercise}
        onWillDismiss={(ev) => onWillDismiss(ev)}
        animated
      >
        <IonHeader className="ion-no-border" >
          <IonToolbar>
            <IonTitle color="primary">Lista de ejercicios</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding">
          <IonList>
            <IonListHeader>
              <IonLabel>Elige hasta {MAXCHIPSNUMBER} ejercicios para tu rutina del dia</IonLabel>
            </IonListHeader>
            {trainingCategoryList.length !== 0 &&
              trainingCategoryList.map((item: any) => {
                return (
                  <IonItem key={item.id}>
                    <IonLabel>({item.acronym}) {item.category}</IonLabel>
                    <IonCheckbox
                      checked={selectedCheckboxes.includes(item.id)}
                      onIonChange={() => handleCheckboxChange(item.id)}
                      disabled={
                        selectedCheckboxes.length === MAXCHIPSNUMBER &&
                        !isCheckboxChecked(item.id)
                      }
                    />
                  </IonItem>
                );
              })}
          </IonList>
        </IonContent>
        <IonFooter className="ion-no-border">
          <IonToolbar>
            <IonButtons slot="start">
              <MyButton
                title="Cancelar"
                color="primary"
                fill="outline"
                onClick={() => setIsModalAddExerciseOpen(false)}
              />
            </IonButtons>
            <IonButtons slot="end">
              <MyButton
                title="Agregar"
                color="primary"
                fill="solid"
                isDisabled={selectedCheckboxes.length === 0}
                onClick={() => saveLocalDate()}
              />
            </IonButtons>
          </IonToolbar>
        </IonFooter>
      </IonModal>
      <MyLoading
        isOpen={isLoading}
        toggleLoading={toggleLoading}
      />
      <MyToast
        message={toastMessage}
        isOpen={isToastOpen}
        onDismiss={() => setIsToastOpen(false)}
        color={toastColor}
      />
    </IonPage>
  )
};

export default CreateRoutine;
