import { IonPage, IonButton, IonGrid, IonRow, IonCol, IonIcon, IonLoading, IonAlert, useIonViewWillEnter, IonSelect, IonSelectOption, IonLabel } from '@ionic/react';
import React, { useState, useContext, useEffect } from 'react';
import './SignIn.css';
import Logo from '../../components/generic/MyLogo/Logo';
import {
  personOutline, lockClosedOutline, eyeOutline,
  eyeOffOutline, alertCircleOutline
} from 'ionicons/icons';
import { Header, MInput, MyContent } from '../../components/generic/Index';
import { Link, RouteComponentProps } from 'react-router-dom';
import { AppContext } from '../../data/state';
import { DoGet, DoRemove, DoSet } from '../../DBHandler/User';
import server from '../../api/server';
import { User } from '../../Models/User';
import { Club } from '../../Models/Club';
import { UniqueDeviceID } from '@ionic-native/unique-device-id';
import { getUUIDAndroid } from '../../data/getUUIDAndroid';
import { Capacitor } from '@capacitor/core';
import { readSecretFile, writeSecretFile } from '../../lib/filesystem';


interface OwnProps extends RouteComponentProps { };

const SignIn: React.FC<OwnProps> = ({ history }) => {
  const { state, dispatch } = useContext(AppContext);
  const [username, setUsername] = useState('');
  const [sucursal, setSucursal] = useState('');
  const [sucursalID, setSucursalID] = useState('');
  const [password, setPassword] = useState('');
  const [secondIcon, setSecondIcon] = useState(eyeOutline);
  const [isPasswordShow, setIsPasswordShow] = useState(false);
  const [inputPasswordType, setInputPasswordType] = useState('password');
  const [loginError, setLoginError] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [show500Error, setShow500Error] = useState(false);
  const [showSearchingClubs, setShowSearchingClubs] = useState(true);
  const [showErrorAtSearch, setShowErrorAtSearch] = useState(false);
  const [sucursales, setSucursales] = useState<Club[]>([]);
  const [errorMessage, setErrorMesagge] = useState('');
  const message404UserNotFound = 'Usuario no encontrado.';
  const message401WrongData = 'Usuario y/o contraseña incorrectos.';
  const message400DeviceLinked = 'Esta cuenta ya esta vinculada a otro dispositivo.';

  function showIcon() {
    if (!isPasswordShow) {
      setSecondIcon(eyeOffOutline);
      setInputPasswordType('text');
    } else {
      setSecondIcon(eyeOutline);
      setInputPasswordType('password');
    }
    setIsPasswordShow(!isPasswordShow);
  };

  const sendInfoUserAndroid = async () => {
    setLoginError(false);
    setShowLoading(true);
    setErrorMesagge('');
    const dataRequest = {
      userId: username,
      clubId: parseInt(sucursalID),
      Password: password,
      DeviceToken: "",
      IsIOS: Capacitor.getPlatform() === 'ios'
    };
    try {
      readSecretFile().then(async (data) => {
        dataRequest.DeviceToken = data.data;
        await _clickLogin(dataRequest)
      }).catch((e) => {
        console.log(e);
        setErrorMesagge("No se puede iniciar sesión en este equipo, solicité desvinculación en recepción");
        setLoginError(true);
      }).finally(() => {
        setShowLoading(false);
      });
    } catch (e: any) {
    }
  }

  const checkDeviceForLogin = async (e: any) => {
    e.preventDefault();
    if (Capacitor.getPlatform() === 'ios') {
      const uuid = await UniqueDeviceID.get()
      const data = {
        userId: username,
        clubId: parseInt(sucursalID),
        Password: password,
        DeviceToken: uuid,
        IsIOS: Capacitor.getPlatform() === 'ios'
      };
      _clickLogin(data)
    } else {
      sendInfoUserAndroid()
    }
  }

  const searchForNewClubs = async () => {
    setShowSearchingClubs(true);
    let clubsArr: Club[] = [];
    let savedClubId = state.userSaved?.clubId.toString() ?? undefined;
    let clubs = await DoGet('Clubs');
    if (!clubs || clubs.length < 0) {
      await server.get('/club').then(async (res) => {
        const response = res.data;
        if (response.message != null || response.message != undefined) {
          // TODO review what is the error and dump into internal db for logs
          setShowSearchingClubs(false);
          setShowErrorAtSearch(true);
          return false;
        }
        clubs = response.data;
      }).catch(() => {
        setShowSearchingClubs(false);
        setShowErrorAtSearch(true);
      });
    }
    const fillClubsPromise = new Promise(async (resolve, reject) => {
      for await (let club of clubs) {
        clubsArr.push(club);
        if (state.userSaved) {
          if (savedClubId == club.id) {
            setSucursalID(club.id);
            setSucursal(`${club.id}-${club.name}`);
          }
        }
      }
      setSucursales(clubsArr);
      await DoSet('Clubs', clubsArr);
      resolve();
    });
    await Promise.all([fillClubsPromise]);

    setShowSearchingClubs(false);
  };

  useIonViewWillEnter(() => {
    searchForNewClubs();
    if (state.userSaved) {
      setUsername(state.userSaved.userId.toString());
    }
  });

  useEffect(() => {
    let menu = document.getElementById('moveMenu');
    menu?.setAttribute('disabled', 'disabled');
  }, []);

  const _onDismissNoInternet = async () => {
    const p1 = new Promise(async (resolve, reject) => {
      await setShowErrorAtSearch(false);
      await setTimeout(() => {
        resolve();
      }, 100);
    });
    await Promise.all([p1]).then(() => {
      history.replace('/home');
    });
  };

  const enterPressedOnUser = (key: any) => {
    if (key != 'Enter') return;
  };

  const _clickLogin = async (data: any) => {
    setLoginError(false);
    setShowLoading(true);
    setErrorMesagge('');

    let uuid: string = "";
    if (Capacitor.getPlatform() == 'ios') {
      uuid = await UniqueDeviceID.get()
    } else {
      uuid = await getUUIDAndroid()
    }

    await server.post('/auth/login', data,
    ).then(async (res) => {
      setPassword('');
      const response = res.data;
      if (response.statusCode != 200) {
        console.log('error');
        setShow500Error(true);
        setShowLoading(false);
        return;
      }
      let userRetrived: User = response.data.outputDto;
      // userRetrived.deviceUUID = data.IMEI;
      userRetrived.memberIDTrimmed = data.userId;
      if (!state.userSaved) {
        const userData = {
          userId: parseInt(username),
          clubId: parseInt(sucursalID),
        }
        await DoSet('SavedUserData', userData);
        await dispatch({
          type: 'set-user-saved',
          userSaved: userData
        });
      }
      await DoSet('isLoggedIn', true);
      await DoSet('dateLoggedIn', new Date(Date.now()));
      dispatch({
        type: 'set-is-loggedin',
        isLoggedin: true
      });
      await dispatch({
        type: 'set-token',
        token: response.data.token,
      });
      await dispatch({
        type: 'set-user',
        user: userRetrived
      });
      let menu = document.getElementById('moveMenu');
      menu?.removeAttribute('disabled');
      setShowLoading(false);
      if (response.data.outputDto.tempPassword) {

        await dispatch({
          type: 'set-temp-password',
          tempPassword: data.Password
        });
        await dispatch({
          type: 'set-come-from-login',
          comeFromLogin: true
        });
        history.replace('/changePassword');
      } else {
        history.replace('/profile');
      }
    }).catch((error) => {
      const response = error.response;
      if (response) {
        switch (response.status) {

          case 400:
            setErrorMesagge(message400DeviceLinked);
            setLoginError(true);
            break;

          case 401:
            setErrorMesagge(message401WrongData);
            setLoginError(true);
            break;

          case 404:
            setErrorMesagge(message404UserNotFound);
            setLoginError(true);
            break;

          case 502:
            setShow500Error(true);
            break;

          default:
            if (!state.userSaved) {
              setUsername('');
              setSucursalID('');
              setSucursal('');
            }
            setPassword('');
            setLoginError(true);
            break;
        }
      } else {
        setShow500Error(true);
      }
    });
    setShowLoading(false);
    return;
  };

  const _clickClearCredentials = async () => {
    await DoRemove('SavedUserData');
    await DoSet('Clubs', null);
    await dispatch({
      type: 'set-user-saved',
      userSaved: undefined
    });
    setUsername('');
    setSucursalID('');
    setSucursal('');
    setPassword('');
    searchForNewClubs();
  };

  const _onSelect = (e: any) => {
    const suc = e.split('-');
    setSucursalID(suc[0]);
    setSucursal(e);
  }


  return (

    <IonPage color='dark'>
      <Header color='dark' headerClassname='ion-no-border' defaultHref='/home' />

      <MyContent color='dark'>
        <div className="centerContainer-withHeader">
          <Logo />

          {loginError && <IonGrid>
            <IonRow>
              <IonCol size='1' />
              <IonCol size='10'>
                <IonIcon icon={alertCircleOutline} color='primary' />
                <IonLabel>{errorMessage}</IonLabel>
              </IonCol>
              <IonCol size='1' />
            </IonRow>
          </IonGrid>}

          <br />
          {/* Not user info saved. */}

          <IonGrid>
            <IonRow>
              <IonCol size='6'>
                <MInput
                  inputID='userId'
                  inputName='userId'
                  iconSize='large'
                  icon={personOutline}
                  itemClass='darkInput'
                  inputClass='innerDarkInput'
                  placeholder='# socio'
                  inputType='number'
                  value={username}
                  clearInput={true}
                  handleChangeValue={setUsername}
                  handleKeyPress={enterPressedOnUser}
                  enterkeyhint={'next'}
                  maxLength={5}
                  minLength={1}
                  isReadOnly={state.userSaved}
                />
              </IonCol>
              <IonCol size='6'>
                <IonSelect id='clubsSelect' value={sucursal} mode='ios' placeholder='Sucursal'
                  onIonChange={e => _onSelect(e.detail.value)}
                  interface='popover' disabled={state.userSaved}>
                  {sucursales.map((suc) => {
                    return <IonSelectOption key={suc.name} value={suc.id + '-' + suc.name}>
                      {suc.name}
                    </IonSelectOption>
                  })}
                </IonSelect>
              </IonCol>
            </IonRow>
          </IonGrid>

          <IonGrid>
            <IonRow>
              <IonCol size='12'>
                <MInput
                  inputID='password'
                  inputName='password'
                  iconSize='large'
                  icon={lockClosedOutline}
                  isSecondIcon={true}
                  secondIconSize='small'
                  secondIcon={secondIcon}
                  onclickSecondIcon={showIcon}
                  itemClass='darkInput'
                  inputClass='innerDarkInput'
                  placeholder='Contraseña'
                  value={password}
                  handleChangeValue={setPassword}
                  inputType={inputPasswordType}
                  enterkeyhint={'go'}
                />
              </IonCol>
            </IonRow>
          </IonGrid>


          <span>
            <Link className='forgetPassword' to="/forgot">Olvidé mi contraseña</Link>
          </span>


          <br />
          <IonButton className='submitButton' shape='round' expand='block' color='primary'
            disabled={username === '' || password === '' || sucursalID == ''}
            onClick={(e) => checkDeviceForLogin(e)} >
            Inicia sesión
          </IonButton>

          <br />
          {
            state.userSaved &&
            <IonButton shape='round' fill='outline' expand='block' color='primary'
              onClick={() => _clickClearCredentials()}>
              Inicia sesión con otro usuario
            </IonButton>
          }
        </div>

        <IonLoading
          cssClass='my-custom-class'
          isOpen={showLoading}
          onDidDismiss={() => { }}
          message={'Iniciando sesión'}
          duration={0}
          spinner='bubbles'
        />

        <IonAlert
          isOpen={show500Error}
          onDidDismiss={() => { }}
          cssClass='my-custom-class'
          header={'Error!'}
          message={'Parece que no pudimos establecer conexión con el servidor, intentalo de nuevo más tarde'}
          buttons={[{
            text: 'Aceptar',
            role: 'Ok',
            handler: () => setShow500Error(false)
          }]}
          backdropDismiss={false}
        />

        <IonLoading
          cssClass='my-custom-class'
          isOpen={showSearchingClubs}
          onDidDismiss={() => { }}
          message={'Actualizando las sucursales...'}
          duration={0}
        />

        <IonAlert
          isOpen={showErrorAtSearch}
          onDidDismiss={() => { }}
          cssClass='my-custom-class'
          header={'Error!'}
          message={'Parece que no pudimos establecer conexión con el servidor, intentalo de nuevo más tarde'}
          buttons={[{
            text: 'Aceptar',
            role: 'Ok',
            handler: () => _onDismissNoInternet()
          }]}
          backdropDismiss={false}
        />

      </MyContent>
    </IonPage>
  );
};

export default SignIn;
