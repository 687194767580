import { IonPage } from "@ionic/react";
import React, { useEffect, useState, useContext } from "react";
import { AppContext } from "../../data/state";
import { RouteComponentProps } from "react-router";
import { Header, MyContent, MyTitle, MyLoading } from "../../components/generic/Index";
import dayjs from "dayjs";
import server from "../../api/server";
import "./Progress.css";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartJS.register(
  CategoryScale,
  ChartDataLabels,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

interface OwnProps extends RouteComponentProps { };
const LABELS = ["Composición Corporal KGS", "Composición Corporal %", "Peso"];
const options = {
  responsive: true,
  plugins: {
    legend: {
      display: true,
      position: 'bottom' as const,
    },
    title: {
      display: false,
    },
    datalabels: {
      anchor: 'end',
    }
  },
  scales: {
    x: {
      display: true,
      title: {
        display: true,
        text: 'Sesiones',
      }
    },
    y: {
      display: false,
    }
  }
};

const Progress: React.FC<OwnProps> = ({ }) => {
  const { state } = useContext(AppContext);
  const [isLoading, setIsLoading] = useState(false);
  const [consultDateLabel, setConsultDateLabel] = useState<any[]>([]);
  const [progress, setProgress] = useState<any[]>([]);
  dayjs.locale('es');

  const headers = { 'Content-Type': 'application/json', 'Authorization': `Bearer ${state.token}` };

  useEffect(() => {
      getProgress();
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getProgress = async () => {
    setIsLoading(true);
    var consult = [];

    var gc = [];
    var mme = [];
    var pgc = [];
    var pmm = [];

    var weight = [];
    var allData = [];
    await server
      .get('/customer/nutrition/history', { headers: headers })
      .then(async (res) => {
          const response = res.data?.data;

          response.map((item: any) => {
            consult.push(dayjs(item.consultDate).format('DD/MM'));
            gc.push(item.gc.toFixed(1));
            mme.push(item.mme.toFixed(1));
            pgc.push(item.pgc.toFixed(1));
            pmm.push(item.pmm.toFixed(1));
            weight.push(item.weight.toFixed(1));
          });

          setConsultDateLabel(consult);
          setConsultDateLabel(consult);
          allData.push([mme, "Masa Muscular", gc, "Grasa"]);
          allData.push([pmm, "Porcentaje Masa Muscular", pgc, "Porcentaje Grasa"]);
          allData.push([weight, "Peso"]);
          setProgress(allData);
          setIsLoading(false);
      })
      .catch((error) => {
          console.warn(error);
          setIsLoading(false);
      })
  };

  const formatData = (dataArray: any, index: number) => {
    const data = {
      labels: consultDateLabel,
      datasets: [
        {
          data: dataArray[0],
          borderColor: 'rgb(136, 190, 85)',
          backgroundColor: 'rgba(136, 190, 85, 0.9)',
          pointStyle: 'rect',
          pointRadius: 6,
          pointHoverRadius: 2,
          label: dataArray[1],
        },
      ],
    };

    if(index !== 2) {
      data.datasets.push({
        data: dataArray[2],
        borderColor: 'lightBlue',
        backgroundColor: 'lightBlue',
        pointStyle: 'rect',
        pointRadius: 6,
        pointHoverRadius: 2,
        label: dataArray[3],
      });
    }
    return data;
  };

  return (
    <>
      <IonPage>
        <Header
          title="Progreso"
          titleColor="primary"
          isBackButton={true}
          defaultHref="/nutrition"
        />
        <MyContent>
          {
            progress !== undefined &&
            progress.map((item, index) => (
              <div key={index} className="progress-bar">
                <MyTitle key={index+10} subtitle={LABELS[index]} />
                <Line key={index} options={options} data={formatData(item, index)} />
              </div>
            ))
          }
        </MyContent>
        <MyLoading
          isOpen={isLoading}
        />
      </IonPage>
    </>
  )
};

export default Progress;
