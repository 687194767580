import { Directory, Encoding, Filesystem } from "@capacitor/filesystem";


export const writeSecretFile = async (data: string) => {
    await Filesystem.writeFile({
        path: 'filesystem.txt',
        data,
        directory: Directory.Data,
        encoding: Encoding.UTF8,
    });
};

export const readSecretFile = async () => {
    return await Filesystem.readFile({
        path: 'filesystem.txt',
        directory: Directory.Data,
        encoding: Encoding.UTF8,
    });
};

export const deleteSecretFile = async () => {
    await Filesystem.deleteFile({
        path: 'filesystem.txt',
        directory: Directory.Data,
    });
};