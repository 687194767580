import React, { useContext, useEffect } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { IonApp, IonRouterOutlet, IonSplitPane, setupIonicReact, useIonAlert } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
/* App state */
import { AppContext, AppContextProvider } from './data/state';
import { Menu } from './components/generic/Index';
import Home from './pages/Home';
import SignIn from './pages/Sign/SignIn';
import SignUp from './pages/Sign/SignUp';
import ForgortPassword from './pages/Sign/ForgotPasword';
import Profile from './pages/Profile/ViewProfile';
import ChangePassword from './pages/Sign/ChangePassword';
import MyPlan from './pages/Profile/MyPlan';
import Contact from './pages/Profile/Contact';
import HealthInfo from './pages/Profile/HealthInfo';
import Configuration from './pages/Configuration/Configuration';
import PaymentHistory from './pages/Payments/PaymentHistory';
import Lessons from './pages/Club/Lessons';

import Routine from './pages/Training/Routine';
import EditRoutine from './pages/Training/EditRoutine';
import Conditioning from './pages/Training/Conditioning';
import CreateRoutine from './pages/Training/CreateRoutine';

import Nutrition from './pages/Nutrition/Nutrition';
import Results from './pages/Nutrition/Results';
import Progress from './pages/Nutrition/Progress';
import Report from './pages/Nutrition/Report';

import PersonalData from './pages/Subcription/PersonalData';
import Contract from './pages/Subcription/Contract';
import Resume from './pages/Subcription/Resume';
/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';

import Access from './pages/CodeGenerator/Access';
import { DoGet, DoSet } from './DBHandler/User';
import { SplashScreen } from '@capacitor/splash-screen';
import { UniqueDeviceID } from '@ionic-native/unique-device-id';
import ClubList from './pages/Club/ClubList';
import Plans from './pages/Club/Plans';
import Club from './pages/Club/Club';
import ClubServices from './pages/Club/ClubServices';
import ClubMap from './pages/Club/ClubMap';
import ReservePool from './pages/Club/ReservePool';
import { Capacitor } from '@capacitor/core';
import { getUUIDAndroid } from './data/getUUIDAndroid';
import { Filesystem } from '@capacitor/filesystem';
import { BarcodeScanner } from '@capacitor-community/barcode-scanner';

setupIonicReact();

const IonicApp = () => {
  const { state, dispatch } = useContext(AppContext);

  const [present] = useIonAlert();

  useEffect(() => {
    (async () => {
      await initializeApp();
      await requestPermission();
    })();
    SplashScreen.hide();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const redirectPermissions = async () => {
    await BarcodeScanner.openAppSettings()
  }

  const requestPermission = async () => {
    if (Capacitor.isNativePlatform()) {
      await Filesystem.requestPermissions();
      let request = await Filesystem.checkPermissions();
      if (request.publicStorage !== 'granted') {
        present("Es necesario que acepte los permisos de almacenamiento para poder usar la aplicación", [
          {
            text: 'Aceptar',
            handler: async () => {
              await redirectPermissions();
            }
          }
        ]);
      }
    }
  }

  const initializeApp = async () => {
    if (state.isInsideClub) {
      let lastInside = new Date(state.lastEnteredClubDate);
      let today = new Date(Date.now());
      today.setHours(0, 0, 0, 0);
      lastInside.setHours(0, 0, 0, 0);
      if (today > lastInside) {
        dispatch({
          type: 'set-is-inside-club',
          isInsideClub: false
        });
      }
    }

    const SavedUserData = await DoGet('SavedUserData');
    if (SavedUserData != undefined || SavedUserData) {
      dispatch({
        type: 'set-user-saved',
        userSaved: SavedUserData
      });
    } else {
      dispatch({
        type: 'set-user-saved',
        userSaved: undefined
      });
    }

    let DeviceUUID = await DoGet('DeviceUUID');
    if (DeviceUUID == undefined || !DeviceUUID) {
      let uuid: string = "";
      if (Capacitor.getPlatform() == 'ios') {
        uuid = await UniqueDeviceID.get()
      } else {
        uuid = await getUUIDAndroid()
      }
      DeviceUUID = `${uuid}`;
      await DoSet('DeviceUUID', DeviceUUID);
    }
    dispatch({
      type: 'set-device-uuid',
      deviceUUID: DeviceUUID
    });

    SplashScreen.hide();
  };

  return (
    <IonApp>
      <IonReactRouter>
        <IonSplitPane contentId='main'>
          <Menu />
          <IonRouterOutlet id='main'>
            <Route path='/home' component={Home} exact={true} />
            <Route path='/signIn' component={SignIn} exact={true} />
            <Route path='/signUp' component={SignUp} exact={true} />
            <Route path='/forgot' component={ForgortPassword} exact={true} />
            <Route path='/changePassword' component={ChangePassword} exact={true} />
            <Route path='/' render={() =>
              <Redirect to={state.isLoggedin ? '/profile' : '/home'} />} exact={true} />

            <Route path='/profile' component={Profile} exact={true} />
            <Route path='/access' component={Access} exact={true} />
            <Route path='/profile/myPlan' component={MyPlan} />
            <Route path='/profile/contact' component={Contact} />
            <Route path='/profile/healthInfo' component={HealthInfo} />

            <Route path='/routine' component={Routine} />
            <Route path='/editRoutine' component={EditRoutine} exact={true} />
            <Route path='/conditioning' component={Conditioning} exact={true} />
            <Route path='/createRoutine' component={CreateRoutine} exact={true} />

            <Route path='/nutrition' component={Nutrition} exact={true} />
            <Route path='/nutrition/results' component={Results} exact={true} />
            <Route path='/nutrition/progress' component={Progress} exact={true} />
            <Route path='/nutrition/report' component={Report} exact={true} />

            <Route path='/configurations' component={Configuration} />
            <Route path='/personaldata' component={PersonalData} />
            <Route path='/contract' component={Contract} />
            <Route path="/resume" component={Resume} ></Route>
            <Route path='/payments/history' component={PaymentHistory} />
            <Route path='/clublist' component={ClubList} exact={true} />
            <Route path='/clublist/:id' component={Club} exact={true} />
            <Route path='/clublist/:id/plans' component={Plans} exact={true} />
            <Route path='/clublist/:id/services' component={ClubServices} />
            <Route path='/clublist/:id/map' component={ClubMap} />
            <Route path='/clublist/:id/lessons' component={Lessons} />
            <Route path='/clublist/:idClub/reserve/:idReserve' component={ReservePool} />
          </IonRouterOutlet>
        </IonSplitPane>
      </IonReactRouter>
    </IonApp>
  )
};

const App = () => {
  return (
    <AppContextProvider>
      <IonicApp />
    </AppContextProvider>
  )
};

export default App;
