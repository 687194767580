import { IonActionSheet } from "@ionic/react";
import React from "react";

interface ActionSheetButton {
  text: string;
  role: string;
  handler: () => void;
};

interface MyActionSheetProps {
  isOpen: boolean;
  onDidDismiss: () => void;
  buttons: ActionSheetButton[];
  header?: string;
  animated?: boolean;
  backdropDismiss?: boolean;
  keyboardClose?: boolean;
};

const MyActionSheet: React.FC<MyActionSheetProps> = ({
  isOpen,
  onDidDismiss,
  buttons,
  header = "Placeholder header",
  animated = true,
  backdropDismiss = true,
  keyboardClose = true,
}) => {
    return (
      <IonActionSheet
        isOpen={isOpen}
        onDidDismiss={onDidDismiss}
        animated={animated}
        backdropDismiss={backdropDismiss}
        keyboardClose={keyboardClose}
        header={header}
        buttons={buttons.map((button, index) => ({
          key: index,
          text: button.text,
          role: button.role,
          handler: button.handler,
        }))}
        />
    );
};

export default MyActionSheet;
