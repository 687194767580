import React, { useState, useContext, useEffect } from "react";
import { IonCol, IonItem, IonLabel, IonPage, IonCard, IonDatetime, IonPopover, IonToolbar, IonAccordionGroup, IonAccordion, IonFooter } from "@ionic/react";
import { RouteComponentProps } from 'react-router-dom';
import { AppContext } from '../../data/state';
import { Header, MyButton, MyContent, MyPopover, MyTitle, MyToast, NoDataAvailable } from "../../components/generic/Index";
import server from '../../api/server';
import { walletOutline } from "ionicons/icons";
import dayjs from "dayjs";
import 'dayjs/locale/es-mx';
import PaymentItemSkeleton from "../../components/custom/Payments/PaymentItemSkeleton";

interface OwnProps extends RouteComponentProps { };

const PaymentHistory: React.FC<OwnProps> = ({ history }) => {

  var t = new Date();
  var today = dayjs(t).locale('es-mx').format('YYYY');
  const [isLoading, setIsLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState<string>(today);
  const [searchDate, setSearchDate] = useState<string>(today);

  const [isToastOpen, setIsToastOpen] = useState(false);
  const [showPopover, setShowPopover] = useState(false);
  const [paymentsList, setPaymentsList] = useState<Array<any>>([]);
  const { state } = useContext(AppContext);

  useEffect(() => {
    getUserPayments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const changeDate = (year: any) => {
    setSelectedDate(year);
    setSearchDate(year);
    getUserPayments(year);
  }

  const getUserPayments = async (year?: any) => {
    setIsLoading(true);
    var auxDate = ""
    // eslint-disable-next-line
    if (year == undefined) {
      auxDate = searchDate;
    }
    else {
      auxDate = year;
    }

    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${state.token}`,
    };

    await server
      .get('/user/payments?year=' + auxDate, { headers: headers })
      .then(async (res) => {
        const response = res.data;
        if (response.statusCode !== 200) {
          console.log('Error');
          return false;
        }
        setPaymentsList(response.data.payments);
      })
      .finally(() => {
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
        setIsToastOpen(true);
      });
  }

  const colorFill = (status: any) => {
    // eslint-disable-next-line
    if (status == "Vencido") {
      return "red";
    }
    else {
      return "black";
    }
  }

  return (

    <IonPage>
      <Header
        title="Historial de pagos"
        titleColor="primary"
        isBackButton={false}
        isMenu={true}
        menuColor="primary"
      />

      <MyContent hasRefresher refreshMethod={getUserPayments}>

        <MyTitle title={`${selectedDate}`} />

        {isLoading ? (
          <PaymentItemSkeleton count={3} />
        ) : (
          <IonCard>
            <IonAccordionGroup animated multiple expand="compact" >

              {(Object.keys(paymentsList).length !== 0) &&
                Object.keys(paymentsList).map((date: any, index: number) => {
                  return (

                    <IonAccordion value={date} key={index}>
                      <IonItem
                        slot="header"
                        style={{ textTransform: 'capitalize' }}
                        lines="none"
                      >
                        <IonLabel children={date} />
                      </IonItem>

                      <div slot="content" color="dark">
                        {paymentsList[date].map((info: any, index: number) => {
                          return (
                            <IonItem
                              key={index}
                              lines="none" >
                              <IonCol size="9">
                                <IonLabel style={{ textTransform: 'capitalize' }}>
                                  <h3 style={{ color: 'var(--ion-color-primary)' }} children={info.description.toLowerCase()} />
                                  <h4 style={{ color: 'var(--ion-color-medium)' }} children={`Vigencia: ${info.expireDate.slice(0, 10)}`} />
                                  <h4 style={{ color: 'var(--ion-color-medium)' }} children={`Fecha pago: ${info.paymentDate.slice(0, 10)}`} />
                                </IonLabel>
                              </IonCol>

                              <IonCol size="3">
                                <IonLabel style={{ textAlign: 'right' }} >
                                  <h4 style={{ "color": colorFill(info.status) }} >{info.status}</h4>
                                  <h4 style={{ color: 'var(--ion-color-primary)' }} children={`$${info.amount}`} />
                                  &nbsp;                                                            </IonLabel>
                              </IonCol>
                            </IonItem>
                          )
                        })}
                      </div>
                    </IonAccordion>
                  )
                })}

            </IonAccordionGroup>
          </IonCard>
        )}

        {!isLoading && Object.keys(paymentsList).length === 0 && (
          <NoDataAvailable
            text="Sin elementos en el historial de pagos"
            icon={walletOutline}
          />
        )}

      </MyContent>

      <IonFooter
        style={{ textAlign: 'center' }}
        className="ion-no-border"
      >
        <MyButton
          title={`Seleccione un periodo: ${selectedDate}`}
          onClick={() => setShowPopover(true)}
        />
      </IonFooter>

      <IonPopover
        isOpen={showPopover}
        onDidDismiss={() => setShowPopover(false)}
        keepContentsMounted={true}
        animated
        backdropDismiss
        showBackdrop
        size="auto"
        alignment="center"
        side="top"
      >
        <IonDatetime
          value={selectedDate}
          onIonChange={e => changeDate(e.detail.value)}
          presentation="year"
          locale="es-MX"
        ></IonDatetime>
      </IonPopover>

      <MyToast
        isOpen={isToastOpen}
        onDismiss={() => setIsToastOpen(false)}
        message="Hubo un problema al cargar los datos. Inténtalo de nuevo mas tarde"
        color="danger"
      />

    </IonPage>

  );

};

export default PaymentHistory;
