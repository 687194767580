import React, { createContext, useReducer, useEffect } from "react";
import { useLocalStorage } from './useLocalStorage';

let AppContext = createContext();

const initialState = {
  isLoggedin: false,
  darkMode: false,
  token: undefined,
  user: undefined,
  userIsMulticlub: false,
  multiClubType: undefined,
  isInsideClub: false,
  lastEnteredClub: undefined,
  lastEnteredClubDate: undefined,
  defaultClub: undefined,
  userRequiredACode: false,
  CodeRequired: undefined,
  hasRequiredRegister: false,
  isPreviousLoggedIn: false,
  userSaved: undefined,
  deviceUUID: undefined,
  tempPassword: undefined,
  comeFromLogin: false,
  isSignIn: false,
  requestedUnklinkDevice: false,
  selectedDay: undefined
}

let reducer = (state, action) => {
  switch (action.type) {
    case 'set-is-loggedin':
      return { ...state, isLoggedin: action.isLoggedin };
    case 'set-dark-mode':
      return { ...state, darkMode: action.darkMode };
    case 'set-token':
      return { ...state, token: action.token };
    case 'set-is-previous-open':
      return { ...state, isPreviousLoggedIn: action.isPreviousLoggedIn };
    case 'set-has-required-register':
      return { ...state, hasRequiredRegister: action.hasRequiredRegister };
    case 'set-user-saved':
      return { ...state, userSaved: action.userSaved };
    case 'set-user':
      return { ...state, user: action.user };
    case 'set-is-inside-club':
      return { ...state, isInsideClub: action.isInsideClub };
    case 'set-user-is-multiclub':
      return { ...state, userIsMulticlub: action.userIsMulticlub };
    case 'set-last-entered-club':
      return { ...state, lastEnteredClub: action.lastEnteredClub };
    case 'set-last-entered-club-date':
      return { ...state, lastEnteredClubDate: action.lastEnteredClubDate };
    case 'set-default-club':
      return { ...state, defaultClub: action.defaultClub };
    case 'set-device-uuid':
      return { ...state, deviceUUID: action.deviceUUID };
    case 'set-user-family':
      return { ...state, userFamily: action.userFamily };
    case 'set-user-access':
      return { ...state, userAccess: action.userAccess };
    case 'set-temp-password':
      return { ...state, tempPassword: action.tempPassword };
    case 'set-come-from-login':
      return { ...state, comeFromLogin: action.comeFromLogin };
    case 'set-requested-unlink-device':
      return { ...state, requestedUnklinkDevice: action.requestedUnklinkDevice };
    case 'set-is-sign-in':
      return { ...state, isSignIn: action.isSignIn };
    case 'set-selected-day':
      return { ...state, selectedDay: action.selectedDay };
    default:
      return state;
  }
};

function AppContextProvider(props) {
  const fullInitialState = {
    ...initialState
  }

  let [data, setData] = useLocalStorage('move', fullInitialState);
  let [state, dispatch] = useReducer(reducer, data);
  let value = { state, dispatch };

  useEffect(() => {
    setData(state);
  }, [state, setData]);

  return (
    <AppContext.Provider value={value}>{props.children}</AppContext.Provider>
  );
}

let AppContextConsumer = AppContext.Consumer;

export { AppContext, AppContextProvider, AppContextConsumer };
