import React, { useContext } from "react";
import {
  IonContent,
  IonPage,
  IonItem,
  IonList,
  IonSelect,
  IonSelectOption,
  IonButton,
  IonIcon,
  useIonAlert,
} from "@ionic/react";
import { useHistory, useParams } from "react-router-dom";
import { Loading, Header } from "../../components/generic/Index";
import server from "../../api/server";
import { AppContext } from "../../data/state";
import { timeOutline } from "ionicons/icons";

import "./ReservePool.css";

const ReservePool: React.FC = () => {
  const { state } = useContext(AppContext);
  const [schedules, setSchedules] = React.useState<any>([]);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [date, setDate] = React.useState<string>("");
  const [present] = useIonAlert();

  const history = useHistory();

  // Get id parameter from url
  const { idClub, idReserve } = useParams<{
    idClub: string;
    idReserve: string;
  }>();
  // Create list of next seven days from today
  const days = Array.from({ length: 8 }, (_, i) => {
    const date = new Date();
    date.setDate(date.getDate() + i);
    return date;
  });

  const selectReserveDay = async (e: any) => {
    const idDay = e.target.value;
    const selectDay = days[idDay];
    // Convert date in this format
    // 2021-08-31
    const date =
      selectDay.getFullYear() +
      "-" +
      (selectDay.getMonth() + 1) +
      "-" +
      selectDay.getDate();

      setDate(date);

    const data = {
      date,
      calendarId: Number.parseInt(idReserve),
    };
    setIsLoading(true);
    try {
      const requestSchedules = await server.post(
        `/club/${idClub}/pool`,
        JSON.stringify(data),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${state.token}`,
          },
        }
      );
      const dataFormated = retrieveStringWithRegex(requestSchedules.data.data);
      setSchedules(dataFormated);
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };

  const retrieveStringWithRegex = (htmlRaw: string) => {
    const groupAllTagDivTimeSlot =
      htmlRaw.match(
        /<div class="timeslot bookedClearFix.*?<\/div>/g
      ) ?? [];
    const result = groupAllTagDivTimeSlot.map((divTimeSlot: string) => {
      const dataTimeSlot = divTimeSlot.match(/data-timeslot="(.*?)"/); // -
      const dataTimeSlotValue = dataTimeSlot ? dataTimeSlot[1] : "";
      const buttonTimeSlotContent = divTimeSlot.match(
        /class="button-timeslot">(.*?)<\/span>/
      );
      const buttonTimeSlotContentValue = buttonTimeSlotContent
        ? buttonTimeSlotContent[1]
        : "";
      const getContentButtonText = divTimeSlot.match(
        /class="button-text">(.*?)<\/span>/
      );
      const getContentButtonTextValue = getContentButtonText
        ? getContentButtonText[1]
        : "";
      const getSpotsAvailable = divTimeSlot.match(
        /class="spots-available(.*?)<\/span>/
      );
      const getSpotsAvailableValue = getSpotsAvailable
        ? getSpotsAvailable[1]
        : "";
      const formatSpotsAvailable = getSpotsAvailableValue.split(">")[1]; // -
      return {
        timeSlot: dataTimeSlotValue,
        timeSlotLabel: buttonTimeSlotContentValue,
        labelButton: getContentButtonTextValue,
        spots: formatSpotsAvailable,
        isActive: getContentButtonTextValue !== "Reservar horario",
      };
    });

    return result;
  };

  const reservePool = async (timeSlot: string) => {
    const data = {
      date,
      "calendarId": Number.parseInt(idReserve),
      "timeSlot": timeSlot,
    }
    
    setIsLoading(true);
    try {
      const requestSchedules = await server.post(
        `/club/${idClub}/pool-schedule`,
        JSON.stringify(data),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${state.token}`,
          },
        }
      );
      setIsLoading(false);
      present(`La reserva se ha realizado correctamente`,[
        {
          text: "OK",
          role: "confirm",
          handler: () => {
            history.replace("/")
          },
        },
      ])
    } catch (e) {
      console.log(e);
      setIsLoading(false);
      present(`Ha ocurrido un error al realizar la reserva. Comuníquese con el administrador`,[
        {
          text: "OK",
          role: "confirm",
          handler: () => {
            history.replace("/")
          },
        },
      ])
    }

  }


  return (
    <>
      {isLoading && <Loading />}
      <IonPage>
        <Header
          title={""}
          titleColor="primary"
          headerClassname="ion-no-border"
          defaultHref="/clublist"
        />
        <IonContent>
          <IonList>
            <IonItem className="ion-text-center">
              <IonSelect
                placeholder="Selecciona un dia"
                onIonChange={selectReserveDay}
              >
                {days.map((day, i) => (
                  <IonSelectOption key={i} value={i}>
                    {day.toLocaleDateString("es-ES", {
                      month: "long",
                      day: "numeric",
                    })}
                  </IonSelectOption>
                ))}
              </IonSelect>
            </IonItem>
          </IonList>
          <IonList>
            {schedules &&
              schedules.map((schedule: any, i: number) => (
                <IonItem key={i}>
                  <div className="row-list">
                    <div className="col-list-info">
                      <div className="time-slot">
                        <IonIcon
                          icon={timeOutline}
                          className="left-icon"
                        ></IonIcon>
                        <h6>{schedule.timeSlotLabel}</h6>
                      </div>
                      <span>{schedule.spots}</span>
                    </div>
                    <div className="col-list-button">
                      <IonButton
                        size="small"
                        disabled={schedule.isActive}
                        className={"btn-reserve"}
                        onClick={() => reservePool(schedule.timeSlot)}
                      >
                        <span>{schedule.labelButton}</span>
                      </IonButton>
                    </div>
                  </div>
                </IonItem>
              ))}
          </IonList>
        </IonContent>
      </IonPage>
    </>
  );
};

export default ReservePool;
