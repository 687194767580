import React from "react";
import { IonContent, IonGrid, IonRefresher, IonRefresherContent, RefresherEventDetail } from "@ionic/react";

interface MyContentProps {
    children: React.ReactNode;
    hasRefresher?: boolean,
    noPadding?: boolean
    noFullscreen?: boolean,
    color?: "primary" | 'secondary' | 'tertiary' | 'success' | 'warning' | 'danger' | 'light' | 'medium' | 'dark' | 'none' | undefined,
    refreshMethod?: () => void,
}

const MyContent: React.FC<MyContentProps> = ({
    children,
    hasRefresher = false,
    noFullscreen = false,
    noPadding = false,
    color,
    refreshMethod
}) => {

    function handleRefresh(event: CustomEvent<RefresherEventDetail>) {
        setTimeout(() => {
            if (refreshMethod) {
                refreshMethod();
            }
            event.detail.complete();
        }, 500);
    }

    return (
        <IonContent color={color} fullscreen={noFullscreen ? false : true} className={noPadding ? 'ion-no-padding' : ''} >

            {hasRefresher &&
                <IonRefresher slot="fixed" pullFactor={0.5} pullMin={100} pullMax={200} onIonRefresh={handleRefresh}>
                    <IonRefresherContent></IonRefresherContent>
                </IonRefresher>
            }

            <IonGrid fixed>
                {children}
            </IonGrid>
        </IonContent>
    )
};

export default MyContent;