import React, { useState, useEffect } from "react";
import { IonPage, IonGrid, IonRow, IonLabel, IonCard, IonCol, IonCardHeader, IonCardContent, IonItem, IonCardSubtitle, IonList, IonNote, } from "@ionic/react";
import { RouteComponentProps } from "react-router-dom";
import { useParams } from "react-router-dom";
import { rainy } from "ionicons/icons";
import { Header, MyContent, MyLoading, MyButton, NoDataAvailable, MyTitle } from '../../components/generic/Index';
import server from "../../api/server";

import "./Plans.css";

interface OwnProps extends RouteComponentProps { }

const Plans: React.FC<OwnProps> = ({ history }) => {

  const { id }: any = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const toggleLoading = () => { setIsLoading(prevState => !prevState); };
  const clubName = (history?.location?.state as any)?.clubName ?? "";

  const [aditionalPricesList, setAditionalPricesList] = useState([]);
  const [lateFeeDescription, setlateFeeDescription] = useState("");
  const [plansList, setPlansList] = useState([]);
  const [subscriptionBenefits, setSubscriptionBenefits] = useState([]);

  useEffect(() => {
    getSusbscriptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getSusbscriptions = async () => {
    toggleLoading();
    await server
      .get(`/club/${id}/additional-prices`)
      .then(async (res) => {
        const response = res.data;
        if (response.statusCode !== 200) {
          console.warn("error");
          return false;
        }
        setAditionalPricesList(response.data);

        if (response.data.length > 0) {
          const lastItemIndex = response.data.length - 1;
          setlateFeeDescription(response.data[lastItemIndex].additionalPrices.description);
        }
      })
      .catch((error) => {
        console.warn(error);
        setAditionalPricesList([]);
      });

    await server
      .get(`/club/${id}/subscriptions`)
      .then(async (res) => {
        const response = res.data;
        if (response.statusCode !== 200) {
          console.warn("error");
          return false;
        }
        setPlansList(response.data);
        if (response.data.length > 0) {
          const lastItemIndex = response.data.length - 1;
          setSubscriptionBenefits(response.data[lastItemIndex].subscriptionBenefits);
        }
      })
      .finally(() => {
        toggleLoading();
      })
      .catch((error) => {
        console.warn(error);
        setPlansList([]);
        setSubscriptionBenefits([]);
      });
  };

  return (
    <>
      <IonPage>
        <Header
          title={clubName}
          titleColor="primary"
          isBackButton={true}
          defaultHref="/clublist"
        />

        <MyContent>

          <MyTitle title="Planes" />

          {aditionalPricesList.length !== 0 && (
            <IonList className="ion-text-center" >
              {aditionalPricesList !== null && aditionalPricesList.map((element: any) => {
                return (
                  <MyButton
                    key={element.id}
                    color="primary"
                    fill="solid"
                    title={element.additionalPrices.priceName + ' $' + element.price}
                  />
                )
              })}
              <IonGrid className="ion-text-center">
                <IonNote children={lateFeeDescription ? lateFeeDescription : "Aplica al pagar 10 díás después de la fecha"} style={{ fontSize: '0.8rem' }} />
              </IonGrid>
            </IonList>
          )}

          {plansList.length !== 0 && plansList.map((element: any) => {
            return (
              <IonCard key={element.id} className="card-style">
                <IonCardHeader
                  className={`${element.subscriptionType.id === 1 ? "card-header-basic" : "card-header-premium"}`}
                  children={"Plan " + element.subscriptionType.type} />

                <IonCardContent className="card-table-text">
                  <IonRow className="card-subheader">
                    <IonCol size="5">
                      <IonLabel>Modalidad</IonLabel>
                    </IonCol>

                    <IonCol size={element.subscriptionType.id !== 3 ? "4" : "7"}>
                      <IonLabel>Cuota mensual</IonLabel>
                    </IonCol>

                    {element.subscriptionType.id !== 3 && (
                      <IonCol>
                        <IonLabel>Cuota anual</IonLabel>
                      </IonCol>
                    )}
                  </IonRow>

                  {element.subscriptionClubModes.map((mode: any, index: number) => {
                    return (
                      <IonRow  >
                        <IonCol key={index} size="5" className="ion-text-center" >
                          <IonLabel>{mode.subscriptionMode.mode}</IonLabel>
                        </IonCol>
                        {mode.subscriptionPrices.map((value: any, index: number) => {
                          return (
                            <IonCol key={index}>
                              <IonLabel color="medium"> ${value.price.toLocaleString('es-MX')}.00</IonLabel>
                            </IonCol>
                          );
                        })
                        }
                      </IonRow>
                    )
                  })}

                </IonCardContent>
              </IonCard>
            );
          })}

          {subscriptionBenefits.length !== 0 && (
            <IonCard>
              <IonCardHeader>
                <IonCardSubtitle color="primary" children={"Beneficios Premium"} className="ion-text-center" />
              </IonCardHeader>

              <IonCardContent>
                <IonRow >
                  {subscriptionBenefits !== null && subscriptionBenefits.map((benefit: any, index: number) => {
                    return (
                      <IonCol key={index} size="12" size-md="6">
                        <IonItem lines="none" color="none" className="grid-plans" >
                          <img src={`${benefit.iconString}`} alt="icon" className="icon-label-plans" />
                          <span className="label-plans"> {benefit.benefit} </span>
                        </IonItem>
                      </IonCol>
                    )
                  })}
                </IonRow>

              </IonCardContent>
            </IonCard>
          )}

          {plansList.length === 0 && aditionalPricesList.length === 0 && subscriptionBenefits.length === 0 && (
            <NoDataAvailable icon={rainy} text="Hubo un error al procesar la solicitud. intente de nuevo mas tarde." />
          )}

        </MyContent>

      </IonPage >

      <MyLoading
        isOpen={isLoading}
        toggleLoading={toggleLoading}
      />
    </>

  )
};

export default Plans;
