import { IonCol, IonContent, IonGrid, IonItem, IonLabel, IonPage } from "@ionic/react";
import React from "react";
import { RouteComponentProps } from "react-router";
import { Header } from "../../components/generic/Index";

import "./Resume.css";

interface OwnProps extends RouteComponentProps { };

const Resume: React.FC<OwnProps> = ({ history }: any) => {

    return (
        <IonPage>
            <Header
                title="Resumen"
                titleColor="primary"
                isBackButton={true}
                menuColor="primary"
            />
            <IonContent>
                <IonGrid fixed>
                    <IonItem>
                        <IonCol size="9">
                            <IonLabel >
                                <div className="resume-main-label">Move Urbania</div>
                                <div className="resume-sub-label">Incripcion</div>
                            </IonLabel>
                        </IonCol>

                        <IonCol size="3">
                            <IonLabel className="amount-col" >
                                <div className="resume-amount-label">$599</div>
                                <div>‎</div>
                            </IonLabel>
                        </IonCol>
                    </IonItem>

                    <IonItem>
                        <IonCol size="6">
                            <IonLabel color="primary" >Total</IonLabel>
                        </IonCol>

                        <IonCol size="6">
                            <IonLabel className="amount-col">$2000</IonLabel>
                        </IonCol>
                    </IonItem>
                </IonGrid>
            </IonContent>
        </IonPage>
    )
};

export default Resume;