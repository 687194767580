import React, { useEffect, useState, useContext } from "react";
import { RouteComponentProps } from "react-router";
import { IonLabel, IonPage, IonRow, IonItem, IonIcon, IonToolbar, IonCardTitle, IonAccordionGroup, IonAccordion, IonButtons, IonCard, IonCardContent, IonText, IonPopover, useIonViewDidEnter, IonList, IonFooter } from "@ionic/react";
import ReactPlayer from 'react-player';
import dayjs from "dayjs";
import 'dayjs/locale/es';
import { Swiper, SwiperSlide } from "swiper/react";
import server from "../../api/server";
import { MyToast, Header, MyTitle, MyAlert, MyContent, MyButton, MyModal, MyLoading } from "../../components/generic/Index";
import { AppContext } from "../../data/state";
import { barbellOutline, ellipsisVertical } from "ionicons/icons";
import "swiper/swiper-bundle.css";
import "./Routine.css";

interface OwnProps extends RouteComponentProps { };

const Routine: React.FC<OwnProps> = ({ history }: any) => {
  const { state } = useContext(AppContext);

  dayjs.locale('es');

  const today = dayjs().startOf('day');
  const DATE_FORMAT = "YYYY-MM-DD";

  var sessionCategoryID = 0;
  var routineExerciseID = 0;

  const firstDay = today.add(0, 'day');
  const lastDay = today.add(30, 'day');

  const [selectedDay, setSelectedDay] = useState<string>(today.format(DATE_FORMAT));
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [sessionId, setSessionId] = useState(0);
  const [daySession, setDaySession] = useState(0);
  const [wasCompleted, setWasCompleted] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const toggleLoading = () => { setIsLoading(prevState => !prevState); };

  const [toastMessage, setToastMessage] = useState("");
  const [isToastOpen, setIsToastOpen] = useState(false);
  const [toastColor, setToastColor] = useState("");

  const [alertHeaderText, setAlertHeaderText] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [alertConfirmButtonText, setAlertConfirmButtonText] = useState("");
  const [alertCancelButtonText, setAlertCancelButtonText] = useState("");
  const [isOpenAlert, setIsOpenAlert] = useState(false);
  const [alertConfirmHandler, setAlertConfirmHandler] = useState(() => () => { });
  const [alertCancelHandler, setAlertCancelHandler] = useState(() => () => { });

  const [showPopover, setShowPopover] = useState<{ open: boolean, event: Event | undefined }>({ open: false, event: undefined, });
  const [isTutorialOpen, setIsTutorialOpen] = useState(false);
  const [isCardioOpen, setIsCardioOpen] = useState(false);

  const [routinesList, setRoutinesList] = useState([]);
  const [datesWithRoutineList, setDatesWithRoutineList] = useState([])
  const [firstSessionRoutine, setFirstSessionRoutine] = useState<any>([]);
  const [hasSessionRoutine, setHasSessionRoutine] = useState(false);
  const [repetitionsList, setRepetitionsList] = useState([]);
  const [isCompleted, setIsCompleted] = useState([]);
  const [ppmCardio, setPpmCardio] = useState(0);

  const [video, setVideo] = useState("");
  const [tutorialName, setTutorialName] = useState("");
  const [tutorialSubtitle, setTutorialSubtitle] = useState("");
  const [userClubID] = useState(state.user.club.id);

  const [totalExercises, setTotalExercises] = useState(0);
  const [totalExercisesCompleted, setTotalExercisesCompleted] = useState(1);

  useIonViewDidEnter(() => {
    getDatesWithRoutine();
  });

  useEffect(() => {
    getDatesWithRoutine();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getRoutines = async (sessionID: any, daySessionNumber: any) => {
    setIsLoading(true);
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${state.token}`,
    };

    setRoutinesList([]);
    try {
      const res = await server.get(`/customer/training/${sessionID}/${daySessionNumber}`, { headers: headers });
      const response = res.data;
      setTotalExercises(response.data.totalExercises);
      setTotalExercisesCompleted(response.data.totalCompleted);
      setRoutinesList(response.data.exercises);
      setHasSessionRoutine(false);
      setFirstSessionRoutine([]);
      setPpmCardio(response.data.ppm);
      getFirstSessionId(response.data.exercises);
      setRepetitionsList(response.data.session);
      setIsLoading(false);
    } catch (error: any) {
      let errorCode = error.response?.status;
      setRoutinesList([]);
      if (errorCode === 400) {
        //400 = User does not have training assigned
        setAlertConfirmHandler(() => goNewRoutine);
        setAlertCancelHandler(() => goHome);
        setAlertHeaderText("Nueva rutina");
        setAlertMessage("¿Desea crear una rutina de entrenamiento?");
        setAlertConfirmButtonText("Aceptar");
        setAlertCancelButtonText("Volver a inicio");
        setIsOpenAlert(true);
      } else if (errorCode === 409) {
        //404 = User does not have routine for today
        setAlertConfirmHandler(() => goCreateRoutine);
        setAlertCancelHandler(() => goHome);
        setAlertHeaderText("Sin rutinas disponibles");
        setAlertMessage("¿Desea crear una rutina semanal?");
        setAlertConfirmButtonText("Aceptar");
        setAlertCancelButtonText("Volver a inicio");
        setIsOpenAlert(true);
      } else {
        //Internal Server Error
        setToastMessage("Hubo un problema al cargar los datos. Inténtalo de nuevo mas tarde.");
        setIsToastOpen(true);
        setToastColor("danger");
      }
      //409 - User does not have routine for today
      setHasSessionRoutine(false);
      setFirstSessionRoutine([]);
      setIsLoading(false);
    }
  };

  const getDatesWithRoutine = async () => {
    setIsLoading(true);
    const dates = {
      "StartDate": firstDay.format(DATE_FORMAT),
      "EndDate": lastDay.format(DATE_FORMAT)
    };

    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${state.token}`,
    };

    setDatesWithRoutineList([]);

    try {
      const res = await server.post('/customer/training/user-session/weeks', JSON.stringify(dates), { headers: headers });
      const response = res.data;

      getFirstNotCompleted(response.data.weeks);
      await getRoutines(response.data.currentDay.sessionID, response.data.currentDay.day);
      setSessionId(response.data.currentDay.sessionID);
      setDaySession(response.data.currentDay.day);
      setWasCompleted(response.data.currentDay.completed);
      setDatesWithRoutineList(response.data.weeks);
      setIsLoading(false);
    } catch (error: any) {
      let errorCode = error.response?.status;

      setRoutinesList([]);
      if (errorCode === 400) {
        //400 = User does not have training assigned
        setAlertConfirmHandler(() => goNewRoutine);
        setAlertCancelHandler(() => goHome);
        setAlertHeaderText("Nueva rutina");
        setAlertMessage("¿Desea crear una rutina de entrenamiento?");
        setAlertConfirmButtonText("Aceptar");
        setAlertCancelButtonText("Volver a inicio");
        setIsOpenAlert(true);
      } else if (errorCode === 409) {
        //404 = User does not have routine for today
        setAlertConfirmHandler(() => goCreateRoutine);
        setAlertCancelHandler(() => goHome);
        setAlertHeaderText("Sin rutinas disponibles");
        setAlertMessage("¿Desea crear una rutina semanal?");
        setAlertConfirmButtonText("Aceptar");
        setAlertCancelButtonText("Volver a inicio");
        setIsOpenAlert(true);
      } else {
        //Internal Server Error
        setToastMessage("Hubo un problema al cargar los datos. Inténtalo de nuevo mas tarde.");
        setIsToastOpen(true);
        setToastColor("danger");
      }
      //409 - User does not have routine for today
      setHasSessionRoutine(false);
      setFirstSessionRoutine([]);
      setIsLoading(false);
    }
  };

  const getFirstNotCompleted = (sessions: any) => {
    for (let index = 0; index < sessions.length; index++) {
      if (sessions[index].completed === false) {
        setSelectedIndex(index);
        break;
      }
    }
  };

  const getFirstSessionId = (exercises: any) => {
    const exercisesKV = Object.entries(exercises);
    for (let [k, v] of exercisesKV) {
      const valueExercise = Object.entries(v);

      for (let [key, value] of valueExercise) {
        if (value.hasRoutine) {
          var data = {
            sessionRoutineID: value.sessionRoutineID,
            category: {
              category: value.category.category,
              id: value.category.id
            }
          };
          setFirstSessionRoutine(data);
          setHasSessionRoutine(true);
          return;
        }
      }
    }
  };

  const finishActivity = async (sessionCategoryId: number, routineExerciseId: number) => {
    sessionCategoryID = sessionCategoryId;
    routineExerciseID = routineExerciseId;

    setAlertConfirmHandler(() => onConfirmCompleteActivity);
    setAlertCancelHandler(() => onAlertDismiss);
    setAlertHeaderText("Confirmar acción");
    setAlertMessage("¿Desea marcar la actividad como completada?");
    setAlertConfirmButtonText("Completar");
    setAlertCancelButtonText("Cancelar");
    setIsOpenAlert(true);
  };

  function onConfirmCompleteActivity() {
    completeActivity();
  };

  async function completeActivity() {
    setIsLoading(true);

    const exerciseData = {
      sessionCategoryID: sessionCategoryID,
      routineExerciseID: routineExerciseID
    };

    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${state.token}`,
    };

    try {
      const res = await server.post(
        '/customer/training/routine/complete-exercise',
        JSON.stringify(exerciseData),
        { headers: headers }
      );

      const response = res.data;
      setIsCompleted(response.data.isCompleted);
      setToastMessage("Acciòn completada correctamente.");
      setToastColor("primary");

      if (totalExercisesCompleted + 1 === totalExercises) {
        await getDatesWithRoutine();
      } else {
        await getRoutines(sessionId, daySession);
      }
      setIsLoading(false);
    } catch (error: any) {
      console.warn(error);
      setToastMessage("Hubo un problema al realizar la acciòn. Inténtalo de nuevo mas tarde.");
      setToastColor("danger");
      setIsToastOpen(true);
      setIsLoading(false); 
    }
  };

  const completeDayHandler = async () => {
    setAlertConfirmHandler(() => completeDay);
    setAlertCancelHandler(() => onAlertDismiss);
    setAlertHeaderText("Confirmar acción");
    setAlertMessage("¿Desea marcar día como completado?");
    setAlertConfirmButtonText("Completar");
    setAlertCancelButtonText("Cancelar");
    setIsOpenAlert(true);
  };

  const completeDay = async () => {
    setIsLoading(true);

    const dayData = {
      SessionID: sessionId,
      Day: daySession
    };

    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${state.token}`,
    };

    try {
      const res = await server.post(
        '/customer/training/routine/complete-day/',
        JSON.stringify(dayData),
        { headers: headers }
      );

      const response = res.data;
      // set target excercise
      setToastMessage("Acciòn completada correctamente.");
      setToastColor("primary");
      setIsToastOpen(true);
      setSessionId(0);
      setDaySession(0);
      await getDatesWithRoutine();
      setIsLoading(false);
    } catch (error: any) {
      console.warn(error);
      setToastMessage("Hubo un problema al realizar la acciòn. Inténtalo de nuevo mas tarde.");
      setToastColor("danger");
      setIsToastOpen(true);
      setIsLoading(false); 
    }
  };

  const onAlertDismiss = (open: boolean) => {
    setIsOpenAlert(open);
  };

  const changeDay = (e: any, index: any) => {
    setSelectedIndex(index);
    setSessionId(e.sessionID);
    setDaySession(e.day);
    setWasCompleted(e.completed);
    getRoutines(e.sessionID, e.day);
  };

  const openTutorial = (videoId: string, tutorialName: string, tutorialSubtitle: string) => {
    //toggleLoading();
    setVideo(`https://moveapi.wsd.com.mx/api/customer/training/tutorial/${videoId}`);
    setTutorialName(tutorialName);
    setTutorialSubtitle(tutorialSubtitle);
    setIsTutorialOpen(true);
  };

  const goToLessons = () => {
    history.push(`/clublist/${userClubID}/lessons`);
  };

  const goToPool = () => {
    window.open('https://move.mx/agenda-tu-cita/alberca/', '_blank');
  };

  const editRoutine = () => {
    history.push({
      pathname: '/editRoutine',
      state: {
        session: firstSessionRoutine,
        selectedDay: selectedDay,
      }
    });
  };

  const goCreateRoutine = () => {
    history.push({
      pathname: "/createRoutine"
    });
  };

  const goNewRoutine = () => {
    history.push("/conditioning");
  };

  const goHome = () => {
    history.replace("/profile");
  };

  const formatTitle = (sessionDay: any) => {
    const name = `${sessionDay.objective.substring(0, 2)} ${sessionDay.sessionAcronym} D${sessionDay.day}`;
    return name;
  };

  const getColor = (index: any, sessionDayCompleted: any) => {
    let color = "";

    if (selectedIndex === index) {
      if (wasCompleted === true) {
        color = " completed-day ";
        return color;
      } else {
        color = " selected ";
      }
    }

    if (sessionDayCompleted === true) {
      color += " no-routine ";
    }
    return color;
  };

  return (
    <IonPage>
      <Header
        title="Entrenamiento"
        titleColor="primary"
        isBackButton={false}
        isMenu={true}
        menuColor="primary"
        isSecondIcon={true}
        secondSlot="end"
        secondIcon={ellipsisVertical}
        onclickSecondIcon={(e) => setShowPopover({ open: true, event: e.nativeEvent })}
      />
      <MyContent hasRefresher refreshMethod={getDatesWithRoutine}>
        <MyTitle title="Rutinas" />
        <IonToolbar color="none">
          <div className="datepicker-container">
            <div className="days-container-wrapper">
              <div className="days-container">
                {datesWithRoutineList &&
                  datesWithRoutineList.map((sessionDay, index) => (
                    <div key={index}
                      className={`day-card ${getColor(index, sessionDay.completed)}`}
                      onClick={() => changeDay(sessionDay, index)}>
                      <div className="day-name">{formatTitle(sessionDay)}</div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </IonToolbar>
        <IonList >
          {routinesList &&
            Object.entries(routinesList).map(([k, routine], index) => {
              return (
                <IonCard key={index}>
                  <IonCardTitle>
                    <IonItem color="primary" lines="none">
                      <IonText>{k}</IonText>
                    </IonItem>
                  </IonCardTitle>
                  <IonAccordionGroup animated expand="compact" key={index}>
                    {routine.map((muscleExercise: any, muscleIndex: any) => {
                      return (
                        <IonAccordion value={muscleExercise} key={index + muscleIndex} readonly={muscleExercise.category.familyCategoryID === 2}>
                          <IonItem slot="header" >
                            <IonLabel key={index + muscleIndex}>
                              {(() => {
                                switch (muscleExercise.category.familyCategoryID) {
                                  case 1:
                                    return <div>  {/* exercises categorygrupo muscular*/}
                                      <div className="activity-name">{muscleExercise.exerciseName}</div>
                                      <div className="activity-description">{muscleExercise.exerciseOption}</div>
                                      <div className="activity-duration">Series: {repetitionsList.strengthSeries} x {repetitionsList.strengthRepets}</div>
                                    </div>;
                                  case 2:
                                    return <div> {/* Funcional category */}
                                      <div className="activity-name">{muscleExercise.exerciseName}</div>
                                      <div className="activity-description">Número de ejercicios: {repetitionsList.functionalExcercises}</div>
                                      <div className="activity-description">Intensidad: {repetitionsList?.functionalIntensity}</div>
                                      <div className="activity-description">Volumen: {repetitionsList?.functionalVolume}</div>
                                      <div className="activity-description">Mètodo: {Object(repetitionsList?.functionalMethod).method}</div>
                                      <div className="activity-description">Segmentaciòn: {repetitionsList?.functionalSegments}</div>
                                    </div>;
                                  case 3:
                                    return <div> {/* Cardio category */}
                                      <div className="activity-name">{muscleExercise.exerciseName}</div>
                                      <div className="activity-description">Intensidad: {repetitionsList.cardioIntensity}%</div>
                                      {ppmCardio && ppmCardio !== 0 ?
                                        <div className="activity-description">PPM: {ppmCardio}</div>
                                        : null
                                      }
                                      <div className="activity-description">Tiempo: {repetitionsList.cardioTime} minutos</div>
                                      <div className="activity-description">Metodo: {Object(repetitionsList?.cardioMethod).method}</div>
                                      <div className="activity-description">Segmentaciòn: {Object(repetitionsList?.cardioSegment).segment}</div>
                                    </div>;
                                  case 4:
                                    return <div> {/* Classes category */}
                                      <div className="activity-name">{muscleExercise.exerciseName}</div>
                                      <div className="activity-description">Actividad dirigida por instructor</div>
                                    </div>;
                                  case 5:
                                    return <div> {/* Pool category */}
                                      <div className="activity-name">{muscleExercise.exerciseName}</div>
                                      <div className="activity-description">Actividad sujeta a disponibilidad</div>
                                    </div>;
                                  default:
                                    return null;
                                }
                              })()}
                            </IonLabel>
                          </IonItem>
                          {
                            muscleExercise.category.familyCategoryID !== 2 &&
                            <div className="ion-padding" slot="content" >
                              <IonToolbar color="none">
                                {
                                  (() => {
                                    switch (muscleExercise.category.familyCategoryID) {
                                      case 1:
                                        return <>
                                          <IonButtons slot="start">
                                            <MyButton key={index} onClick={() => finishActivity(muscleExercise.sessionCategoryID, muscleExercise.routineExerciseID)} title="Completar" isDisabled={muscleExercise.isCompleted} color="primary" fill="outline" />
                                          </IonButtons>
                                          <IonButtons slot="end">
                                            <MyButton onClick={() => openTutorial(muscleExercise.trainingExerciseID, muscleExercise.exerciseName, muscleExercise.exerciseOption)} title="Ver tutorial" isDisabled={!muscleExercise.hasTutorial} color="primary" fill="outline" />
                                          </IonButtons>
                                        </>;
                                      case 2:
                                        return;
                                      case 3:
                                        return <IonButtons slot="end">
                                          <MyButton onClick={() => setIsCardioOpen(true)} title="Ver aparatos" color="primary" fill="outline" />
                                        </IonButtons>;
                                      case 4:
                                        return <IonButtons slot="end">
                                          <MyButton onClick={() => goToLessons()} title="Ver clases" color="primary" fill="outline" />
                                        </IonButtons>;
                                      case 5:
                                        return <IonButtons slot="end">
                                          <MyButton onClick={() => goToPool()} title="Agendar" color="primary" fill="outline" />
                                        </IonButtons>;
                                      default:
                                        return null;
                                    }
                                  }
                                  )()}
                              </IonToolbar>
                            </div>
                          }
                        </IonAccordion>
                      );
                    })}
                  </IonAccordionGroup>
                </IonCard>
              );
            })
          }
          {routinesList &&
            routinesList.length === 0 &&
            <IonCard>
              <IonCardContent>
                <IonItem color="none" lines="none">
                  <IonIcon color="primary" className="icon-style" icon={barbellOutline} ></IonIcon>
                </IonItem>

                <IonItem color="none" lines="none">
                  <IonText className="card-text-style">
                    Sin ejercicios disponibles para este dia.
                  </IonText>
                </IonItem>
              </IonCardContent>
            </IonCard>
          }
        </IonList>
        <IonFooter className="ion-no-border">
          {datesWithRoutineList && datesWithRoutineList.length > 0 ?
            <>
              {
                wasCompleted ?
                  <IonRow>
                    <hr className="separator-style" />
                    <IonLabel color="dark" className="club-subtitle-complete-day">
                      Día completado
                    </IonLabel>
                  </IonRow>
                  : <MyButton
                    title="Completar día"
                    color="primary"
                    expand="block"
                    onClick={() => completeDayHandler()}
                  />
              }
            </>
            : null
          }
        </IonFooter>
      </MyContent>
      <MyModal
        isOpen={isTutorialOpen}
        onDidDismiss={() => setIsTutorialOpen(false)}
        breakpoints={[0, 1]}
        title={tutorialName + ' ' + tutorialSubtitle}
      >
        <IonItem color="none" lines="none">
          {video && <ReactPlayer url={video} playing loop muted height={500} />}
        </IonItem>
        <br />
        <IonItem lines="none" color="none">
          <IonText className="ion-text-center">
            Si existen dudas del ejercicio, por favor acercate a tu coach para recibir asesoría.
          </IonText>
        </IonItem>
      </MyModal>
      <MyModal
        isOpen={isCardioOpen}
        onDidDismiss={() => setIsCardioOpen(false)}
        title="Aparatos de cardio"
      >
        <Swiper
          slidesPerView={1}
          pagination={true}
          history={{ key: "slide", }}
          grabCursor={true}
        >
          <SwiperSlide data-history="1">
            <div className="cardio-name-device ion-text-center">Adaptive Motion Trainer (AMT)</div>
            <img
              alt=""
              src="/assets/Cardio/AMT.jpeg"
            />
          </SwiperSlide>
          <SwiperSlide data-history="2">
            <div className="cardio-name-device ion-text-center">Arc Trainer</div>
            <img
              alt=""
              src="/assets/Cardio/ARC TRAINER.jpeg"
            />
          </SwiperSlide>
          <SwiperSlide data-history="3">
            <div className="cardio-name-device ion-text-center">Bicicleta de cycling</div>
            <img
              alt=""
              src="/assets/Cardio/BICICLETA DE CYCLING.jpeg"
            />
          </SwiperSlide>
          <SwiperSlide data-history="4">
            <div className="cardio-name-device ion-text-center">Bicicleta recumbente</div>
            <img
              alt=""
              src="/assets/Cardio/BICICLETA RECUMBENTE.jpeg"
            />
          </SwiperSlide>
          <SwiperSlide data-history="5">
            <div className="cardio-name-device ion-text-center">Bicicleta vertical</div>
            <img
              alt=""
              src="/assets/Cardio/BICICLETA VERTICAL.jpeg"
            />
          </SwiperSlide>
          <SwiperSlide data-history="6">
            <div className="cardio-name-device ion-text-center">Caminadora</div>
            <img
              alt=""
              src="/assets/Cardio/CAMINADORA.jpeg"
            />
          </SwiperSlide>
          <SwiperSlide data-history="7">
            <div className="cardio-name-device ion-text-center">Elíptica</div>
            <img
              alt=""
              src="/assets/Cardio/ELIPTICA.jpeg"
            />
          </SwiperSlide>
          <SwiperSlide data-history="8">
            <div className="cardio-name-device ion-text-center">Escalera</div>
            <img
              alt=""
              src="/assets/Cardio/ESCALERA.jpeg"
            />
          </SwiperSlide>
        </Swiper>
        <hr />
        <IonItem lines="none" color="none">
          <IonText className="ion-text-center">
            Si existen dudas con el uso de los aparatos, por favor acércate a tu coach para recibir asesoría.
          </IonText>
        </IonItem>
      </MyModal>
      <MyLoading
        isOpen={isLoading}
        toggleLoading={toggleLoading}
      />
      <MyAlert
        isOpen={isOpenAlert}
        headerText={alertHeaderText}
        message={alertMessage}
        cancelButtonText={alertCancelButtonText}
        confirmButtonText={alertConfirmButtonText}
        onDismiss={(() => setIsOpenAlert(false))}
        onCancel={alertCancelHandler}
        onConfirm={alertConfirmHandler}
      />
      <MyToast
        message={toastMessage}
        isOpen={isToastOpen}
        onDismiss={() => setIsToastOpen(false)}
        color={toastColor}
      />
      <IonPopover
        isOpen={showPopover.open}
        event={showPopover.event}
        onDidDismiss={
          () => setShowPopover({
            open: false,
            event: undefined
          })
        }
        animated
        backdropDismiss
        dismissOnSelect
        showBackdrop
        size="auto"
      >
        {
          hasSessionRoutine && !wasCompleted ?
            <IonItem onClick={() => editRoutine()} lines="none">
              Editar rutina del dia
            </IonItem>
            : null
        }
        <IonItem onClick={() => goCreateRoutine()} lines="none">
          Sesiones de entrenamiento
        </IonItem>
      </IonPopover>
    </IonPage>
  );
};

export default Routine;
