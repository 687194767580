import { IonFab, IonFabButton, IonIcon } from "@ionic/react";
import { help } from "ionicons/icons";
import React from "react";

interface MyFloatingButtonProps {
    color?: "primary" | 'secondary' | 'tertiary' | 'success' | 'warning' | 'danger' | 'light' | 'medium' | 'dark' | 'none' | undefined,
    icon?: string,
    horizontal?: "center" | "end" | "start" | undefined,
    vertical?: "bottom" | "center" | "top" | undefined,
    isDisabled?: boolean,
    isActivated?: boolean,
    size?: 'small' | undefined,
    onClick?: () => void,
}

const MyFloatingButton: React.FC<MyFloatingButtonProps> = ({

    color = 'light',
    icon = help,
    horizontal = 'end',
    vertical = 'bottom',
    isDisabled = false,
    isActivated = false,
    size,
    onClick,

}) => {
    return (
        <IonFab
            vertical={vertical}
            horizontal={horizontal}
            activated={isActivated}
        >
            <IonFabButton
                size={size}
                disabled={isDisabled}
                onClick={onClick}
                aria-label="fab button"
            >
                <IonIcon
                    style={{ margin: '0', fontSize: '28px' }}
                    icon={icon}
                    color={color}
                />
            </IonFabButton>
        </IonFab>
    )
};

export default MyFloatingButton;