import React, { useEffect, useState, useContext } from "react";
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonPage,
  IonRow,
  IonText,
  IonTitle,
  IonToolbar,
  useIonAlert
} from "@ionic/react";
import { Loading, Header, ModalAccess, MyToast } from "../../components/generic/Index";
import { BarcodeScanner } from "@capacitor-community/barcode-scanner";
import { App } from "@capacitor/app";
import { stopCircleOutline } from "ionicons/icons";
import { RouteComponentProps } from "react-router-dom";
import server from "../../api/server";
import { AppContext } from "../../data/state";

import "./Access.css";

interface OwnProps extends RouteComponentProps {}

const errors: any = {
  "404": "QR Inválido, Por favor intentalo de nuevo",
  "400": "Error al procesar QR, Comunicate con la administración",
  "409":
    "Error al enviar petición al torniquete, Comunicate con la administración",
  "500": "Error Inesperado, por favor comunicate con la administración",
};

const Access: React.FC<OwnProps> = ({ history }) => {
  const { state } = useContext(AppContext);
  const [err, setErr] = useState<string>();
  const [hideBg, setHideBg] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isShowModal, setIsShowModal] = useState(false);
  const [present] = useIonAlert();

  const [toastMessage, setToastMessage] = useState("Ingreso satisfactorio.");
  const [isToastOpen, setIsToastOpen] = useState(false);
  const [toastColor, setToastColor] = useState("primary");

  useEffect(() => {
    App.addListener("appStateChange", async ({ isActive }) => {
      if (isActive) {
        const res = await checkPermission(false);
        if (res) {
          setIsShowModal(false);
          setErr(undefined);
          startScan();
        }
      }
    });

    checkAPIScan();
    //App.removeAllListeners();
  }, []);

  const startScan = async () => {
    BarcodeScanner.hideBackground(); // make background of WebView transparent
    setHideBg("hideBg");
    const result = await BarcodeScanner.startScan(); // start scanning and wait for a result
    stopScan();

    if (result.hasContent) {
      checkQrCode(result.content);
    }
  };

  const stopScan = async () => {
    await BarcodeScanner.showBackground();
    await BarcodeScanner.stopScan();
  };

  const checkQrCode = async (contentQR: any) => {
    setIsLoading(true);

    const headers = {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${state.token}`,
    };

    const data = {
      token: contentQR,
    };

    try {
      await server.post("/user/club-access", JSON.stringify(data), {
        headers: headers,
      });

      //setIsToastOpen(true);
      setToastColor("primary");
      setTimeout(() => {
        setIsLoading(false);
        history.replace({
          pathname: "/profile",
          state: {
            isQRok: true,
          }
        });
      }, 1500);

    } catch (error: any) {
      setIsLoading(false);
      const response = error.response;
      if (response.data.message) {
        const statusCode = String(response.data?.statusCode);
        present(errors[statusCode], [
          { text: "OK", role: "confirm", handler: () => {} },
        ]);
        startScan();

      } else {
        present(`${errors["500"]}`, [
          { text: "OK", role: "confirm", handler: () => {} },
        ]);
        startScan();
      }
    }
  };

  const checkPermission = async (force = true) => {
    try {
      const status = await BarcodeScanner.checkPermission({ force });
      if (status.granted) {
        return true;
      }
      // user denied permission
      return false;
    } catch (error: any) {
      setErr(error.message);
      console.log(error.message);
    }
  };

  const checkAPIScan = async () => {
    try {
      const response: any = await checkPermission();
      if (response) {
        startScan();
      } else {
        setIsShowModal(true);
        setErr("Permission denied");
      }
    } catch (error) {
      setErr("Permission denied");
    }
  };

  const goBackProfile = () => {
    setIsShowModal(false);
    history.replace("/profile");
  };

  const redirectPermissions = async () => {
    await BarcodeScanner.openAppSettings();
  };

  if (err) {
    return (
      <IonPage>
        <Header
          title="Move Wellnesss"
          titleColor="primary"
          isBackButton={true}
          isMenu={false}
          menuColor="primary"
        />
        {isShowModal && (
          <ModalAccess
            OkHandle={redirectPermissions}
            CancelHandle={goBackProfile}
          />
        )}

        <IonContent className="ion-padding">
          <IonRow>
            <IonText color="danger">{err} Error</IonText>
          </IonRow>
        </IonContent>
      </IonPage>
    );
  }

  return (
    <IonPage>
      <IonHeader>
        {isLoading && <Loading />}
        <IonToolbar>
          <IonTitle color="primary">Move Wellness</IonTitle>

          <IonButtons slot="end">
            <IonButton
              color="danger"
              hidden={!hideBg}
              onClick={async () => {
                await stopScan();
              }}
            >
              <IonIcon icon={stopCircleOutline} slot="start" />
              Cancelar
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className={"hideBg"}>
        <div hidden={!hideBg} className="scan-box" />
      </IonContent>
      <MyToast
        message={toastMessage}
        isOpen={isToastOpen}
        onDismiss={() => setIsToastOpen(false)}
        color={toastColor}
        position="bottom"
      />
    </IonPage>
  );
};

export default Access;
