import React from 'react';
import { IonHeader, IonToolbar, IonBackButton, IonTitle, IonButtons, IonButton, IonIcon, IonMenuButton, useIonViewWillEnter } from '@ionic/react';
import { Device } from '@capacitor/device';
// import { StatusBar, Style } from '@capacitor/status-bar';

interface HeaderProps {
  headerClassname?: string,
  toolbarClassname?: string,
  color?: "primary" | 'secondary' | 'tertiary' | 'success' | 'warning' | 'danger' | 'light' | 'medium' | 'dark' | 'none' | undefined,
  toolbarColor?: "primary" | 'secondary' | 'tertiary' | 'success' | 'warning' | 'danger' | 'light' | 'medium' | 'dark' | 'none' | undefined,
  slot?: string,
  isBackButton?: boolean,
  backButtonText?: string,
  defaultHref?: string,
  firstButtonOnClick?: () => any,
  isFirstIcon?: boolean,
  firstIcon?: any,
  title?: string,
  titleColor?: "primary" | 'secondary' | 'tertiary' | 'success' | 'warning' | 'danger' | 'light' | 'medium' | 'dark' | 'none' | undefined,
  isSecondIcon?: boolean,
  secondSlot?: string,
  onclickSecondIcon?: (e: any) => any,
  secondIcon?: any,
  isMenu?: boolean,
  menuColor?: string
};

const Header: React.FC<HeaderProps> = ({
  toolbarClassname = '',
  headerClassname = 'ion-no-border',
  color = '',
  slot = 'start',
  isBackButton = true,
  backButtonText = '',
  defaultHref = '/',
  title = '',
  titleColor = 'light',
  isFirstIcon = false,
  firstButtonOnClick = () => { },
  firstIcon = '',
  isSecondIcon = false,
  secondSlot = '',
  onclickSecondIcon = () => { },
  secondIcon = '',
  isMenu = false,
  menuColor = ''

}) => {

  /*   useIonViewWillEnter(() => {
      validatePlatform();
    });
  
    const validatePlatform = async () => {
      const info = await Device.getInfo();
      if (info.platform === 'android') {
        setStatusBarColor();
      }
    }
  
    const setStatusBarColor = async () => {
      await StatusBar.show();
      const prefersDark = window.matchMedia('(prefers-color-scheme: dark)');
  
      if (prefersDark.matches) {
        //dark theme
        StatusBar.setStyle({ style: Style.Light });
        StatusBar.setBackgroundColor({ color: '#ffffff' })
      } else {
        //light theme
        StatusBar.setStyle({ style: Style.Dark });
        StatusBar.setBackgroundColor({ color: '#000000' })
      }
    } */

  return (

    <IonHeader className={headerClassname} color={color}>
      <IonToolbar className={toolbarClassname} color={color}>
        <IonButtons slot={slot}>
          {isBackButton && <IonBackButton defaultHref={defaultHref} text={backButtonText} />}
          {isFirstIcon &&
            <IonButton onClick={firstButtonOnClick}>
              <IonIcon icon={firstIcon}></IonIcon>
            </IonButton>}
          {isMenu &&
            <IonMenuButton color={menuColor} style={{fontSize: '32px'}} />}
        </IonButtons>
        <IonTitle color={titleColor} style={{ textTransform: 'Capitalize' }}>
          {title.toLowerCase()}
        </IonTitle>
        {isSecondIcon &&
          <IonButtons slot={secondSlot}>
            <IonButton onClick={onclickSecondIcon} >
              <IonIcon icon={secondIcon} color={titleColor}></IonIcon>
            </IonButton>
          </IonButtons>}
      </IonToolbar>
    </IonHeader>
  );
};

export default Header;