import {
  IonPage,
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonLabel,
  IonPopover,
  IonButton,
  IonItem,
  IonLoading,
  IonAlert
} from '@ionic/react';
import React, { useState, useContext, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Header, MInput } from "../../components/generic/Index";
import { AppContext } from '../../data/state';
import server from '../../api/server';
import { copy, ellipsisVertical } from 'ionicons/icons';

import './Contact.css';

interface OwnProps extends RouteComponentProps { };

const Contact: React.FC<OwnProps> = ({ history }) => {
  const { state, dispatch } = useContext(AppContext);
  const [contactName, setContactName] = useState('');
  const [contactPhone, setContactPhone] = useState('');
  const [contactCellphone, setContactCellphone] = useState('');
  const [relationship, setRelationship] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phonenumber, setPhonenumber] = useState('');

  const [contactNameCopy, setContactNameCopy] = useState('');
  const [contactPhoneCopy, setContactPhoneCopy] = useState('');
  const [contactCellphoneCopy, setContactCellphoneCopy] = useState('');
  const [relationshipCopy, setRelationshipCopy] = useState('');
  const [nameCopy, setNameCopy] = useState('');
  const [emailCopy, setEmailCopy] = useState('');
  const [phonenumberCopy, setPhonenumberCopy] = useState('');

  const [showPopover, setShowPopover] = useState<{ open: boolean, event: Event | undefined }>({
    open: false,
    event: undefined,
  });
  const [editData, setEditData] = useState(false);
  const [showSaving, setShowSaving] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [errorAtSearch, setErrorAtSearch] = useState(false);

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${state.token}`
  };

  const contactDetails = [{
    id: 'name',
    inputname: 'name',
    name: 'Nombre:',
    detail: name,
    editableDetail: nameCopy,
    inputtype: "text",
    editable: false,
    setter: setNameCopy
  }, {
    id: 'email',
    inputname: 'email',
    name: 'Correo: ',
    detail: email,
    editableDetail: emailCopy,
    inputtype: "email",
    editable: true,
    setter: setEmailCopy
  }, {
    id: 'telephone',
    inputname: 'telephone',
    name: 'Celular: ',
    detail: phonenumber,
    editableDetail: phonenumberCopy,
    inputtype: "tel",
    editable: true,
    setter: setPhonenumberCopy
  }];

  const emergencyContact = [{
    id: 'contactName',
    inputname: 'contactName',
    name: 'Nombre:',
    detail: contactName,
    editableDetail: contactNameCopy,
    inputtype: "text",
    setter: setContactNameCopy
  }, {
    id: 'relationship',
    inputname: 'familiar',
    name: 'Parentezco: ',
    detail: relationship,
    editableDetail: relationshipCopy,
    inputtype: "text",
    setter: setRelationshipCopy
  }, {
    id: 'contactCellphone',
    inputname: 'cellular',
    name: 'Celular:',
    detail: contactCellphone,
    editableDetail: contactCellphoneCopy,
    inputtype: "tel",
    setter: setContactCellphoneCopy
  }, {
    id: 'contactPhone',
    inputname: 'telephone',
    name: 'Teléfono:',
    detail: contactPhone,
    editableDetail: contactPhoneCopy,
    inputtype: "tel",
    setter: setContactPhoneCopy
  }];

  useEffect(() => {
    getEmergencyContact();
    getUserInfo();
  }, []);

  const getEmergencyContact = async () => {
    // the await word has no effect here since useeffect 
    // will not await
    setShowLoading(true);
    await server.get('/user/emergency', { headers: headers })
      .then(async (res) => {
        const response = res.data;
        if (response.statusCode != 200) {
          console.log('error');
          return false;
        }
        setContactName(response.data.emergencyContact);
        setContactPhone(response.data.emergencyPhone);
        setContactCellphone(response.data.emergencyCellPhone);
        setRelationship(response.data.relationship);
        setContactNameCopy(response.data.emergencyContact);
        setContactPhoneCopy(response.data.emergencyPhone);
        setContactCellphoneCopy(response.data.emergencyCellPhone);
        setRelationshipCopy(response.data.relationship);
      })
      .catch((error) => {
        console.log(error);
      }
      );
    setShowLoading(false);
  }

  const getUserInfo = async () => {
    // the await word has no effect here since useeffect 
    // will not await
    /*await server.get('/user', { headers: headers})
      .then(async (res) => {
        const response = res.data;
        if (response.statusCode != 200) {
          console.log('error');
          return false;
        }
        setEmail(response.data.email);
        setPhonenumber(response.data.phoneNumber);
      })
      .catch((error) =>{
        console.log(error);
      }
    );*/
    setName(state.user.name);
    setEmail(state.user?.email);
    setPhonenumber(state.user?.phoneNumber);
    setNameCopy(state.user.name);
    setEmailCopy(state.user?.email);
    setPhonenumberCopy(state.user?.phoneNumber);
  }

  const handleEdit = () => {
    setEditData(true);
    setShowPopover({ open: false, event: undefined });
  }

  const _onUpdateData = async () => {
    setShowSaving(true);

    const data = {
      Email: emailCopy,
      PhoneNumber: phonenumberCopy,
      EmergencyContact: contactNameCopy,
      EmergencyPhone: contactPhoneCopy,
      EmergencyCellPhone: contactCellphoneCopy,
      Relationship: relationshipCopy,
    };

    await server.put('/user', JSON.stringify(data)
      , { headers: headers }
    ).then((res) => {
      const response = res.data;
      if (response.statusCode != 200) {
        console.log(response);
        return false;
      }

      setContactName(response.data.emergencyContact);
      setContactPhone(response.data.emergencyPhone);
      setContactCellphone(response.data.emergencyCellPhone);
      setRelationship(response.data.relationship);
      setName(response.data.name);
      setEmail(response.data.email);
      setPhonenumber(response.data.phoneNumber);
      let copyUser = { ...state.user };
      copyUser.image = state.user?.image ?? '';
      copyUser.email = response.data.email;
      copyUser.phoneNumber = response.data.phoneNumber;
      copyUser.age = response.data.age;
      copyUser.birthDate = response.data.birthDate;
      dispatch({
        type: 'set-user',
        user: copyUser
      });

    })
      .catch((error) => {
        setErrorMessage('Error al guardar los datos.');
        setErrorAtSearch(true);
      }
      );
    setEditData(false)
    setShowSaving(false);
  }

  const cancelEdit = () => {
    setContactNameCopy(contactName);
    setContactPhoneCopy(contactPhone);
    setContactCellphoneCopy(contactCellphone);
    setRelationshipCopy(relationship);
    setNameCopy(state.user.name);
    setEmailCopy(state.user?.email);
    setPhonenumberCopy(state.user?.phoneNumber);

    setEditData(false);
  }

  return (

    <IonPage>
      <Header
        title='Contacto'
        titleColor='primary'
        defaultHref='/profile'
        secondSlot='end'
        isSecondIcon={true}
        secondIcon={ellipsisVertical}
        onclickSecondIcon={(e) => setShowPopover({ open: true, event: e.nativeEvent })}
      />

      <IonContent>



        <IonGrid>
          {contactDetails.map((element, i) => {
            return (
              <IonRow key={i}>
                <IonCol size='12'>
                  <IonLabel className="reduced-label" color="medium">
                    {element.name}
                  </IonLabel>
                  <br />
                  {
                    (!editData) ?
                      (element.detail) ?
                        <IonLabel>
                          {element.detail}
                        </IonLabel>
                        : <br />
                      :
                      <MInput
                        inputID={element.id}
                        inputName={element.inputname}
                        value={element.editableDetail}
                        icon='none'
                        placeholder={element.detail}
                        inputType={element.inputtype}
                        isDisabled={!editData || !element.editable}
                        handleChangeValue={element.setter}
                      />
                  }

                </IonCol>
              </IonRow>
            );
          })}
        </IonGrid>

        <hr className='hrNoMargin' />

        <IonGrid>
          <IonLabel className="emergency-contact">Contacto de emergencia</IonLabel>
          {emergencyContact.map((element, i) => {
            return (
              <IonRow key={i}>
                <IonCol size='12'>
                  <IonLabel className="reduced-label" color="medium">
                    {element.name}
                  </IonLabel>
                  <br />
                  {
                    (!editData) ?
                      (element.detail) ?
                        <IonLabel>
                          {element.detail}
                        </IonLabel>
                        : <br />
                      :
                      <MInput
                        inputID={element.id}
                        inputName={element.inputname}
                        value={element.editableDetail}
                        icon='none'
                        placeholder={element.detail}
                        inputType={element.inputtype}
                        isDisabled={!editData}
                        handleChangeValue={element.setter}
                      />
                  }
                </IonCol>
              </IonRow>
            );
          })}
          <IonButton
            color="primary"
            fill="solid"
            onClick={() => _onUpdateData()}
            expand='block'
            hidden={!editData}
          //type="submit"
          >
            Guardar
          </IonButton>

          <IonButton
            color="danger"
            fill="outline"
            onClick={() => cancelEdit()}
            expand='block'
            hidden={!editData}
            className='ion-margin-top'
          >
            Cancelar
          </IonButton>

        </IonGrid>

        <IonLoading
          cssClass='my-custom-class'
          isOpen={showSaving}
          onDidDismiss={() => { }}
          message={'Guardando...'}
          duration={0}
        />

        <IonLoading
          cssClass='my-custom-class'
          isOpen={showLoading}
          onDidDismiss={() => { }}
          message={'Cargando'}
          duration={0}
          spinner='bubbles'
        />

        <IonAlert
          isOpen={errorAtSearch}
          onDidDismiss={() => { }}
          cssClass='my-custom-class'
          header={'Error!'}
          message={errorMessage}
          buttons={[{
            text: 'Aceptar',
            role: 'Ok',
            handler: () => setErrorAtSearch(false)
          }]}
          backdropDismiss={false}
        />
      </IonContent>

      <IonPopover
          isOpen={showPopover.open}
          event={showPopover.event}
          onDidDismiss={() => setShowPopover({ open: false, event: undefined })}
        >
          <IonItem
            disabled={editData}
            lines='none'
            onClick={() => handleEdit()}
          >
            Editar
          </IonItem>
        </IonPopover>
        
    </IonPage>
  );
};

export default Contact;
