import { IonAlert } from "@ionic/react";
import React from 'react';

interface MyAlertProps {
  isOpen: boolean;
  headerText: string;
  message: string;
  cancelButtonText?: string;
  confirmButtonText?: string;
  onDismiss?: () => void;
  onCancel?: () => void;
  onConfirm?: () => void;
}

const MyAlert: React.FC<MyAlertProps> = ({
  isOpen,
  headerText = 'Placeholder title',
  message,
  cancelButtonText,
  confirmButtonText,
  onCancel,
  onDismiss,
  onConfirm

}) => {
  return (

    <IonAlert
      isOpen={isOpen}
      header={headerText}
      message={message}
      onDidDismiss={onDismiss}
      animated
      buttons={[
        {
          text: cancelButtonText,
          role: 'cancel',
          cssClass: 'secondary',
          id: 'cancel-button',
          handler: () => onCancel()
        },
        {
          text: confirmButtonText,
          id: 'confirm-button',
          handler: () => onConfirm()
        }
      ]}
    />
  );
};

export default MyAlert;