import React, { useEffect, useState, useContext } from "react";
import { RouteComponentProps } from "react-router";
import { IonFooter, IonItem, IonLabel, IonList, IonNote, IonPage, IonSelect, IonSelectOption, IonToolbar, } from "@ionic/react";
import { MyAlert, MyToast, Header, MyContent, MyButton, MyLoading, MyModal } from "../../components/generic/Index";
import { useLocation } from "react-router";
import { AppContext } from "../../data/state";
import server from "../../api/server";
import { closeCircleOutline, } from "ionicons/icons";

interface OwnProps extends RouteComponentProps { };

const EditRoutine: React.FC<OwnProps> = ({ history }: any) => {

  const { state } = useContext(AppContext);
  const location = useLocation();
  const headers = { 'Content-Type': 'application/json', 'Authorization': `Bearer ${state.token}`, };

  const [isLoading, setIsLoading] = useState(false);
  const toggleLoading = () => { setIsLoading(prevState => !prevState); };
  const [isModalAddExerciseOpen, setIsModalAddExerciseOpen] = useState(false);

  const [toastMessage, setToastMessage] = useState("");
  const [isToastOpen, setIsToastOpen] = useState(false);
  const [toastColor, setToastColor] = useState("");

  const [alertHeaderText, setAlertHeaderText] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [isOpenAlert, setIsOpenAlert] = useState(false);

  const [routinelist, setRoutinelist] = useState([]);
  const [exerciseId, setExerciseId] = useState(Number);
  const [sessionRoutineID, setSessionRoutineID] = useState(Number);

  const [category, setCategory] = useState("");
  const [categoryId, setCategoryId] = useState(-1);
  const [muscularGroupCategory, setMuscularGroupCategory] = useState([]);
  const [muscularGroup, setMuscularGroup] = useState("1");
  const [trainingExerciseCategory, setTrainingExerciseCategory] = useState([]);
  const [trainingExercise, setTrainingExercise] = useState("");
  const [optionExerciseCategory, setOptionExerciseCategory] = useState([]);
  const [optionExercise, setOptionExercise] = useState("");

  useEffect(() => {
    if (location.state && location.state.session?.sessionRoutineID !== undefined) {
      setSessionRoutineID(location.state.session.sessionRoutineID);
      getRoutineToEdit(location.state.session.sessionRoutineID);
    }
    if (location.state && location.state.session?.category !== undefined) {
      setCategory(location.state.session.category.category);
      setCategoryId(1);
    }
  }, []);

  const addExercise = async () => {
    toggleLoading();
    try {
      const res = await server.get(`/customer/training/muscle/${categoryId}`, 
        { headers: headers });
        const response = res.data;
        setMuscularGroupCategory(response.data);
        toggleLoading();
    } catch (error: any) {
      setToastMessage("Hubo un problema al realizar la acciòn. Inténtalo de nuevo mas tarde");
      setIsToastOpen(true);
      setToastColor("danger");
      toggleLoading();
    }
  };

  const getRoutineToEdit = async (sessionRoutineId: any) => {
    toggleLoading();

    try {
      const res = await server.get(`/customer/training/routine/${sessionRoutineId}`, 
        { headers: headers });
      const response = res.data;
      setRoutinelist(response.data);
      toggleLoading();
    } catch (error: any) {
      setRoutinelist([]);
      console.log(error); 
    }
  };

  const openEditExercise = (id: any) => {
    setExerciseId(id);
    setIsModalAddExerciseOpen(true);
    setMuscularGroup("0");
    addExercise();
  };

  const handleMuscularGroup = (data: any) => {
    getTrainingExercise(data);
    setMuscularGroup(data);
  };

  const getTrainingExercise = async (muscularGroupId: number) => {
    try {
      const res = await server.get(`/customer/training/training-exercise?TrainingCategoryID=${categoryId}&MuscleGroupID=${muscularGroupId}`, 
        { headers: headers });

      const response = res.data;
      setTrainingExerciseCategory(response.data);
      if (response.data?.length > 0) {
        setTrainingExercise(response.data[0].id);
      } else {
        setTrainingExercise("");
      }
    } catch (error: any) {
        console.log(error);
    }
  };

  const handleExercise = (data: any) => {
    getTrainingOption(data);
    setTrainingExercise(data);
  };

  const getTrainingOption = async (data: any) => {
    try {
      const res = await server.get(`/customer/training/training-exercise/option/${data}`, 
        { headers: headers });

      const response = res.data;
      setOptionExerciseCategory(response.data);
    } catch (error: any) {
      console.log(error);  
    }
  };

  const confirmEditExercise = async () => {
    toggleLoading();

    const data = {
      TrainingExerciseCategoryID: optionExercise,
    };

    try {
      await server.put(
        `/customer/training/routine/${exerciseId}`,
        JSON.stringify(data),
        { headers: headers }
      );
      setToastColor("primary");
      setToastMessage("Actividad agregada con éxito.");
      setIsToastOpen(true);
      getRoutineToEdit(sessionRoutineID);
      setIsModalAddExerciseOpen(false);
      toggleLoading();
    } catch (error: any) {
      console.log(error);
      toggleLoading();
      setToastMessage("Hubo un problema al realizar la acciòn. Inténtalo de nuevo mas tarde");
      setIsToastOpen(true);
      setToastColor("danger");
    }
  };

  const goBack = () => {
    setTimeout(() => {
      history.goBack();
    }, 1100);
  };

  const onToastDismiss = () => {
    setIsToastOpen(!isToastOpen);
  };

  const onAlertDismiss = () => {
    setIsOpenAlert(!isOpenAlert);
  };

  return (
    <IonPage>
      <Header
        title="Editar rutina del dia"
        titleColor="primary"
        isBackButton={false}
      />

      <MyContent >
        <IonList>
          {routinelist.map((routine: any, index: number) => {
            return (
              <IonItem
                key={index}
                lines="inset"
                button
                detail
                detailIcon={closeCircleOutline}
                onClick={() => openEditExercise(routine.id)}
              >
                <IonLabel style={{ whiteSpace: 'normal' }}>
                  <h2 children={routine.exercise.trainingExersice.name} />
                  <IonNote children={routine.exercise.trainingExersice.option ? routine.exercise.trainingExersice.option : 'Sin especificación'} />
                </IonLabel>
              </IonItem>
            )
          })}
        </IonList>
      </MyContent>
      <IonFooter className="ion-no-border">
        <IonToolbar>
          <MyButton onClick={goBack} title="Guardar y volver" expand="block" color="primary" />
        </IonToolbar>
      </IonFooter>
      <MyModal
        isOpen={isModalAddExerciseOpen}
        onDidDismiss={() => setIsModalAddExerciseOpen(false)}
        title="Agregar actividad"
        breakpoints={[0, 0.7, 1]}
        initialbreakpoint={0.7}
      >
        <IonList >
          <IonItem lines="inset" >
            <IonLabel
              position="stacked"
              color="primary"
              children={"Grupo muscular"}
            />
            <IonSelect
              interfaceOptions={{ subHeader: 'Selecciona un grupo muscular' }}
              placeholder="Selecciona un grupo muscular"
              onIonChange={(e) => handleMuscularGroup(e.target.value)}
              value={muscularGroup}
              interface="popover" >
              {muscularGroupCategory &&
                muscularGroupCategory.map((item: any) => {
                  return (
                    <IonSelectOption
                      value={item.id}
                      key={item.id}
                      children={item.group}
                    />
                  )
                })
              }
            </IonSelect>
          </IonItem>
          <IonItem
            lines="inset" >
            <IonLabel
              position="stacked"
              color="primary"
              children="Ejercicio"
            />
            <IonSelect
              interfaceOptions={{ subHeader: 'Selecciona una opcion', }}
              placeholder="Selecciona un ejercicio"
              onIonChange={(e) => handleExercise(e.target.value)}
              value={trainingExercise}
              interface="popover" >
              {trainingExerciseCategory &&
                trainingExerciseCategory.map((item: any) => {
                  return (
                    <IonSelectOption
                      value={item.id}
                      key={item.id}
                      children={item.name}
                    />
                  )
                })
              }
            </IonSelect>
          </IonItem>
          <IonItem lines="inset" >
            <IonLabel
              position="stacked"
              color="primary"
              children="Opciòn"
            />
            <IonSelect
              interfaceOptions={{ subHeader: 'Selecciona una opciòn' }}
              placeholder="Selecciona una variante"
              onIonChange={(e) => setOptionExercise(e.target.value!)}
              value={optionExercise}
              interface="popover">
              {optionExerciseCategory &&
                optionExerciseCategory.map((item: any) => {
                  return (
                    <IonSelectOption
                      value={item.id}
                      key={item.id}>
                      {item.trainingExersice.option &&
                        item.trainingExersice.option + " - "
                      }
                      {item.trainingExersice.name}
                    </IonSelectOption>
                  )
                })
              }
            </IonSelect>
          </IonItem>
        </IonList>
        <IonList>
          <IonFooter className="ion-no-border">
            <IonToolbar color="none">
              <MyButton
                title="Guardar cambios"
                expand="block"
                onClick={confirmEditExercise}
              />
            </IonToolbar>
          </IonFooter>
        </IonList>
      </MyModal>
      <MyLoading
        isOpen={isLoading}
        toggleLoading={toggleLoading}
      />
      <MyAlert
        headerText={alertHeaderText}
        message={alertMessage}
        isOpen={isOpenAlert}
        onDismiss={onAlertDismiss}
      />
      <MyToast
        message={toastMessage}
        isOpen={isToastOpen}
        onDismiss={onToastDismiss}
        color={toastColor}
      />
    </IonPage>
  )
};

export default EditRoutine;
