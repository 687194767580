import {
  IonContent, IonPage, IonButton,
  IonToast, useIonViewWillEnter, IonGrid
} from '@ionic/react';
import React, { useState, useEffect, useContext } from 'react';
import { App } from '@capacitor/app';
import './Home.css';
import Logo from '../components/generic/MyLogo/Logo';
import { RouteComponentProps } from 'react-router';
import { AppContext } from '../data/state';

interface OwnProps extends RouteComponentProps { };

const Home: React.FC<OwnProps> = ({ history }) => {
  const { state, dispatch } = useContext(AppContext);
  const [toastNotImplemented, setToastNotImplemented] = useState(false);
  const [showExitToast, setShowExitToast] = useState(false);

  const buttonInfo = [{
    name: 'Inicia sesión',
    onclick: () => {
      dispatch({
        type: "set-is-sign-in",
        isSignIn: false,
      })
      history.replace('/signIn'); 
    }
  }, {
    name: 'Registrate',
    onclick: () => { history.replace('/signUp'); }
  }, { 
    name: 'Sucursales',
    onclick: () => { 
      dispatch({
        type: "set-is-sign-in",
        isSignIn: true,
      })
      history.push('/clublist'); 
    }
  }];

  useIonViewWillEnter(() => {
    let menu = document.getElementById('moveMenu');
    menu?.setAttribute('disabled', 'disabled');
    dispatch({
      type: 'set-is-loggedin',
      isLoggedin: false
    });
    dispatch({
      type: 'set-user',
      user: undefined
    });
    dispatch({
      type: 'set-token',
      token: undefined
    });
    dispatch({
      type: 'set-is-inside-club',
      isInsideClub: false,
    });
    dispatch({
      type: 'set-user-family',
      userFamily: undefined,
    });
  });

  useEffect(() => {
    let menu = document.getElementById('moveMenu');
    menu?.setAttribute('disabled', 'disabled');
    // set state variables
    dispatch({
      type: 'set-is-loggedin',
      isLoggedin: false
    });
    dispatch({
      type: 'set-user',
      user: undefined
    });
    dispatch({
      type: 'set-token',
      token: undefined
    });
  }, []);

  //#region EndApp
  document.addEventListener('ionBackButton', (ev: any) => {
    if (history.location.pathname === '/home') {
      ev.detail.register(-1, () => {
        if (showExitToast) {
          App.exitApp();
        } else {
          setShowExitToast(true);
        }
      });
    }
  });
  //#endregion EndApp

  return (

    <IonPage>
      <IonContent color='dark'>
        <div className="centerContainer">
          <Logo />
          <br />
          <IonGrid fixed>
            {buttonInfo.map((element, i) => {
              return (
                <IonButton key={i} className='principalButton'
                  shape="round" color='primary' expand='block'
                  onClick={buttonInfo[i].onclick}>
                  {buttonInfo[i].name}
                </IonButton>
              );
            })}
          </IonGrid>
        </div>

        <IonToast
          isOpen={toastNotImplemented}
          onDidDismiss={() => setToastNotImplemented(false)}
          message="Proximamente."
          duration={800}
        />
        <IonToast
          isOpen={toastNotImplemented}
          onDidDismiss={() => setToastNotImplemented(false)}
          message="Proximamente."
          duration={800}
        />
      </IonContent>
    </IonPage>
  );
};

export default Home;
