import { useContext } from "react";
import { AppContext } from "../data/state";

export const useDispatch = () => {
    const { dispatch } = useContext(AppContext);
    const dispatchAction = () => {
        dispatch({
            type: 'set-is-loggedin',
            isLoggedin: false,
        });
        dispatch({
            type: 'set-token',
            token: undefined,
        });
    }

    return { dispatchAction };
}