//import { Plugins } from '@capacitor/core';
//const { Storage } = Plugins
import { Storage } from '@capacitor/storage';

/**
 * Saves data in storage 
 * @param key Name of the object to save
 * @param value Object to save in storage
 */
export const DoSet = async (key:string, value: any) => {
  await Storage.set({
    key,
    value: JSON.stringify(value)
  });
};

/**
 * Get the user data previously saved for
 * quicker logins.
 * If no data returns undefined.
 * @param key Name of the object to retrieve
 */
export const DoGet = async(key: string) => {
  let userData = undefined;
  const val = await Storage.get({ key });
  if ( val.value ) {
    userData = JSON.parse(val.value);
  }
  return userData;
};

/**
 * Removes an object from storage
 * @param key Name of the object to remove
 */
export const DoRemove = async(key: string) => {
  await Storage.remove({ key });
}