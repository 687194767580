import { IonButton } from "@ionic/react"
import React from "react";

interface MyButtonProps {
    title?: string,
    color?: "primary" | 'secondary' | 'tertiary' | 'success' | 'warning' | 'danger' | 'light' | 'medium' | 'dark' | 'none' | undefined,
    fill?: "clear" | "clear" | "outline" | "solid" | undefined,
    shape?: "round" | undefined,
    expand?: "block" | "full" | undefined,
    size?: "default" | "large" | "small" | undefined,
    strong?: boolean,
    type?: "button" | "reset" | "submit",
    isDisabled?: boolean,
    onClick?: () => void,
}

const MyButton: React.FC<MyButtonProps> = ({

    title = 'Placeholder',
    color,
    fill,
    shape = 'round',
    expand,
    size,
    strong,
    type = 'button',
    isDisabled,
    onClick,

}) => {

    return (

        <IonButton
            color={color}
            fill={fill}
            shape={shape}
            expand={expand}
            size={size}
            strong={strong}
            type={type}
            disabled={isDisabled}
            onClick={onClick}
        >{title}
        </IonButton>
    )
};

export default MyButton;

