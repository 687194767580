import React, { useContext, useState } from 'react';
import { IonPage, IonItem, IonText, IonLoading, IonTextarea, IonToast, IonList, } from '@ionic/react';
import { MyContent, Header, MyModal, MyButton, MyLogo } from '../../components/generic/Index';
import { RouteComponentProps, } from 'react-router-dom';
import { AppContext } from '../../data/state';
import { informationCircle } from 'ionicons/icons';
import { Browser } from '@capacitor/browser';
import server from '../../api/server';

interface OwnProps extends RouteComponentProps { };

const Configuration: React.FC<OwnProps> = ({ history }) => {

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showToastSuccess, setShowToastSuccess] = useState(false)
  const [showToastError, setShowToastError] = useState(false)
  const [showLoading, setShowLoading] = useState(false);
  const { state, dispatch } = useContext(AppContext);
  const [explanation, setExplanation] = useState<string>('');

  const profileModules = [{
    name: 'Cambio de contraseña',
    onclick: () => { history.push('/changePassword'); }
  }, {
    name: 'Aviso de Privacidad',
    onclick: () => openBrowser()
  }, {
    name: 'Desvincular dispositivo',
    onclick: () => openModal()
  }];

  const openBrowser = async () => {
    await Browser.open({ url: 'https://move.mx/aviso-de-privacidad/' })
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const cancelUnlinkDevice = () => {
    setExplanation('');
    setIsModalOpen(false);
  }

  const unlinkDevice = async () => {
    setShowLoading(true);
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${state.token}`
    };
    const data = {
      Reason: explanation
    };

    try {
      const response = await server.put('/auth/unlink-request',
        JSON.stringify(data),
        { headers: headers })
      dispatch({
        type: 'set-requested-unlink-device',
        requestedUnklinkDevice: true
      })
      setIsModalOpen(false);
      setExplanation('')
      setShowToastSuccess(true);
    } catch (error) {
      console.warn(error);

      setIsModalOpen(false);
      setExplanation('')
      setShowToastError(true);
    }
    setShowLoading(false);
  }

  return (
    <IonPage>
      <Header
        title='Configuraciones'
        titleColor='primary'
        isBackButton={false}
        isMenu={true}
        menuColor='primary'
      />
      <MyContent>

        <IonList >
          {profileModules.map((element, i) => {
            return (
              <IonItem
                detail
                button
                lines="none"
                key={i}
                onClick={element.onclick}
              >
                {element.name}
              </IonItem>
            );
          })}
        </IonList>
      </MyContent>

      <MyModal
        isOpen={isModalOpen}
        onDidDismiss={() => cancelUnlinkDevice()}
        title='Desvinculación'
        backgroundColor='dark'
        initialbreakpoint={1}
        breakpoints={[0, 0.8, 1]}
        closeModalButtonText='Cancelar'
      >
        <div
          style={{ textAlign: 'center' }}>
          <MyLogo />

          <IonText color='light' >
            <p>
              Tu solicitud será revisada por el administrador de tu sucursal, una vez aprobada tu
              cuenta será removida y podrás registrarte con un nuevo dispositivo.
            </p>
          </IonText>

          <IonTextarea
            style={{ textAlign: 'center', marginTop: '2vh' }}
            color='light'
            clearOnEdit={true}
            placeholder='Platícanos un poco la razón'
            value={explanation}
            onIonChange={e => setExplanation(e.detail.value!)} >
          </IonTextarea>

          <MyButton
            title='Si, desvincular'
            expand='block'
            color='danger'
            isDisabled={explanation === ''}
            onClick={() => unlinkDevice()}
          />
        </div>
      </MyModal>

      <IonLoading
        cssClass='my-custom-class'
        isOpen={showLoading}
        onDidDismiss={() => { }}
        message={'Procesando...'}
        duration={3}
      />

      <IonToast
        isOpen={showToastSuccess}
        onDidDismiss={() => setShowToastSuccess(false)}
        icon={informationCircle}
        animated
        color='primary'
        position="bottom"
        duration={5000}
        message={"La solicitud de desvinculación se ha mandado correctamente"}
        buttons={[
          {
            side: "end",
            text: "Ok",
            role: "cancel"
          }
        ]}

      ></IonToast>

      <IonToast
        isOpen={showToastError}
        onDidDismiss={() => setShowToastError(false)}
        icon={informationCircle}
        animated
        color='danger'
        position="bottom"
        duration={5000}
        message={"Hubo un error al procesar la solicitud, inténtelo de nuevo más tarde"}
        buttons={[
          {
            side: "end",
            text: "Ok",
            role: "cancel"
          }
        ]}

      ></IonToast>

    </IonPage>
  );
};

export default Configuration;