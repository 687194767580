import {
  IonContent, IonPage, IonButton,
  IonText, IonSelect, IonSelectOption,
  IonGrid, IonRow, IonCol,
  IonLoading, IonAlert, isPlatform,
  useIonViewWillEnter
} from '@ionic/react';
import React, { useEffect, useContext, useState } from 'react';
import './SignUp.css';
import { personOutline } from 'ionicons/icons';
import { Header, MInput, MyContent } from '../../components/generic/Index';
import { RouteComponentProps } from 'react-router-dom';
import { Keyboard } from '@capacitor/keyboard';
import { Club } from '../../Models/Club';
import server from '../../api/server';
import { DoSet } from '../../DBHandler/User';
import { AppContext } from '../../data/state';
import { UniqueDeviceID } from '@ionic-native/unique-device-id';
import { Capacitor } from '@capacitor/core';
import { getUUIDAndroid } from '../../data/getUUIDAndroid';
import { writeSecretFile } from '../../lib/filesystem';

interface OwnProps extends RouteComponentProps { };

const SignUp: React.FC<OwnProps> = ({ history }) => {
  const { state, dispatch } = useContext(AppContext);
  const [user, setUser] = useState('');
  const [sucursal, setSucursal] = useState('');
  const [sucursalID, setSucursalID] = useState('');
  const [showLoading, setShowLoading] = useState(false);
  const [showFoundAlert, setShowFoundAlert] = useState(false);
  const [wasFound, setWasFound] = useState(false);
  const [reminderAlert, setReminderAlert] = useState(false);
  const [showSignupAlert, setShowSignupAlert] = useState(false);
  const [showSearchingClubs, setShowSearchingClubs] = useState(true);
  const [showErrorAtSearch, setShowErrorAtSearch] = useState(false);
  const [notWasFoundMessage, setNotWasFoundMessage] = useState('No pudimos encontrar tu usuario, por favor vuelve a intentarlo o acude a una sucursal');

  const wasFoundHeader = '¡Exito!';
  const wasFoundMessage = 'Te enviaremos un correo electrónico al correo registrado';
  const notWasFoundHeader = '¡Oops!';

  // Improve - This should be on state, keep it on state will make changes easier
  let sucursales: Club[] = [];

  const searchForNewClubs = async () => {
    setShowSearchingClubs(true);
    await server.get('/club').then(async (res) => {
      const response = res.data;
      if (response.message != null || response.message != undefined) {
        // TODO review what is the error and dump into internal db for logs
        setShowErrorAtSearch(true);
        return false;
      }
      let clubs = document.getElementById('clubsSelect');
      const fillClubsPromise = new Promise(async (resolve, reject) => {
        for await (let club of response.data) {
          let clubNode = document.createElement('ion-select-option');
          clubNode.setAttribute('role', 'option');
          clubNode.setAttribute('value', club.id + '-' + club.name);
          clubNode.innerText = club.name;
          clubs?.appendChild(clubNode);
        }

        resolve();
      });
      await Promise.all([fillClubsPromise]);
    }).catch(() => {
      setShowErrorAtSearch(true);
    });
    setShowSearchingClubs(false);
    setReminderAlert(true);
  };

  useIonViewWillEnter(() => {
    searchForNewClubs();
  });

  useEffect(() => {
    let menu = document.getElementById('moveMenu');
    menu?.setAttribute('disabled', 'disabled');
  }, []);

  const _onSelect = (e: any) => {
    const suc = e.split('-');
    setSucursalID(suc[0]);
    setSucursal(e);
  };

  const sendInfoUserAndroid = async () => {
    try {
      const requestData = {
        UserID: Number.parseInt(user),
        ClubID: Number.parseInt(sucursalID),
      }

      const headers = {
        'Content-Type': 'application/json',
      }
      try {
        const { data: { data } }: any = await server.post('/auth/device-token', JSON.stringify(requestData), { headers });
        const DeviceToken = data.deviceToken;
        await writeSecretFile(DeviceToken);
      } catch (e: any) {
        setShowLoading(false);
        const response = e.response;
        if (response) {
          switch (response.status) {
            case 400:
              setNotWasFoundMessage(' Ya se ha registrado una cuenta en este dispositivo, para cualquier aclaración favor de acudir con administración.');
              break;

            case 403:
              setNotWasFoundMessage('El ' + response.data.data.field + ' ya ha sido registrado' + (response.data.data.club == null ? '.' : ' en la sucursal ' + response.data.data.club + '.') + ' Favor de verificarlo con administración.')
              break;

            case 404:
              setNotWasFoundMessage('Usuario no encontrado.')
              break;

            case 406:
              setNotWasFoundMessage('El correo del usuario es inválido, Favor de revisar con la administración.')
              break;

            case 409:
              setNotWasFoundMessage('Ocurrió un error al enviar el email.')
              break;

            case 500:
              setNotWasFoundMessage('Error en el servidor. inténtalo más tarde.');
              break;

            default:
              setNotWasFoundMessage('Hubo un error inesperado, inténtalo más tarde.');
              break;
          }
        } else {
          setShowErrorAtSearch(true);
        }
      }
    } catch (e: any) {
      setNotWasFoundMessage('Error en el servidor. inténtalo más tarde.');
    }
  }

  const _onSendData = async () => {
    setShowLoading(true);

    // Deprecated
    let uuid: string = "";
    if (Capacitor.getPlatform() == 'ios') {
      uuid = await UniqueDeviceID.get()
    } else {
      uuid = await getUUIDAndroid()
    }

    const userData = {
      userId: parseInt(user),
      clubId: parseInt(sucursalID),
    }
    const headers = {
      'Content-Type': 'application/json',
    }
    await server.post('/auth/signup', JSON.stringify(userData)
      , { headers: headers }
    ).then(async (res) => {
      const response = res.data;
      if (response.statusCode != 200) {
        console.log('error');
        return false;
      }
      setWasFound(true);
      DoSet('HasRequieredRegister', true);
      DoSet('SavedUserData', userData);
      dispatch({
        type: 'set-user-saved',
        userSaved: userData
      });
      await sendInfoUserAndroid();

    }).catch((error) => {
      if (error.response) {
        const response = error.response;
        if (response) {
          switch (response.status) {

            case 400:
              setNotWasFoundMessage(' Ya se ha registrado una cuenta en este dispositivo, para cualquier aclaración favor de acudir con administración.');
              break;

            case 403:
              setNotWasFoundMessage('El ' + response.data.data.field + ' ya ha sido registrado' + (response.data.data.club == null ? '.' : ' en la sucursal ' + response.data.data.club + '.') + ' Favor de verificarlo con administración.')
              break;

            case 404:
              setNotWasFoundMessage('Usuario no encontrado.')
              break;

            case 406:
              setNotWasFoundMessage('El correo del usuario es inválido, Favor de revisar con la administración.')
              break;

            case 409:
              setNotWasFoundMessage('Ocurrio un error al enviar el email.')
              break;

            case 500:
              setNotWasFoundMessage('Error en el servidor. intentalo mas tarde.');
              break;

            default:
              setNotWasFoundMessage('Hubo un error inesperado, intentalo mas tarde.');
              break;
          }
        } else {
          setShowErrorAtSearch(true);
        }
      }
      setWasFound(false);
      return false;
    });
    setShowFoundAlert(true);
    setShowLoading(false);
  };

  const _onDismissAlert = async () => {
    setShowLoading(false);
    setUser('');
    setSucursal('');
    const p1 = new Promise(async (resolve, reject) => {
      await setShowFoundAlert(false);
      await setTimeout(() => {
        resolve();
      }, 100);
    });
    await Promise.all([p1]).then(() => {
      if (wasFound)
        history.replace('/signin');
    });
  };

  const _onDismissNoInternet = async () => {
    const p1 = new Promise(async (resolve, reject) => {
      await setShowErrorAtSearch(false);
      await setTimeout(() => {
        resolve();
      }, 100);
    });
    await Promise.all([p1]).then(() => {
      history.replace('/home');
    });
  };

  const handleKeyPress = (e: any) => {
    if (isPlatform('hybrid')) {
      if (e === 'Enter') {
        Keyboard.hide();
      }
    }
  }

  return (
    <IonPage >
      <Header title='Regístrate'
        titleColor='primary'
        headerClassname='ion-no-border'
        defaultHref='/home'
      />

      <MyContent >
        <div className="centerContainer-withHeader">
          <br />
          <MInput
            inputID='user'
            inputName='user'
            inputType='number'
            iconSize='large'
            icon={personOutline}
            placeholder='Número de socio'
            value={user}
            handleChangeValue={setUser}
            enterkeyhint={'next'}
            handleKeyPress={handleKeyPress}
          />

          <IonGrid>
            <IonRow>
              <IonCol size='3'></IonCol>
              <IonCol size='6'>
                <IonSelect id='clubsSelect' value={sucursal} mode='ios' placeholder='Sucursal'
                  onIonChange={e => _onSelect(e.detail.value)}
                  interface='popover'>
                  {sucursales.map((suc) => {
                    return <IonSelectOption key={suc.name} value={suc.id + '-' + suc.name}>
                      {suc.name}
                    </IonSelectOption>
                  })}
                </IonSelect>
              </IonCol>
              <IonCol size='3'></IonCol>
            </IonRow>
            <br />
            <IonRow>
              <IonCol size='2'></IonCol>
              <IonCol size='8'>
                <IonText>
                  <p className='avisoRegistro'>
                    De momento solo es posible registrarse si ya cuentas con una membresía activa
                    y un correo electrónico registrado.
                  </p>
                </IonText>
              </IonCol>
              <IonCol size='2'></IonCol>
            </IonRow>

          </IonGrid>

          <br />
          <IonButton shape='round' expand='block' color='primary'
            disabled={sucursal === '' || user === ''}
            onClick={() => setShowSignupAlert(true)}>
            Buscar
          </IonButton>

          <IonLoading
            cssClass='my-custom-class'
            isOpen={showLoading}
            onDidDismiss={() => { }}
            message={'Validando...'}
            duration={0}
          />

          <IonLoading
            cssClass='my-custom-class'
            isOpen={showSearchingClubs}
            onDidDismiss={() => { }}
            message={'Actualizando las sucursales...'}
            duration={0}
          />

          <IonAlert
            isOpen={reminderAlert}
            onDidDismiss={() => setReminderAlert(false)}
            backdropDismiss={false}
            cssClass='alert-text-style'
            animated
            keyboardClose
            header='Aviso importante'
            message={'Antes de realizar tu registro, corrobora en la recepción del club que tus datos personales (Nombre, email y teléfono) estén actualizados, ya que son de vital importancia para el uso de la aplicación. ¡Gracias!'}
            buttons={[{
              text: 'Entiendo',
              role: 'Ok',
              handler: () => setReminderAlert(false)
            }]}
          >
          </IonAlert>

          <IonAlert
            isOpen={showSignupAlert}
            onDidDismiss={() => setShowSignupAlert(false)}
            cssClass='alert-text-style'
            animated
            keyboardClose
            backdropDismiss={false}
            header='Confirma tu usuario'
            message={'¿Estas seguro que deseas registrarte con el usuario <strong> ' + user + '</strong>?'}
            buttons={[
              {
                text: 'Cancelar',
                role: 'cancel',
                cssClass: 'secondary',
                id: 'cancel-button',
                handler: blah => {
                  console.log('Confirm Cancel: blah');
                }
              },
              {
                text: 'Continuar',
                id: 'confirm-button',
                handler: () => _onSendData()
              }
            ]}
          >
          </IonAlert>

          <IonAlert
            id='FoundAlert'
            isOpen={showFoundAlert}
            onDidDismiss={() => { }}
            cssClass='alert-text-style'
            header={wasFound ? wasFoundHeader : notWasFoundHeader}
            message={wasFound ? wasFoundMessage : notWasFoundMessage}
            buttons={[{
              text: 'Aceptar',
              role: 'Ok',
              handler: () => _onDismissAlert()
            }]}
            backdropDismiss={false}
          />

          <IonAlert
            isOpen={showErrorAtSearch}
            onDidDismiss={() => { }}
            cssClass='alert-text-style'
            header={'Error!'}
            message={'Parece que no pudimos establecer conexión con el servidor, intentalo de nuevo más tarde'}
            buttons={[{
              text: 'Aceptar',
              role: 'Ok',
              handler: () => _onDismissNoInternet()
            }]}
            backdropDismiss={false}
          />
        </div>

      </MyContent>
    </IonPage>
  );
};

export default SignUp;
