import React from 'react';

import "./ModalAccess.css";

interface ModalAccessProps {
    OkHandle: () => void;
    CancelHandle: () => void;
}

const ModalAccess: React.FC<ModalAccessProps> = ({ OkHandle, CancelHandle }) => {
    return (
        <div className="modal-access">
            <div className="card-modal-access">
                <div className="wrapper__content">
                    <span className='content-modal-access'>Es necesario que permitas el acceso a la cámara</span>
                </div>
                <div className="wrapper_actions">
                    <button className='btn-modal-access' onClick={CancelHandle}>Cancel</button>
                    <button className='btn-modal-access' onClick={OkHandle}>Otorgar</button>
                </div>
            </div>
        </div>
    );
};
export default ModalAccess;
