import { IonCard, IonCardContent, IonContent, IonGrid, IonIcon, IonItem, IonLabel, IonLoading, IonPage, IonRow, IonSegment, IonSegmentButton, IonText, IonToast, IonToolbar, IonModal, IonHeader, IonTitle, IonButton, IonFooter, IonCardHeader, IonCardSubtitle } from "@ionic/react";
import React, { useState, useContext, useEffect, useRef } from "react";
import { RouteComponentProps } from "react-router-dom";
import { useParams } from "react-router-dom";
import { OverlayEventDetail } from "@ionic/react/dist/types/components/react-component-lib/interfaces";
import server from "../../api/server";
import { Header, MyContent, MyTitle, NoDataAvailable } from '../../components/generic/Index';
import { bicycle } from "ionicons/icons";
import { AppContext } from "../../data/state";
import dayjs from "dayjs";
import ReactPlayer from "react-player";

import "./Lessons.css";

interface OwnProps extends RouteComponentProps { }

const Lessons: React.FC<OwnProps> = ({ history }: any) => {

    const { state } = useContext(AppContext);
    const headers = { 'Content-Type': 'application/json', 'Authorization': `Bearer ${state.token}`, };
    const [clubName] = useState(history?.location?.state?.clubName ?? "");
    let { id } = useParams();

    dayjs.locale('es');

    const today = dayjs().startOf('day');
    const DATE_FORMAT = "YYYY-MM-DD";
    const DAY_FORMAT = "dd D";
    const dates = [];

    for (let i = 0; i < 7; i++) {
        dates.push(today.add(i, 'day'));
    }

    const [selectedDate, setSelectedDate] = useState<string>(today.format(DATE_FORMAT));
    const [selectedCategory, setSelectedCategory] = useState<string>("CYCLING");

    const [classList, setClassList] = useState([]);

    const [showToast, setShowToast] = useState(false);
    const [showLoading, setShowLoading] = useState(false);
    const [showVideoLoading, setShowVideoLoading] = useState(false);

    const modalClasses = useRef<HTMLIonModalElement>(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const [classId, setClassId] = useState(Number);
    const [className, setClassName] = useState("");
    const [classDescription, setClassDescription] = useState("");
    const [hasTutorial, setHasTutorial] = useState<string | null>(null);
    const [classVideo, setClassVideo] = useState("");
    const [videoLoaded, setVideoLoaded] = useState(false);

    useEffect(() => {
        if (selectedDate || selectedCategory) {
            getClubClasses(selectedDate, selectedCategory);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedDate, selectedCategory]);

    const getClubClasses = async (date?: any, category?: any) => {

        setShowLoading(true);

        const userData = {
            date: date,
            category: category
        }

        await server
            .post(`/club/${id}/classes/`, JSON.stringify(userData), { headers: headers })
            .then(async (res) => {
                const response = res.data;
                if (response.statusCode !== 200) {
                    console.warn('Error');
                    return false;
                }
                setClassList(response.data);
            })
            .finally(() => {
                setShowLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setClassList([]);
                setShowLoading(false);
                setShowToast(true);
            });
    };

    const getClassInfo = async (classInfo: string) => {

        await server
            .get(`/customer/club/class-details/${classInfo}`, { headers: headers })
            .then(async (res: any) => {
                const response = res.data;
                if (response.statusCode !== 200) {
                    console.warn("Error");
                    return false;
                }

                const { id, className, classDescription, tutorial } = response.data;

                setClassId(id);
                setClassName(classInfo);
                setClassDescription(classDescription);

                setHasTutorial(tutorial);
                setClassVideo(`https://moveapi.wsd.com.mx/api/customer/club/class-tutorial/${id}`);
            })
            .finally(() => {
                setIsModalOpen(true);
            })
            .catch((error) => {
                setClassId(0);
                setClassName(classInfo);
                setClassDescription("");
                setHasTutorial(null);
                setClassVideo("");
                setShowVideoLoading(false);
                console.warn(error);
            })
    };

    const changeDate = (value: any) => {
        setSelectedDate(value ?? today.format(DATE_FORMAT));
    };

    const changeCategory = (value: any) => {
        setSelectedCategory(value);
    };

    const handleVideoLoad = () => {
        setVideoLoaded(true);
        setShowVideoLoading(false);
    };

    function onWillDismiss(ev: CustomEvent<OverlayEventDetail>) {
        setIsModalOpen(false);
    };

    const setColor = (color: any) => {
        switch (color) {
            case "#0000FF": return "danger";
            case "#8000FF": return "success";
            case "#00FFFF": return "secondary";
            default: return "dark";
        }
    }
    

    return (

        <IonPage>
            <Header
                title={clubName}
                titleColor="primary"
                isBackButton={true}
                defaultHref='/clublist'
                menuColor="primary"
            />

            <MyContent hasRefresher refreshMethod={getClubClasses}>

                <MyTitle title="Clases" />
                
                <IonToolbar color="none">
                    <IonSegment onIonChange={e => changeCategory(e.detail.value)} value={selectedCategory} defaultValue={""} color="primary">
                        <IonSegmentButton value="CLASES DE SALÓN">Clase de salón</IonSegmentButton>
                        <IonSegmentButton value="CYCLING">Cycling</IonSegmentButton>
                        <IonSegmentButton value="MOVE 30">MOVE 30</IonSegmentButton>
                    </IonSegment>
                </IonToolbar>


                <IonToolbar color="none">
                    <IonSegment onIonChange={e => changeDate(e.detail.value)} value={selectedDate} scrollable color="primary">
                        {dates.map(date => (
                            <IonSegmentButton key={date.format(DATE_FORMAT)} value={date.format(DATE_FORMAT)}>
                                {date.format(DAY_FORMAT)}
                            </IonSegmentButton>
                        ))}
                    </IonSegment>
                </IonToolbar>

                <hr className="separator-style" />

                {(classList.length !== 0) &&
                    classList.map((element: any, i: number) => {
                        return (
                            <IonCard key={i} button onClick={() => getClassInfo(element.clubClass.name)} className="card-styles" >

                                <IonCardHeader color={setColor(element.clubClass.colorString)}>
                                    <IonCardSubtitle>{element.clubClass.name}</IonCardSubtitle>
                                </IonCardHeader>

                                <IonCardContent>
                                    <IonLabel >
                                        {/* <h2 className="class-activity-name" >{element.clubClass.name.toLowerCase()}</h2> */}
                                        <h2 className="class-details" >{element.startDate.slice(11, 16)} - {element.endDate.slice(11, 16)}</h2>
                                        <h2 className="class-details" >{element.clubClass.trainer === "" ? "Entrenador por definir" : element.clubClass.trainer}</h2>
                                    </IonLabel>
                                </IonCardContent>
                            </IonCard>
                        )
                    })
                }

                {(classList.length === 0) &&
                    <IonCard className="card-styles" button>
                        <IonCardContent>

                            <IonItem color="none" lines="none">
                                <IonIcon color="primary" className="icon-style" icon={bicycle} />
                            </IonItem>

                            <IonItem color="none" lines="none">
                                <IonText className="card-text-style" >
                                    Sin clases disponibles en este momento. Intenta mas tarde.
                                </IonText>
                            </IonItem>
                        </IonCardContent>
                    </IonCard>
                }


            </MyContent>

            <IonModal isOpen={isModalOpen} ref={modalClasses} onWillDismiss={(ev) => onWillDismiss(ev)} >
                <IonHeader>
                    <IonToolbar>
                        <IonTitle className="modal-activity-name" color="primary">
                            {className ? className.toLowerCase() : "Actividad sin detalles"}
                        </IonTitle>
                    </IonToolbar>
                </IonHeader>

                {(hasTutorial && classDescription) && (
                    <IonContent className="ion-padding">
                        <IonItem color="none" lines="none">
                            {classVideo && <ReactPlayer url={classVideo} onLoadedData={handleVideoLoad} playing loop muted height={500} />}
                        </IonItem>
                        <IonItem color="none" lines="none">
                            <IonText className="modal-activity-description">
                                <div dangerouslySetInnerHTML={{ __html: classDescription }} />
                            </IonText>
                        </IonItem>
                    </IonContent>
                )}

                {(hasTutorial === null && classDescription) && (
                    <IonContent className="ion-padding">
                        <IonItem color="none" lines="none">
                            <IonText className="modal-activity-description">
                                <div dangerouslySetInnerHTML={{ __html: classDescription }} />
                            </IonText>
                        </IonItem>
                    </IonContent>
                )}

                {(!hasTutorial && !classDescription) && (
                    <NoDataAvailable icon={bicycle} text="Informaciòn de clase aun no disponible. Intenta mas tarde." />
                )}

                <IonFooter>
                    <IonToolbar>
                        <IonButton onClick={() => modalClasses.current?.dismiss()} expand="block" >Volver</IonButton>
                    </IonToolbar>
                </IonFooter>
            </IonModal>

            <IonLoading
                isOpen={showLoading}
                onDidDismiss={() => setShowLoading(false)}
                cssClass='custom-loading'
                animated
                keyboardClose
                spinner={'crescent'}
                message={'Cargando...'}
                duration={0}
            />

            <IonLoading
                isOpen={showVideoLoading}
                onDidDismiss={() => setShowVideoLoading(false)}
                cssClass='custom-loading'
                animated
                keyboardClose
                spinner={'crescent'}
                message={'Cargando...'}
            />

            <IonToast cssClass="ion-toast-text-style"
                isOpen={showToast}
                onDidDismiss={() => setShowToast(false)}
                message="Hubo un problema al cargar los datos. Inténtalo de nuevo mas tarde"
                color="danger"
                duration={3000}
            />

        </IonPage>
    )
};

export default Lessons;
