import React, { useState, useEffect } from 'react';
import { IonList, IonPage } from '@ionic/react';
import { RouteComponentProps } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { Header, MyContent } from '../../components/generic/Index';
import server from '../../api/server';

interface OwnProps extends RouteComponentProps { };

const ClubMap: React.FC<OwnProps> = ({ history }) => {
  let { id } = useParams();
  const [clubName, setClubName] = useState('');
  const [srcMap, setSrcMap] = useState('');

  useEffect(() => {
    getClubInfoByID();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getClubInfoByID = async () => {
    await server
      .get(`/club/${id}`)
      .then(async (res) => {
        const response = res.data;
        if (response.statusCode !== 200) {
          console.log('error');
          return false;
        }
        setClubName(response.data.name);
        setSrcMap(response.data.mapsSrc);
      })
      .finally(() => {
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <IonPage>

      <Header
        title={clubName}
        titleColor='primary'
        isBackButton={true}
        defaultHref='/clublist'
      />

      <MyContent noPadding >
        <IonList inset>
          <iframe
            style={{
              paddingTop: '2.9em',
              border: '0',
              width: '100%',
              height: '100vh',
              display: srcMap ? 'block' : 'none',
            }}
            src={srcMap}
            allowFullScreen={true}
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
            title='maps'
          />
        </IonList>

      </MyContent>

    </IonPage>
  );
};

export default ClubMap;