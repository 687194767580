import { IonItem, IonCol } from "@ionic/react";
import server from "../../api/server";

import React, { useState, useEffect } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartJS.register(
  CategoryScale,
  ChartDataLabels,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

interface ConcurrenceProps {
  clubId: any;
}

const ClubConcurrence: React.FC<ConcurrenceProps> = ({ clubId }) => {
  const [hourLabel, setHourLabel] = useState([]);
  const [backgroundColor, setBackgroundColor] = useState(
    "rgba(136,190,85, 0.2)"
  );
  const [borderColor, setBorderColor] = useState("rgba(136, 190, 85, 1)");
  const [currentConcurrence, setCurrentConcurrence] = useState(0);
  const [hourList, setHourList] = useState([]);
  const [hourData, setHourData] = useState([]);
  const [isNotAvailable, setIsNotAvailable] = useState(false);
  var newDate = new Date();

  useEffect(() => {
    getConcurrenceByDay();
  }, []);

  const getConcurrenceByDay = async () => {
    await server
      .get(`/club/${clubId}/concurrence/${newDate.getDay()}`)
      .then(async (res) => {
        const response = res.data;
        if (response.statusCode != 200) {
          console.log("error");
          setIsNotAvailable(true);
        } else {
          setCurrentConcurrence(response.data.current);

          if (response.data.concurrences.length > 0) {
            response.data.concurrences.map((element: any) => {
              setHourData((hourData) => hourData.concat(element.average));
              const myHour = new Date(`August 19, 1975 ${element.hour}`);
              const hour: any = myHour.getHours();
              setHourLabel((hourLabel) => hourLabel.concat(hour));
              const color: any =
              newDate.getHours() === hour ? borderColor : backgroundColor;
              setHourList((hourList) => hourList.concat(color));
            });
          } else {
            setIsNotAvailable(true);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const data = {
    labels: hourLabel,
    datasets: [
      {
        label: "Usuarios aprox.",
        data: hourData,
        backgroundColor: hourList,
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        color: 'black' as const,
      },
      font: {
          weight: 'bold',
          size: 16,
        }
    },
    scales: {
      x: {
        display: true,
        title: {
          display: true,
          text: 'Horas',
        }
      }
    }
  };

  return (
    <IonItem lines="none">
      <IonCol size="12">
        {isNotAvailable ? (
          <span style={{fontSize: "11px"}}>Sucursal Cerrada</span>
        ) : (
          <Bar type="bar" data={data} options={options} />
        )}
      </IonCol>
    </IonItem>
  );
};

export default ClubConcurrence;
