import {
  IonGrid, IonRow,
  IonCol, IonLabel, IonPage,
  IonItem, IonList, IonIcon,
  useIonViewWillEnter,
} from '@ionic/react';
import React, { useState, useContext } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Header, MyContent, MyLoading, MyTitle } from "../../components/generic/Index";
import { AppContext } from '../../data/state';
import './MyPlan.css'
import { iconSet } from '../../Helpers/DevHelpers'
import { chevronDownOutline, chevronUpOutline } from 'ionicons/icons';
import server from '../../api/server';
import { DoGet, DoSet } from '../../DBHandler/User';
import { Club } from '../../Models/Club';
import { User } from '../../Models/User';

interface OwnProps extends RouteComponentProps { };

const MyPlan: React.FC<OwnProps> = ({ history }) => {
  const { state, dispatch } = useContext(AppContext);
  const [isLoading, setIsLoading] = useState(false);
  const toggleLoading = () => { setIsLoading(prevState => !prevState); };
  const [accessList, setAccessList] = useState(true);
  const [membersList, setMembersList] = useState(true);
  const [family, setFamily] = useState<User[]>([]);
  const [clubsAvailable, setClubsAvailable] = useState<Club[]>([]);
  const [hasLocker, setHasLocker] = useState(false);
  const [hasMulticlub, setHasMulticlub] = useState(false);
  const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }

  useIonViewWillEnter(() => {
    (async () => {
      toggleLoading();
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${state.token}`
      }
      //#region Get User Info
      await server.get('/user', { headers }
      ).then(async (res) => {
        let response = res.data;
        if (response.statusCode != 200) {
          return;
        }
        const userRetrived: User = response.data;
        userRetrived.deviceUUID = state.user.deviceUUID;
        userRetrived.memberIDTrimmed = state.user.memberIDTrimmed;
        userRetrived.club = state.user.club;
        userRetrived.membership = response.data.membershipInfo;
        await dispatch({
          type: 'set-user',
          user: userRetrived
        });
      }).catch(async (error) => {
        console.log('error at get User by ID');
      });
      //#endregion Get User Info

      //#region user Fam
      await server.get('/user/family', { headers }
      ).then(async (res) => {
        let response = res.data;
        if (response.statusCode != 200) {
          await getUserFamFromDB();
          return;
        }
        const userFamily = response.data;
        await DoSet('userFamily', userFamily);
        await getUserFamFromDB();
      }).catch(async (error) => {
        await getUserFamFromDB();
      });
      //#endregion user Fam

      //#region user access
      await server.get('/user/clubs', { headers }
      ).then(async (res) => {
        let response = res.data;
        if (response.statusCode != 200) {
          await getUserAccessFromDB();
          return;
        }
        const userAccess = response.data;
        await DoSet('userAccess', userAccess);
        await getUserAccessFromDB();
      }).catch(async (error) => {
        await getUserAccessFromDB();
      });
      //#endregion user access
      await getServices();
      toggleLoading();
    })();
  });

  const getUserFamFromDB = async () => {
    let userFamily = await DoGet('userFamily');
    let userIsInList = false;
    let headline = userFamily.headline;
    if (!headline) {
      userIsInList = true;
      headline = state.user;
    }
    let dependents = userFamily.dependents;
    if (!userIsInList) {
      userIsInList = true;
      dependents.unshift(state.user);
    }
    dependents.unshift(headline);
    userFamily = dependents;
    await dispatch({
      type: 'set-user-family',
      userFamily
    });
    setFamily(userFamily);
  };

  const getUserAccessFromDB = async () => {
    let userAccess = await DoGet('userAccess');
    await dispatch({
      type: 'set-user-access',
      userAccess
    });
    setClubsAvailable(userAccess);
  };

  const getServices = async () => {
    const now = new Date(Date.now());
    let lockerDate = state.user.membership?.lockerExpires;
    let locker = state.user.membership?.locker;
    let lockerDesc = state.user.membership?.lockerDesc;
    let lockerNumber = state.user.membership?.lockerNumber;
    if (lockerDesc != 'NINGUNO' || locker != '0000' || lockerNumber != null) {
      lockerDate = new Date(lockerDate);
      if (lockerDate > now) {
        setHasLocker(true);
      }
    }
    let multiclubDate = state.user.membership?.multiclubExpires;
    let multiclub = state.user.membership?.multiclub;
    let multiclubDesc = state.user.membership?.multiclubDesc;
    if (multiclubDesc != 'NINGUNO' || multiclub != '0000') {
      multiclubDate = new Date(multiclubDate);
      if (multiclubDate > now) {
        setHasMulticlub(true);
      }
    }
  }

  const profilePlan = [{
    name: 'Sucursal:',
    detail: (state.user.club?.name ?? ''),
    icon: '',
    valuehidden: false,
  }, {
    name: 'Plan: ',
    detail: state.user.membership?.membershipType,
    icon: ((state.user.membership?.membershipType).includes('Premium') || (state.user.membership?.membershipType).includes('PREMIUM')) ?
      iconSet.icon_premium : iconSet.icon_basico,
    expires: (state.user.membership?.membershipExpires ? new Date(state.user.membership?.membershipExpires).toLocaleDateString('es-MX', options) : '-'),
    valuehidden: !state.user.membership.isYearly,
  }, {
    name: 'Modalidad:',
    detail: state.user.membership?.membershipModality ?? 'INDIVIDUAL',
    // detail: ( (state.user.membership?.membershipModality).includes('INTEGRANTES') || (state.user.membership?.membershipModality).includes('INTEGRANTE') || (state.user.membership?.membershipModality).includes('FAMILIAR') ) ?
    //         'Familiar' : 'Individual' ,
    icon: ((state.user.membership?.membershipModality ?? '').includes('INTEGRANTES') || (state.user.membership?.membershipModality ?? '').includes('INTEGRANTE') || (state.user.membership?.membershipModality ?? '').includes('FAMILIAR')) ?
      iconSet.icon_familiar : iconSet.icon_personal,
    valuehidden: false,
  }, {
    name: 'Locker: ',
    detail: state.user.membership.lockerNumber ? state.user.membership?.lockerNumber : '-',
    icon: iconSet.icon_locker,
    expires: (state.user.membership.lockerExpires ? new Date(state.user.membership?.lockerExpires).toLocaleDateString('es-MX', options) : '-'),
    valuehidden: !state.user.membership.isLockerYearly,
  }, {
    name: 'Multiclub',
    detail: (state.user.membership.multiclubDesc ? state.user.membership?.multiclubDesc : '-'),
    icon: iconSet.icon_total,
    expires: (state.user.membership.multiclubExpires ? new Date(state.user.membership?.multiclubExpires).toLocaleDateString('es-MX', options) : '-'),
    valuehidden: !state.user.membership.isMulticlubYearly,
  }];

  const planDetails = [
    {
      name: 'Integrantes',
      flag: membersList,
      onclick: () => { setMembersList(!membersList); }
    },
    {
      name: 'Acceso disponible',
      flag: accessList,
      onclick: () => { setAccessList(!accessList); }
    }];

  return (
    <IonPage>
      <Header
        title='Mi plan'
        titleColor='primary'
        headerClassname='ion-no-border'
        defaultHref='/profile'
      />

      <MyContent>

        <IonRow>
          <IonCol size='8'>
            <IonLabel
              style={{ fontSize: '1.8rem' }}
              color={'primary'}
              children={(state.user === undefined)
                ? ''
                : `Socio: ${state.user.memberIDTrimmed}`
              }
            />
          </IonCol>
        </IonRow>

        {profilePlan.map((element, i) => {
          return (

            <IonRow
              key={i}
              className="profile-plan-elements"
            >
              <IonCol size='10' className="profile-plan-detail">
                <div className="col-container">
                  <div className="width-container">
                    <IonLabel color="medium" className="reduced-label ">
                      {element.name}
                    </IonLabel>
                  </div>
                  <div className="width-container">
                    <IonLabel className="my-plan-details ">
                      {element.detail}
                    </IonLabel>
                  </div>
                  {element.expires &&
                    <div className="width-container">
                      <IonLabel className="reduced-label " hidden={element.valuehidden}>
                        Vigencia: {element.expires}
                      </IonLabel>
                    </div>
                  }
                </div>
              </IonCol>
              <IonCol size='2' className="profile-plan-icon">
                <img src={element.icon}/>
              </IonCol>
            </IonRow>
          );
        })}
        <hr />

        {planDetails.map((element, i) => {
          return (
            <IonGrid key={i} className="profile-modules">
              {
                (element.name == 'Integrantes' && family.length > 1) &&
                <>
                  <IonItem
                    lines="none"
                    onClick={element.onclick}
                  >
                    {element.name}
                    <IonIcon
                      icon={element.flag
                        ? chevronDownOutline
                        : chevronUpOutline}
                    >
                    </IonIcon>
                  </IonItem>
                  <IonList hidden={element.flag}>
                    {element.name == 'Integrantes' &&
                      <>
                        {family.map((famUser: User, idx: number) => {
                          return (
                            <IonItem key={famUser.id}>
                              <IonLabel>
                                <p> {idx == 0 ? 'Titular - ' : ''} {`Socio: ${famUser.memberIDTrimmed}`} <br /> </p>
                                {famUser.name}
                              </IonLabel>
                            </IonItem>
                          )
                        })}
                      </>
                    }
                  </IonList>
                </>
              }
              {
                (element.name == 'Acceso disponible') &&
                <>
                  <IonItem
                    lines="none"
                    onClick={element.onclick}
                  >
                    {element.name}
                    <IonIcon
                      icon={element.flag
                        ? chevronDownOutline
                        : chevronUpOutline}
                    >
                    </IonIcon>
                  </IonItem>
                  <IonList hidden={element.flag}>
                    {element.name == 'Acceso disponible' &&
                      <>
                        {clubsAvailable.map((club) => {
                          return (
                            <IonItem key={club.id}>
                              {club.name}
                            </IonItem>
                          )
                        })}
                      </>
                    }
                  </IonList>
                </>
              }

            </IonGrid>
          );

        })}

      </MyContent>

      <MyLoading
        isOpen={isLoading}
        toggleLoading={toggleLoading}
      />

    </IonPage>
  );
};

export default MyPlan;
