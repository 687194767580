import React, { useState, useEffect } from 'react';
import { IonPage, IonRow, IonIcon, IonLabel, IonCard, IonCol, IonCardContent } from '@ionic/react';
import { RouteComponentProps } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { Header, MyContent, MyLoading, MyTitle } from '../../components/generic/Index';

import server from '../../api/server';

interface OwnProps extends RouteComponentProps { };

const ClubServices: React.FC<OwnProps> = ({ history }) => {

  let { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const toggleLoading = () => { setIsLoading(prevState => !prevState); };
  const [clubName, setClubName] = useState('');
  const [servicesList, setServicesList] = useState([]);

  useEffect(() => {
    getClubServices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getClubServices = async () => {

    toggleLoading();

    await server.get(`/club/${id}`)
      .then(async (res) => {
        const response = res.data;
        if (response.statusCode !== 200) {
          console.warn('error');
          return false;
        }
        setClubName(response.data.name);
      })
      .catch((error) => {
        console.log(error);
      });

    await server.get(`/club/${id}/service`)

      .then(async (res) => {
        const response = res.data;
        if (response.statusCode !== 200) {
          console.log('error');
          return false;
        }
        setServicesList(response.data);
      })
      .finally(() => {
        toggleLoading();
      })
      .catch((error) => {
        console.log(error);
      });

  }

  return (
    <>
      <IonPage>
        <Header
          title={clubName}
          titleColor='primary'
          isBackButton={true}
          defaultHref='/clublist'
        />

        <MyContent>

          <MyTitle title='Servicios' />

          <IonCard>
            <IonCardContent>
              <IonRow>
                {(servicesList !== null) &&
                  servicesList.map((element: any) => {
                    return (
                      <IonCol size="4" key={element.id} className="ion-align-self-center">
                        <IonIcon
                          color="primary"
                          icon={element.services.iconString}
                          className="services-icons"
                        />
                        <p className="services-icons-text">{element.services.service}</p>
                      </IonCol>
                    )
                  })
                }
              </IonRow>
            </IonCardContent>
          </IonCard>

        </MyContent>

        <MyLoading
          isOpen={isLoading}
          toggleLoading={toggleLoading}
        />

      </IonPage>
    </>
  )
};

export default ClubServices;