import { IonBadge, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle,
  IonCol, IonItem, IonLabel, IonList, IonListHeader, IonPage, IonRow
} from "@ionic/react";
import { RouteComponentProps } from "react-router";
import { Header, MyContent, MyTitle, MyLoading } from "../../components/generic/Index";
import React, { useState, useRef, useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";
import { AppContext } from "../../data/state";
import moment from "moment";
import './Report.css';
import { curveBundle, axisLeft, axisBottom, select, scaleLinear, line } from 'd3';
import {
  FormatMoulding, composicionCorporalOptions, composicionExtremidadesOptions,
  somatotipoOptions, imcOptions
} from "./ReportData";
import {
  Chart as ChartJS,
  ArcElement,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { Pie } from 'react-chartjs-2';
import { Line } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartJS.register(
  ArcElement,
  CategoryScale,
  ChartDataLabels,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

interface OwnProps extends RouteComponentProps { };

const Report: React.FC<OwnProps> = ({ history }) => {
  const svgRef = useRef(null);
  const location: any = useLocation();

  const [userName, setUserName] = useState("");
  const [consultId, setConsultId] = useState(0);
  const [userEvaluationDate, setUserEvaluationDate] = useState<string>("");
  const [userNumberSession, setUserNumberSession] = useState("");
  const [userAge, setUserAge] = useState("");
  const [userHeight, setUserHeight] = useState("");
  const [userWeigth, setUserWeigth] = useState("");
  const [userGender, setUserGender] = useState("");

  const [bodyKg, setBodyKg] = useState([]);
  const [bodyCompositionPercent, setBodyCompositionPercent] = useState([]);
  const [extremitiesCompositionMuscle, setExtremitiesCompositionMuscle] = useState([]);
  const [extremitiesCompositionFat, setExtremitiesCompositionFat] = useState([]);
  const [metabolicRisk, setMetabolicRisk] = useState([]);
  const [coordinates, setCoordinates] = useState([0, 0]);

  const formatDate = (date: any) => moment(date).format("YYYY-MM-DD");

  const { state } = useContext(AppContext);
  const [isActive, setIsActive] = useState(false);
  const toggleLoading = () => { setIsActive(prevState => !prevState); };

  const token = state.token;

  const [imc, setImc] = useState({
    labels: ["IMC"],
    datasets: [
      {
        label: 'Indice',
        data: [0],
        barPercentage: 1,
        borderWidth: 1,
        borderColor: 'lightGreen',
        backgroundColor: 'lightGreen',
      },
    ],
  });

  const [bodyPercent, setBodyPercent] = useState({
    labels: ['Musculatura', 'Grasa', 'Hueso', 'Residial'],
    datasets: [
      {
        label: 'Composicion corporal',
        data: [0],
        backgroundColor: [
          'rgba(255, 99, 132, 0.9)',
          'rgba(54, 162, 235, 0.9)',
          'rgba(255, 206, 86, 0.9)',
          'rgba(75, 192, 192, 0.9)',
          'rgba(153, 102, 255, 0.9)',
          'rgba(255, 159, 64, 0.9)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)',
        ],
        borderWidth: 1,
      },
    ],
  });

  const [extremitiesComposition, setExtremitiesComposition] = useState({
    labels: ['Pantorrilla', 'Muslo', 'Brazo'],
    datasets: [
      {
        label: '% Musculatura',
        data: [0],
        borderColor: 'lightBlue',
        backgroundColor: 'rlightBlue',
      },
      {
        label: '% Grasa',
        data: [0],
        borderColor: 'lightGreen',
        backgroundColor: 'lightGreen',
      },
    ],
  });

  const [somatype, setSomatype] = useState({
    labels: ['Endomorfo', 'Mesomorfo', 'Ectomorfo'],
    datasets: [
      {
        data: [0],
        borderColor: ['#5BC0EB', '#C3423F', '#9FCB77'],
        backgroundColor: ['#5BC0EB', '#C3423F', '#9FCB77'],
      },
    ],
  });

  useEffect(() => {
    if (location.state) {
      setConsultId(location.state.sessionId);
      requestData(location.state.sessionId);
    }
  }, []);

  const requestData = async (consultId: any) => {
    setIsActive(true);

    try {
      const mouldingResult = await FormatMoulding.mouldingReportData(token, consultId);

      setUserName(mouldingResult.info.name);
      setUserEvaluationDate(formatDate(mouldingResult.info.consultDate));
      setUserNumberSession(mouldingResult.info.consultNum);
      setUserAge(mouldingResult.info.age);
      setUserHeight(mouldingResult.info.height);
      setUserWeigth(mouldingResult.info.weight);
      setUserGender(mouldingResult.info.gender);

      await setImc({
        ...imc,
        datasets: [
          {
            label: 'Indice',
            data: mouldingResult.imc,
            barPercentage: 1,
            borderWidth: 1,
            borderColor: "#9fcb77",
            backgroundColor: "#9fcb77",
          },
        ],
      });
      await setBodyPercent({
        labels: ['Musculatura', 'Grasa', 'Hueso', 'Residial'],
        datasets: [
          {
            label: 'Composicion corporal',
            data: mouldingResult.bodyPercent,
            backgroundColor: [
              'rgba(255, 99, 132, 0.9)',
              'rgba(54, 162, 235, 0.9)',
              'rgba(255, 206, 86, 0.9)',
              'rgba(75, 192, 192, 0.9)',
              'rgba(153, 102, 255, 0.9)',
              'rgba(255, 159, 64, 0.9)',
            ],
            borderColor: [
              'rgba(255, 99, 132, 1)',
              'rgba(54, 162, 235, 1)',
              'rgba(255, 206, 86, 1)',
              'rgba(75, 192, 192, 1)',
              'rgba(153, 102, 255, 1)',
              'rgba(255, 159, 64, 1)',
            ],
            borderWidth: 1,
          },
        ],
      });

      await setExtremitiesComposition({
        labels: ['Pantorrilla', 'Muslo', 'Brazo'],
        datasets: [
          {
            label: '% Musculatura',
            data: mouldingResult.extMuscle,
            borderColor: "#9fcb77",
            backgroundColor: "#9fcb77",
          },
          {
            label: '% Grasa',
            data: mouldingResult.extFat,
            borderColor: "#64b5f6",
            backgroundColor: "#64b5f6",
          },
        ],
      });

      await setSomatype({
        labels: ['Endomorfo', 'Mesomorfo', 'Ectomorfo'],
        datasets: [
          {
            data: mouldingResult.soma,
            borderColor: ['#5BC0EB', '#C3423F', '#9FCB77'],
            backgroundColor: ['#5BC0EB', '#C3423F', '#9FCB77'],
          },
        ],
      });

      setBodyKg(mouldingResult.bodyKg);
      setBodyCompositionPercent(mouldingResult.bodyPercent);
      setExtremitiesCompositionFat(mouldingResult.extFat);
      setExtremitiesCompositionMuscle(mouldingResult.extMuscle);
      setMetabolicRisk(mouldingResult.metRisk);
      setCoordinates(mouldingResult.coor);
      const dataX = [0, -6, 6, 0, -4.5, 4.5, 0];
      const dataY = [12, -6, -6, 0, 4.5, 4.5, -7.5];

      // Configuración del gráfico
      const width = document.getElementById("somatocarta").getBoundingClientRect().width - 20;
      const height = 280;
      const margin = { top: 20, right: 20, bottom: 20, left: 40 };

      // Crear escalas para ejes X e Y
      const xScale = scaleLinear()
        .domain([-8, 8])
        .range([margin.left, width - margin.right]);

      const yScale = scaleLinear()
        .domain([-10, 16])
        .range([height - margin.bottom, margin.top]);

      const x = xScale(mouldingResult.coor[0]);
      const y = yScale(mouldingResult.coor[1]);
      // Seleccionar el elemento SVG existente en el DOM
      const svg = select(svgRef.current)
        .attr('width', width)
        .attr('height', height);

      // Crear ejes X e Y
      svg.append("g")
        .attr("transform", "translate(0," + (height - margin.bottom) + ")")
        .call(axisBottom(xScale));

      svg.append("g")
        .attr("transform", "translate(" + margin.left + ",0)")
        .call(axisLeft(yScale).tickSize(1));

      // Segmentos
      svg.append("line")
        .attr("x1", xScale(0))
        .attr("y1", yScale(-7.5))
        .attr("x2", xScale(0))
        .attr("y2", yScale(12))
        .attr("stroke", "#88BE55")
        .attr("stroke-dasharray", "2");

      svg.append("line")
        .attr("x1", xScale(-6))
        .attr("y1", yScale(-6))
        .attr("x2", xScale(6.5))
        .attr("y2", yScale(6.5))
        .attr("stroke", "#88BE55")
        .attr("stroke-dasharray", "2");

      svg.append("line")
        .attr("x1", xScale(6))
        .attr("y1", yScale(-6))
        .attr("x2", xScale(-6.5))
        .attr("y2", yScale(6.5))
        .attr("stroke", "#88BE55")
        .attr("stroke-dasharray", "2");

      // Texto
      svg.append("text")
        .attr("x", xScale(0))
        .attr("y", yScale(13))
        .text("Mesomorfo")
        .attr("font-size", "10px")
        .attr("text-anchor", "middle")
        .style('fill', "#9bab8a");

      svg.append("text")
        .attr("x", xScale(-6))
        .attr("y", yScale(-8))
        .text("Endomorfo")
        .attr("font-size", "10px")
        .attr("text-anchor", "middle")
        .style('fill', "#9bab8a");

      svg.append("text")
        .attr("x", xScale(6))
        .attr("y", yScale(-8))
        .text("Ectomorfo")
        .attr("font-size", "10px")
        .attr("text-anchor", "middle")
        .style('fill', "#9bab8a");
      // Curvas
      const lineData = [
        { x: xScale(-6), y: yScale(-6) },
        { x: xScale(-4.5), y: yScale(4.5) },
        { x: xScale(0), y: yScale(12) }
      ];
      type DataType = { x: any, y: any };
      const lineGenerator = line<DataType>()
        .x(d => d?.x)
        .y(d => d?.y)
        .curve(curveBundle.beta(1.5));

      svg.append("path")
        .datum(lineData)
        .attr("d", lineGenerator)
        .attr("fill", "none")
        .attr("stroke", "#9fcb77");

      const lineData2 = [
        { x: xScale(-6), y: yScale(-6) },
        { x: xScale(0), y: yScale(-7.5) },
        { x: xScale(6), y: yScale(-6) }
      ];

      svg.append("path")
        .datum(lineData2)
        .attr("d", lineGenerator)
        .attr("fill", "none")
        .attr("stroke", "#9fcb77");

      const lineData3 = [
        { x: xScale(6), y: yScale(-6) },
        { x: xScale(4.5), y: yScale(4.5) },
        { x: xScale(0), y: yScale(12) }
      ];

      svg.append("path")
        .datum(lineData3)
        .attr("d", lineGenerator)
        .attr("fill", "none")
        .attr("stroke", "#9fcb77");

      svg.append('circle')
        .attr('cx', x)
        .attr('cy', y)
        .attr('r', 3) // Radio del punto
        .attr('fill', "#9fcb77"); // Color del punto
      setIsActive(false);
    } catch (error: any) {
      console.log(error);
      setIsActive(false);
    }
  };

  const checkCC = (value: number) => {
    if (userGender === "MUJER") {
      if (value < 85) {
        return "Bajo riesgo";
      }
      return "Alto riesgo";
    } else {
      if (value < 90) {
        return "Bajo riesgo";
      }
      return "Alto riesgo";
    }
  };

  const checkICE = (value: number) => {
    if (userGender === "MUJER") {
      if (value < 53.5) {
        return "Bajo riesgo";
      }
      return "Alto riesgo";
    } else {
      if (value < 52.5) {
        return "Bajo riesgo";
      }
      return "Alto riesgo";
    }
  };

  const checkICC = (value: number) => {
    if (userGender === "MUJER") {
      if (value < 0.75) {
        return "Bajo riesgo";
      }
      return "Alto riesgo";
    } else {
      if (value < 0.90) {
        return "Bajo riesgo";
      }
      return "Alto riesgo";
    }
  };

  return (
    <>
      <IonPage>
        <Header
          title="Reporte"
          titleColor="primary"
          isBackButton={true}
          defaultHref="/nutrition"
        />
        <MyContent >
          <MyTitle title="Reporte" />
          <IonCard style={{marginLeft: '0', marginRight: '0'}}>
            <IonCardHeader>
              <IonCardSubtitle>
                <IonLabel children={'Índice de masa corporal (IMC)'} color={'primary'} />
              </IonCardSubtitle>
            </IonCardHeader>
            <IonCardContent>
              <div className="imc-bar">
                <Bar options={imcOptions} data={imc} />
              </div>
            </IonCardContent>
          </IonCard>
          <IonCard style={{marginLeft: '0', marginRight: '0'}}>
            <IonCardHeader>
              <IonCardSubtitle>
                <IonLabel children={'Composición corporal'} color={'primary'} />
              </IonCardSubtitle>
            </IonCardHeader>
            <IonList>
              <IonListHeader >
                <IonLabel style={{ fontSize: '16px' }} children={'Musculatura'} />
              </IonListHeader>
              <IonItem>
                <IonRow>
                  <IonCol>
                    <IonBadge children={`${bodyKg[0]} KG`} color={'light'} />
                  </IonCol>
                  <IonCol>
                    <IonBadge children={`${bodyCompositionPercent[0]} %`} color={'light'} />
                  </IonCol>
                  <IonCol>
                    <IonBadge children={'ref: M > 34, H > 42'} color={'light'} />
                  </IonCol>
                </IonRow>
              </IonItem>
              <IonListHeader >
                <IonLabel style={{ fontSize: '16px' }} children={'Grasa'} />
              </IonListHeader>
              <IonItem>
                <IonRow>
                  <IonCol>
                    <IonBadge children={`${bodyKg[1]} KG`} color={'light'} />
                  </IonCol>
                  <IonCol>
                    <IonBadge children={`${bodyCompositionPercent[1]} %`} color={'light'} />
                  </IonCol>
                  <IonCol>
                    <IonBadge children={'ref: M 21-32, H 8-19'} color={'light'} />
                  </IonCol>
                </IonRow>
              </IonItem>
              <IonListHeader >
                <IonLabel style={{ fontSize: '16px' }} children={'Hueso'} />
              </IonListHeader>
              <IonItem>
                <IonRow>
                  <IonCol>
                    <IonBadge children={`${bodyKg[2]} KG`} color={'light'} />
                  </IonCol>
                  <IonCol>
                    <IonBadge children={`${bodyCompositionPercent[2]} %`} color={'light'} />
                  </IonCol>
                  <IonCol>
                    <IonBadge children={'ref: 12 a 16'} color={'light'} />
                  </IonCol>
                </IonRow>
              </IonItem>
            </IonList>
            <IonCardContent>
              <Pie options={composicionCorporalOptions} data={bodyPercent} />
            </IonCardContent>
          </IonCard>
          <IonCard style={{marginLeft: '0', marginRight: '0'}}>
            <IonCardHeader>
              <IonCardSubtitle>
                <IonLabel children={'COMPOSICIÓN DE EXTREMIDADES'} color={'primary'} />
              </IonCardSubtitle>
            </IonCardHeader>
            <IonList>
              <IonListHeader >
                <IonLabel style={{ fontSize: '16px' }} children={'% Musculatura'} />
              </IonListHeader>
              <IonItem>
                <IonRow>
                  <IonCol>
                    <IonBadge children={`Brazo ${extremitiesCompositionMuscle[0]}`} color={'light'} />
                  </IonCol>
                  <IonCol>
                    <IonBadge children={`Muslo ${extremitiesCompositionMuscle[1]}`} color={'light'} />
                  </IonCol>
                  <IonCol>
                    <IonBadge children={`Pantorrilla ${extremitiesCompositionMuscle[2]}`} color={'light'} />
                  </IonCol>
                </IonRow>
              </IonItem>
              <IonListHeader >
                <IonLabel style={{ fontSize: '16px' }} children={'% Grasa'} />
              </IonListHeader>
              <IonItem>
                <IonRow>
                  <IonCol>
                    <IonBadge children={`Brazo ${extremitiesCompositionFat[0]}`} color={'light'} />
                  </IonCol>
                  <IonCol>
                    <IonBadge children={`Muslo ${extremitiesCompositionFat[1]}`} color={'light'} />
                  </IonCol>
                  <IonCol>
                    <IonBadge children={`Pantorrilla ${extremitiesCompositionFat[2]}`} color={'light'} />
                  </IonCol>
                </IonRow>
              </IonItem>
            </IonList>
            <IonCardContent>
              <Bar options={composicionExtremidadesOptions} data={extremitiesComposition} />
            </IonCardContent>
          </IonCard>
          <IonCard style={{marginLeft: '0', marginRight: '0'}}>
            <IonCardHeader>
              <IonCardSubtitle>
                <IonLabel children={'RIESGO METABÓLICO'} color={'primary'} />
              </IonCardSubtitle>
            </IonCardHeader>
            <IonList>
              <IonListHeader >
                <IonLabel style={{ fontSize: '16px' }} children={'Circunferencia de cintura'} />
              </IonListHeader>
              <IonItem>
                <IonRow>
                  <IonCol>
                    <IonBadge children={`Medidas ${metabolicRisk[0]}`} color={'light'} />
                  </IonCol>
                  <IonCol>
                    <IonBadge children={`${checkCC(metabolicRisk[0])}`} color={'light'} />
                  </IonCol>
                  <IonCol>
                    <IonBadge children={`ref: M < 85, H < 90`} color={'light'} />
                  </IonCol>
                </IonRow>
              </IonItem>
              <IonListHeader >
                <IonLabel style={{ fontSize: '16px' }} children={'Índice cintura estatura'} />
              </IonListHeader>
              <IonItem>
                <IonRow>
                  <IonCol>
                    <IonBadge children={`Medidas: ${metabolicRisk[1]}`} color={'light'} />
                  </IonCol>
                  <IonCol>
                    <IonBadge children={`${checkICE(metabolicRisk[1])}`} color={'light'} />
                  </IonCol>
                  <IonCol>
                    <IonBadge children={`ref: M < 53.5, H < 52.5`} color={'light'} />
                  </IonCol>
                </IonRow>
              </IonItem>
              <IonListHeader >
                <IonLabel style={{ fontSize: '16px' }} children={'Índice cintura cadera'} />
              </IonListHeader>
              <IonItem>
                <IonRow>
                  <IonCol>
                    <IonBadge children={`Medidas: ${metabolicRisk[2]}`} color={'light'} />
                  </IonCol>
                  <IonCol>
                    <IonBadge children={`${checkICC(metabolicRisk[2])}`} color={'light'} />
                  </IonCol>
                  <IonCol>
                    <IonBadge children={`ref: M < 0.75, H < 0.90`} color={'light'} />
                  </IonCol>
                </IonRow>
              </IonItem>
            </IonList>
          </IonCard>
          <IonCard style={{marginLeft: '0', marginRight: '0'}}>
            <IonCardHeader>
              <IonCardSubtitle>
                <IonLabel children={'SOMATOTIPO'} color={'primary'} />
              </IonCardSubtitle>
            </IonCardHeader>
            <IonCardContent>
              <Bar options={somatotipoOptions} data={somatype} />
            </IonCardContent>
            <IonCardContent id="somatocarta">
              <svg ref={svgRef} />
            </IonCardContent>
          </IonCard>
        </MyContent>
      </IonPage>
      <MyLoading
        isOpen={isActive}
        toggleLoading={toggleLoading}
      />
    </>
  )
};

export default Report;
