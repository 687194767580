import React, { useContext, useEffect, useState, useRef } from "react";
import { IonPage, IonButton, IonGrid, IonRow, IonCol, IonIcon, IonLabel, IonItem, IonAlert, useIonAlert } from "@ionic/react";
import { Header, MyContent, MyToast } from "../../components/generic/Index";
import { RouteComponentProps, useLocation } from "react-router-dom";
import { AppContext } from "../../data/state";
import { chevronForwardOutline } from "ionicons/icons";
import server from "../../api/server";
import { useDispatch } from "../../hooks/useDispatch";
import { checkToken } from "../../lib/checkToken";
import ProfileItemSkeleton from "../../components/custom/Profile/ProfileItemSkeleton";

import "./ViewProfile.css";

interface OwnProps extends RouteComponentProps { }

const Profile: React.FC<OwnProps> = ({ history }) => {
  const { state, dispatch } = useContext(AppContext);
  const [showAlertLogout, setShowAlertLogout] = useState(false);
  const { dispatchAction } = useDispatch();
  const [present] = useIonAlert();
  const [isLoading, setIsLoading] = useState(true);

  const [toastMessage, setToastMessage] = useState("Ingreso satisfactorio.");
  const [isToastOpen, setIsToastOpen] = useState(false);
  const [toastColor, setToastColor] = useState("primary");

  const ref = useRef<any>(null);

  const location = useLocation();

  var aux = false;

  useEffect(() => {
     if(location.state !== undefined) {
      aux = location.state?.isQRok;
     }
     setIsToastOpen(aux);
    if (state.isLoggedin) {
      checkStatusToken();
    }

  }, [location]);

  useEffect(() => {
    let menu = document.getElementById("moveMenu");
    menu?.removeAttribute("disabled");

    ref.current = setInterval(logOutUser, 43200000);
    return () => {
      if (ref.current) {
        clearInterval(ref.current);
      }
    };
  }, []);

  const checkStatusToken = async () => {
    setIsLoading(true);
    try {
      await checkToken(state.token);
      setTimeout(() => {setIsLoading(false) }, 2000);
    } catch (error: any) {
      setIsLoading(false);
      if (error.message === "Token expired") {
        present(`Tu sesión ha expirado por favor ingresa tus credenciales de nuevo.`, [{
          text: "OK", role: "confirm", handler: () => {
            dispatchAction();
            history.replace("/home");
          }
        }]);
      }
    }
  };

  const clickLogOut = () => {
    setShowAlertLogout(true);
  };

  const clickAcceptLogout = async () => {
    setIsLoading(true);

    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${state.token}`,
    };
    try {
      await server.get('/auth/logout', { headers: headers });
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
    history.replace("/home");
  };

  const profileModules = [
    {
      name: "Mi Plan",
      onclick: () => {
        history.push("/profile/myPlan");
      },
    },
    {
      name: "Datos de contacto",
      onclick: () => {
        history.push("/profile/contact");
      },
    },
    {
      name: "Salud",
      onclick: () => {
        history.push("/profile/healthInfo");
      },
    },
  ];

  const logOutUser = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${state.token}`,
    };
    if (!state.requestedUnklinkDevice) {
      try {
        const response = await server.get("/user", { headers: headers });
        if (!response.data.data.unlinkApproved) {
          await dispatch({
            type: "set-token",
            token: undefined,
          });
          await dispatch({
            type: "set-requested-unlink-device",
            requestedUnklinkDevice: false,
          });
          clickLogOut();
        }
      } catch (error) {
        console.warn(error);
      }
    }
  };

  return (
    <IonPage>
      <Header
        title="Perfil"
        titleColor="primary"
        isBackButton={false}
        isMenu={true}
        menuColor="primary"
      />
      {
        isLoading ? (
          <ProfileItemSkeleton />
        ) : (
          <MyContent>
            <br />
            <IonGrid className="profile-segment">
              <IonRow>
                <IonCol size="6">
                  <IonLabel className="user-name">
                    {state.user === undefined ? "" : state.user.name}
                  </IonLabel>
                  <IonLabel className="user-name">
                    {aux}
                  </IonLabel>
                  <br />
                  <br />
                  <IonLabel color="primary" className="memberID">
                    Socio:&nbsp;
                    {state.user === undefined
                      ? ""
                      : state.user?.memberIDTrimmed ?? ""}
                  </IonLabel>
                  <br />
                  <IonLabel color="primary">
                    {state.user?.club?.name ?? ""}
                  </IonLabel>
                </IonCol>
                <IonCol size="6">
                  <img
                    alt="user pic"
                    className="profile-rounded"
                    src={
                      state.user?.image
                        ? `data:image/png;base64,${state.user?.image}`
                        : "/assets/img/icon_usuario.svg"
                    }
                  />
                </IonCol>
              </IonRow>
              <hr className="hrMargin" />
            </IonGrid>
            <IonGrid className="profile-modules">
              {profileModules.map((element, i) => {
                return (
                  <IonItem lines="none" key={i} onClick={element.onclick}>
                    {element.name}
                    <IonIcon icon={chevronForwardOutline}></IonIcon>
                  </IonItem>
                );
              })}
              <hr className="hrMargin" />
            </IonGrid>
            <IonButton color="white" fill="clear" onClick={() => clickLogOut()}>
              Cerrar sesión
            </IonButton>
          </MyContent>
        )
      }
      
      <IonAlert
        isOpen={showAlertLogout}
        onDidDismiss={() => setShowAlertLogout(false)}
        backdropDismiss
        animated
        keyboardClose
        cssClass="alert-logout-style"
        header="Cerrar sesión"
        message={
          "<strong>¿Desea continuar? </strong> Debera ingresar sus credenciales si quiere iniciar sesión de nuevo."
        }
        buttons={[
          {
            text: "Cancelar",
            role: "cancel",
            cssClass: "alert-cancel-option-style",
            handler: () => setShowAlertLogout(false),
          },
          {
            text: "Aceptar",
            handler: () => {
              clickAcceptLogout();
            },
          },
        ]}
      />
       <MyToast
        message={toastMessage}
        isOpen={isToastOpen}
        onDismiss={() => setIsToastOpen(false)}
        color={toastColor}
        position="bottom"
      />
    </IonPage>
  );
};

export default Profile;
