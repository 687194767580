import React, { useState, useContext, useEffect } from "react";
import { IonPage } from "@ionic/react";
import { RouteComponentProps, useHistory} from "react-router-dom";
import { AppContext } from "../../data/state";
import { Header, MyContent, MyTitle, MyToast } from '../../components/generic/Index';
import { MyClubCard, MyClubCardSkeleton } from "../../components/custom/Index";
import server from "../../api/server";

import "./ClubList.css";

interface OwnProps extends RouteComponentProps { }

const ClubList: React.FC<OwnProps> = ({}) => {
  const history = useHistory();

  const { state } = useContext(AppContext);
  const [clubList, setClubList] = useState([]);
  const [isToastOpen, setIsToastOpen] = useState(false);

  useEffect(() => {
    getClubList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getClubList = async () => {
    try {
      const res = await server.get("/club");
      const response = res.data;
      setClubList(response.data);
    } catch (error: any) {
      console.log(error);
      setClubList([]);
      setIsToastOpen(true);
    }
  };

  const showClub = (id: any) => {
    history.push(`/clublist/${id}`);
  };

  return (
    <IonPage>
      {state.isSignIn ? (
        <Header
          title="Sucursales"
          titleColor="primary"
          isBackButton={true}
          defaultHref="/home"
          isMenu={false}
          menuColor="primary"
        />
      ) : (
        <Header
          title="Sucursales"
          titleColor="primary"
          isBackButton={false}
          isMenu={true}
          menuColor="primary"
        />
      )}

      <MyContent hasRefresher refreshMethod={getClubList}>
        <MyTitle title="Gimnasios" />
        {
          clubList.length > 0 ? 
            <MyClubCard 
              info={clubList} 
              showClub={(id: any) => showClub(id)}
            /> 
          : 
            <MyClubCardSkeleton />
        }
      </MyContent>

      <MyToast
        isOpen={isToastOpen}
        onDismiss={() => setIsToastOpen(false)}
        color='danger'
        message={"Hubo un error, intente de nuevo mas tarde"}
      />

    </IonPage>
  );
};

export default ClubList;
