import React from "react";
import { MyContent } from "../../generic/Index";

import {
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
  IonSkeletonText,
  IonThumbnail,
  IonLabel,

} from "@ionic/react";

interface ProfileItemSkeletonProps {
  count?: number;
}

const ProfileItemSkeleton: React.FC<ProfileItemSkeletonProps> = ({ count }) => {
  const skeletons = [];
  skeletons.push(
    <MyContent>
      <br />
      <IonGrid className="profile-segment">
        <IonRow>
          <IonCol size="6">
            <IonSkeletonText animated style={{ width: "25vw", height: "24px" }} />
            <br />
            <IonLabel>
              <h2>
                <IonSkeletonText animated style={{ width: "25vw", height: "24px" }} />
              </h2>
              <h2>
                <IonSkeletonText animated style={{ width: "25vw", height: "24px" }} />
              </h2>
            </IonLabel>
          </IonCol>
          <IonCol size="6">
            <IonThumbnail >
              <IonSkeletonText animated style={{ borderRadius: "50%", width: "30vw", height: "30vw"}}/>
            </IonThumbnail>
          </IonCol>
        </IonRow>
        <hr className="hrMargin" />
      </IonGrid>
      <IonGrid className="profile-modules">
        <IonSkeletonText animated style={{ width: "40vw", height: "24px" }} />
        <IonSkeletonText animated style={{ width: "40vw", height: "24px" }} />
        <IonSkeletonText animated style={{ width: "40vw", height: "24px" }} />
        <hr className="hrMargin" />
      </IonGrid>
      <IonButton color="white" fill="clear" onClick={() => { }}>
        <IonSkeletonText animated style={{ width: "25vw", height: "24px" }} />
      </IonButton>
    </MyContent>
  );

  return <>{skeletons}</>;
};

export default ProfileItemSkeleton;
