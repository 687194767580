import {
  IonPage,
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonLabel,
  IonPopover,
  IonItem,
  IonButton,
  IonAlert,
  IonLoading
} from '@ionic/react';
import React, { useState, useContext, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Header, MInput } from "../../components/generic/Index";
import { AppContext } from '../../data/state';
import server from '../../api/server';
import { formatDistanceToNow } from 'date-fns';
import { ellipsisVertical } from 'ionicons/icons';

import './Contact.css';

interface OwnProps extends RouteComponentProps { };

const HealthInfo: React.FC<OwnProps> = ({ history }) => {
  const { state, dispatch } = useContext(AppContext);
  const [allergies, setAllergies] = useState('');
  const [diseases, setDiseases] = useState('');
  const [injuries, setInjuries] = useState('');
  const [weight, setWeight] = useState('');
  const [height, setHeight] = useState('');
  const [imc, setIMC] = useState('');
  const [birthdate, setBirthdate] = useState('');
  const [age, setAge] = useState('');

  const [allergiesCopy, setAllergiesCopy] = useState('');
  const [diseasesCopy, setDiseasesCopy] = useState('');
  const [injuriesCopy, setInjuriesCopy] = useState('');
  const [weightCopy, setWeightCopy] = useState('');
  const [heightCopy, setHeightCopy] = useState('');
  const [imcCopy, setIMCCopy] = useState('');
  const [birthdateCopy, setBirthdateCopy] = useState('');
  const [ageCopy, setAgeCopy] = useState('');

  const [showPopover, setShowPopover] = useState<{ open: boolean, event: Event | undefined }>({
    open: false,
    event: undefined,
  });
  const [editData, setEditData] = useState(false);
  const [showSaving, setShowSaving] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [errorAtSearch, setErrorAtSearch] = useState(false);

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${state.token}`
  };

  const userInfo = [{
    id: 'FechaDeNacimiento',
    name: 'Fecha de nacimiento:',
    inputName: 'birthdate',
    detail: birthdate,
    editableDetail: birthdateCopy,
    inputtype: "text",
    editable: false,
    unit: "",
    setter: setBirthdateCopy
  }, {
    id: 'Edad',
    name: 'Edad: ',
    inputName: 'Edad',
    detail: age,
    editableDetail: ageCopy,
    inputtype: "numeric",
    editable: false,
    enterkeyhint: "next",
    unit: "años",
    setter: setAgeCopy
  }, {
    id: 'Peso',
    name: 'Peso: ',
    inputName: 'Peso',
    detail: weight,
    editableDetail: weightCopy,
    inputtype: "decimal",
    editable: true,
    enterkeyhint: "next",
    unit: "kilos",
    setter: setWeightCopy
  }, {
    id: 'Altura',
    name: 'Altura: ',
    inputName: 'Altura',
    detail: height,
    editableDetail: heightCopy,
    inputtype: "decimal",
    editable: true,
    enterkeyhint: "next",
    unit: "metros",
    setter: setHeightCopy
  }, {
    id: 'IMC',
    name: 'IMC: ',
    inputName: 'IMC',
    detail: imc,
    editableDetail: imcCopy,
    inputtype: "text",
    editable: false,
    unit: "",
    setter: setIMCCopy
  }];

  const userDiseases = [{
    Id: 'Lesiones',
    name: 'Lesiones:',
    inputName: 'Lesiones',
    detail: injuries,
    editableDetail: injuriesCopy,
    inputtype: "text",
    enterkeyhint: "next",
    setter: setInjuriesCopy
  }, {
    id: 'Cronicas',
    name: 'Enfermedades crónicas: ',
    inputName: 'Cronicas',
    detail: diseases,
    editableDetail: diseasesCopy,
    inputtype: "text",
    enterkeyhint: "next",
    setter: setDiseasesCopy
  }, {
    id: 'Alergias',
    name: 'Alergias:',
    inputName: 'Alergias',
    detail: allergies,
    editableDetail: allergiesCopy,
    inputtype: "text",
    enterkeyhint: "next",
    setter: setAllergiesCopy
  }];

  useEffect(() => {
    // No await effect is reached from here
    getUserHealth();
  }, []);

  const getUserHealth = async () => {
    setShowLoading(true);
    await server.get('/user/health', { headers: headers })
      .then(async (res) => {
        const response = res.data;
        if (response.statusCode != 200) {
          console.log('error');
          return false;
        }
        let birthDate = new Date(response.data.birthDate);
        setAllergies(response.data.allergies);
        setDiseases(response.data.diseases);
        setHeight(response.data.height);
        setInjuries(response.data.injuries);
        setWeight(response.data.weight);
        /*
        setBirthdate(
          String(birthDate.getDate()).padStart(2, '0') + '/' +
          String(birthDate.getMonth() + 1).padStart(2, '0') + '/' +
          String(birthDate.getFullYear())
        );
         */
        setBirthdate(
          String(birthDate.getFullYear()) + '/' +
          String(birthDate.getMonth() + 1).padStart(2, '0') + '/' +
          String(birthDate.getDate()).padStart(2, '0')
        );
        setAllergiesCopy(response.data.allergies);
        setDiseasesCopy(response.data.diseases);
        setHeightCopy(response.data.height);
        setInjuriesCopy(response.data.injuries);
        setWeightCopy(response.data.weight);
        setBirthdateCopy(
          String(birthDate.getFullYear()) + '/' +
          String(birthDate.getMonth() + 1).padStart(2, '0') + '/' +
          String(birthDate.getDate()).padStart(2, '0')
        );
        if (response.data.height !== '' && response.data.weight !== '') {
          if (response.data.height === 0 || response.data.weight === 0) {
            setIMC('');
            setIMCCopy('');
          }
          else {
            setIMC(
              (parseInt(response.data.weight) /
                Math.pow((
                  //@ts-ignore
                  (response.data.height > 100 ? parseFloat((response.data.height) / 100) : response.data.height)
                ), 2)
              ).toFixed(1).toString()
            );
            setIMCCopy(
              (parseInt(response.data.weight) /
                Math.pow((
                  //@ts-ignore
                  (response.data.height > 100 ? parseFloat((response.data.height) / 100) : response.data.height)
                ), 2)
              ).toFixed(1).toString()
            );
          }
        }
        setAge(state.user.age);
        setAgeCopy(state.user.age);

      })
      .catch((error) => {
        console.log(error);
      }
      );
    setShowLoading(false);
  }

  const handleEdit = () => {
    setEditData(true);
    setShowPopover({ open: false, event: undefined });
  }

  const _onUpdateData = async () => {
    setShowSaving(true);

    const data = {
      BirthDate: birthdateCopy,
      Weight: (weightCopy !== '') ? parseFloat(weightCopy) : 0,
      Height: (heightCopy !== '') ? parseFloat(heightCopy) : 0,
      Injuries: injuriesCopy,
      diseases: diseasesCopy,
      Allergies: allergiesCopy
    };

    await server.put('/user', JSON.stringify(data)
      , { headers: headers }
    ).then((res) => {
      const response = res.data;
      if (response.statusCode != 200) {
        console.log('error');
        return false;
      }

      let birthDate = new Date(response.data.birthDate);
      setAllergies(response.data.allergies);
      setDiseases(response.data.diseases);
      setHeight(response.data.height);
      setInjuries(response.data.injuries);
      setWeight(response.data.weight);
      setBirthdate(
        String(birthDate.getFullYear()) + '/' +
        String(birthDate.getMonth() + 1).padStart(2, '0') + '/' +
        String(birthDate.getDate()).padStart(2, '0')
      );
      setAllergiesCopy(response.data.allergies);
      setDiseasesCopy(response.data.diseases);
      setHeightCopy(response.data.height);
      setInjuriesCopy(response.data.injuries);
      setWeightCopy(response.data.weight);
      setBirthdateCopy(
        String(birthDate.getFullYear()) + '/' +
        String(birthDate.getMonth() + 1).padStart(2, '0') + '/' +
        String(birthDate.getDate()).padStart(2, '0')
      );
      if (response.data.height !== '' && response.data.weight !== '') {
        if (response.data.height === 0 || response.data.weight === 0) {
          setIMC('');
          setIMCCopy('');
        }
        else {
          setIMC(
            (parseInt(response.data.weight) /
              Math.pow((
                //@ts-ignore
                (response.data.height > 100 ? parseFloat((response.data.height) / 100) : response.data.height)
              ), 2)
            ).toFixed(1).toString()
          );
          setIMCCopy(
            (parseInt(
              response.data.weight) /
              Math.pow((
                //@ts-ignore
                (response.data.height > 100 ? parseFloat((response.data.height) / 100) : response.data.height)
              ), 2)
            ).toFixed(1).toString()
          );
        }
      }
      else {
        setIMC('');
        setIMCCopy('');
      }
      setAge(response.data.age);
      setAgeCopy(response.data.age);
      let copyUser = { ...state.user };
      copyUser.image = state.user?.image ?? '';
      copyUser.email = response.data.email;
      copyUser.phoneNumber = response.data.phoneNumber;
      copyUser.age = response.data.age;
      copyUser.birthDate = response.data.birthDate;
      dispatch({
        type: 'set-user',
        user: copyUser
      });

    })
      .catch((error) => {
        console.log(error);
        setErrorMessage('Error al guardar los datos.');
        setErrorAtSearch(true);
      }
      );
    setEditData(false);
    setShowSaving(false);
  }

  const cancelEdit = () => {
    setAllergiesCopy(allergies);
    setDiseasesCopy(diseases);
    setHeightCopy(height);
    setInjuriesCopy(injuries);
    setWeightCopy(weight);
    setBirthdateCopy(birthdate);
    setIMCCopy(imc);
    setAgeCopy(age);

    setEditData(false);
  }

  return (

    <IonPage>
      <Header
        title='Salud'
        titleColor='primary'
        headerClassname='ion-no-border'
        defaultHref='/profile'
        secondSlot='end'
        isSecondIcon={true}
        secondIcon={ellipsisVertical}
        onclickSecondIcon={(e) => setShowPopover({ open: true, event: e.nativeEvent })}
      />
      <IonContent>
        <IonPopover
          isOpen={showPopover.open}
          event={showPopover.event}
          onDidDismiss={() => setShowPopover({ open: false, event: undefined })}
        >
          <IonItem
            disabled={editData}
            lines='none'
            onClick={() => handleEdit()}
          >
            Editar
          </IonItem>
        </IonPopover>

        <IonGrid>
          {userInfo.map((element, i) => {
            return (
              <IonRow key={i}>
                <IonCol size='12'>
                  <IonLabel className="reduced-label" color="medium">
                    {element.name + " " + (editData ? "(" + element.unit + ")" : "")}
                  </IonLabel>
                  <br />
                  {
                    (!editData) ?
                      (element.detail) ?
                        <IonLabel>
                          {element.detail + " " + element.unit}
                        </IonLabel>
                        :
                        <br />
                      :
                      <MInput
                        inputID={element.id}
                        inputName={element.inputName}
                        value={element.editableDetail}
                        icon='none'
                        placeholder={element.detail ?? ''}
                        inputType={element.inputtype}
                        enterkeyhint={element.enterkeyhint}
                        isDisabled={!editData || !element.editable}
                        handleChangeValue={element.setter}
                      />
                  }
                </IonCol>
              </IonRow>
            );
          })}
        </IonGrid>

        <hr className='hrNoMargin' />

        <IonGrid>
          {userDiseases.map((element, i) => {
            return (
              <IonRow key={i}>
                <IonCol size='12'>
                  <IonLabel color="medium">
                    {element.name}
                  </IonLabel>
                  <br />
                  {
                    (!editData) ?
                      (element.detail) ?
                        <IonLabel>
                          {element.detail}
                        </IonLabel>
                        : <br />
                      :
                      <MInput
                        inputID={element.id}
                        inputName={element.inputName}
                        value={element.editableDetail}
                        icon='none'
                        placeholder={element.detail ?? ''}
                        inputType={element.inputtype}
                        enterkeyhint={element.enterkeyhint}
                        isDisabled={!editData}
                        handleChangeValue={element.setter}
                      />
                  }
                </IonCol>
              </IonRow>
            );
          })}
          <IonButton
            color="primary"
            fill="solid"
            onClick={() => _onUpdateData()}
            expand='block'
            hidden={!editData}
          >
            Guardar
          </IonButton>

          <IonButton
            color="danger"
            fill="outline"
            onClick={() => cancelEdit()}
            expand='block'
            hidden={!editData}
            className='ion-margin-top'
          >
            Cancelar
          </IonButton>

        </IonGrid>
        {/*editData &&
          <div>
            <IonButton
              color="primary"
              fill="solid"
              onClick={() => _onUpdateData()}
            >
              Guardar
              </IonButton>
            <IonButton
              color="danger"
              fill="outline"
              onClick={() => cancelEdit()}
            >
              Cancelar
              </IonButton>
          </div>
        */}
        <IonLoading
          cssClass='my-custom-class'
          isOpen={showSaving}
          onDidDismiss={() => { }}
          message={'Guardando...'}
          duration={0}
        />

        <IonLoading
          cssClass='my-custom-class'
          isOpen={showLoading}
          onDidDismiss={() => { }}
          message={'Cargando'}
          duration={0}
          spinner='bubbles'
        />

        <IonAlert
          isOpen={errorAtSearch}
          onDidDismiss={() => { }}
          cssClass='my-custom-class'
          header={'Error!'}
          message={errorMessage}
          buttons={[{
            text: 'Aceptar',
            role: 'Ok',
            handler: () => setErrorAtSearch(false)
          }]}
          backdropDismiss={false}
        />
      </IonContent>
    </IonPage>
  );
};

export default HealthInfo;
