import React from "react";
import { IonButton, IonContent, IonGrid, IonItem, IonLabel, IonPage, IonTextarea } from "@ionic/react";
import { RouteComponentProps } from "react-router";
import { Header } from "../../components/generic/Index";

import "./Contract.css";

interface OwnProps extends RouteComponentProps { };

const Contract: React.FC<OwnProps> = ({ history }: any) => {

    return (
        <IonPage>
            <Header
                title="Contrato"
                titleColor="primary"
                isBackButton={true}
                defaultHref="personaldata"
                menuColor="primary"
            />
            <IonContent>
                <IonGrid fixed>

                    <IonLabel color="medium" >Por favor, lea el contrato:</IonLabel>

                    <IonItem lines="none" >
                        <IonTextarea className="textarea-contract">
                            Lorem ipsum dolor sit amet consectetur adipisicing elit. In, ab, illo quas temporibus eaque perspiciatis praesentium commodi, asperiores saepe similique consectetur at voluptates laboriosam doloribus? Voluptatibus dolores velit doloribus consequatur.
                        </IonTextarea>
                    </IonItem>

                    <hr /><hr /><hr />

                    <IonLabel color="medium" >Firma:</IonLabel>

                    <IonItem lines="none" >
                        <IonTextarea className="textarea-contract">
                        </IonTextarea>
                    </IonItem>

                    <hr /><hr /><hr />

                    <IonButton className="nextbutton-contract" shape="round" color="primary" routerDirection="forward">Siguiente</IonButton>

                </IonGrid>
            </IonContent>
        </IonPage>
    )
};

export default Contract;