import React, { useRef } from "react";
import { IonContent, IonFooter, IonHeader, IonLabel, IonModal, IonTitle, IonToolbar } from "@ionic/react";
import MyButton from "../MyButton/MyButton";

interface MyModalProps {
    isOpen: boolean;
    onDidDismiss: () => void;
    title?: string,
    titleColor?: "primary" | 'secondary' | 'tertiary' | 'success' | 'warning' | 'danger' | 'light' | 'medium' | 'dark' | 'none' | undefined,
    titleSize?: 'small' | 'large' | undefined,
    breakpoints?: number[],
    initialbreakpoint?: number,
    backgroundColor?: "primary" | 'secondary' | 'tertiary' | 'success' | 'warning' | 'danger' | 'light' | 'medium' | 'dark' | 'none' | undefined,
    closeModalButtonText?: string,
    closeModalButtonColor?: "primary" | 'secondary' | 'tertiary' | 'success' | 'warning' | 'danger' | 'light' | 'medium' | 'dark' | 'none' | undefined,
    children: React.ReactNode;
}

const MyModal: React.FC<MyModalProps> = ({

    isOpen,
    onDidDismiss,
    title = 'Placeholder title',
    titleColor = 'primary',
    titleSize,
    breakpoints = [0, 0.5],
    initialbreakpoint = 1,
    backgroundColor = 'none',
    closeModalButtonText = 'Volver',
    closeModalButtonColor = 'primary',
    children

}) => {

    const modal = useRef<HTMLIonModalElement>(null);

    return (
        <IonModal
            isOpen={isOpen}
            onDidDismiss={onDidDismiss}
            ref={modal}
            initialBreakpoint={initialbreakpoint}
            breakpoints={breakpoints}
        >

            <IonHeader className="ion-no-border" >
                <IonToolbar
                color={backgroundColor}
                 style={{ textAlign: 'center' }}
                >
                    <IonLabel
                        style={{
                            fontSize: '16px',
                            fontWeight: '600'
                        }}
                        className="ion-text-wrap"
                        color={titleColor}
                        children={title}
                    />
                </IonToolbar>
            </IonHeader>

            <IonContent color={backgroundColor} className="ion-padding">
                {children}

                <MyButton
                    title={closeModalButtonText}
                    onClick={onDidDismiss}
                    expand="block"
                    color={closeModalButtonColor}
                    fill="clear"
                />
            </IonContent>
        </IonModal>
    );
};

export default MyModal;