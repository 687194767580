import React, { useEffect, useState, useContext } from "react";
import { IonItem, IonLabel, IonList, IonNote, IonPage, IonRow, IonButton } from "@ionic/react";
import { Header, MyTitle, MyActionSheet, MyLoading, MyContent, NoDataAvailable, MyFloatingButton } from "../../components/generic/Index";
import { RouteComponentProps } from "react-router";
import { AppContext } from "../../data/state";
import { Filesystem, Directory } from '@capacitor/filesystem';
import { nutritionOutline, trendingUp, archive } from "ionicons/icons";
import server from "../../api/server";
import dayjs from "dayjs";
import { FileOpener } from '@capawesome-team/capacitor-file-opener';

interface OwnProps extends RouteComponentProps { };

const Nutrition: React.FC<OwnProps> = ({ history }) => {
  
  interface Session {
    sessionNumber: number;
    id: string;
    date: string;
  };

  const { state } = useContext(AppContext);
  const noDataText = "No tienes sesiones de nutrición disponibles en este momento.";
  const [isLoading, setIsLoading] = useState(false);
  const toggleLoading = () => { setIsLoading(prevState => !prevState); };
  const [sessionsList, setSessionsList] = useState<Session[]>([]);
  const [documents, setDocuments] = useState<any[]>([]);
  const [isActionSheetOpen, setIsActionSheetOpen] = useState(false);
  const headers = { 'Content-Type': 'application/json', 'Authorization': `Bearer ${state.token}` };
  dayjs.locale('es');

  useEffect(() => {
    getSessions();
    getDocuments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getSessions = async () => {
    toggleLoading();
    await server
      .get('/customer/nutrition', { headers: headers })
      .then(async (res) => {
        const response = res.data;

        setSessionsList(response.data);
      })
      .catch((error) => {
        console.warn(error);
        setSessionsList([]);
      })
      .finally(() => {
        toggleLoading();

      })
  };

  const getDocuments = async () => {
    try {
      const headers = {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${state.token}`,
      };

      const request = await server.get(`/customer/nutrition/documents/latest`,
        { headers });
      const response = request.data;

      const newArray = response.data.map((item: any) => {
        return {
          text: item.fileName,
          role: "button",
          handler: () => downloadReports(item.id, item.fileName.replaceAll(" ", "-"))
        };
      });
      setDocuments(newArray);
    } catch (error: any) {
      console.log(error);
    }
  };

  const saveFileInSystem = async (pdfFile: any, name: any) => {
    await Filesystem.requestPermissions();
    const pathLoc = await Filesystem.writeFile({
      path: `${name}.pdf`,
      data: pdfFile,
      directory: Directory.Documents,
      recursive: true,
    });

    await FileOpener.openFile({ path: pathLoc.uri });
  };

  function blobToBase64(blob: any) {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  };

  const downloadReports = async (documentId: string, name: string) => {
    setIsLoading(true);
    try {
      const headers = {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${state.token}`,
      };

      const request = await server.get(`/customer/nutrition/document/download/${documentId}`,
        { headers: headers, responseType: 'blob' });

      const tempoBase64 = await blobToBase64(request.data);

      saveFileInSystem(tempoBase64, name);
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
    }
  };

  const completeHandle = (session: any) => {
    history.push(
      '/nutrition/results', {
      sessionId: session.id,
      sessionNumber: session.consultNum,
      sessionDate: dayjs(session.consultDate).format('DD/MMMM/YYYY'),
    }
    );
  };

  const goToProgress = () => {
    history.push('/nutrition/progress');
  };

  return (
    <>
      <IonPage>
        <Header
          title="Nutrición"
          titleColor="primary"
          isBackButton={false}
          isMenu={true}
          menuColor="primary"
        />
        <MyContent hasRefresher refreshMethod={getSessions} >
          <MyTitle title="Historial de sesiones" />
          <IonList>
            {sessionsList.map((session: any, index: number) => {
              return (
                <IonItem key={index} onClick={() => completeHandle(session)} button detail lines="none" >
                  <IonLabel >
                    <h2>Sesion #{session.consultNum}</h2>
                    <h2>{session.consultType.consultType}</h2>
                    <IonNote children={dayjs(session.consultDate).format('DD/MMMM/YYYY')} />
                  </IonLabel>
                </IonItem>
              )
            })}
            {
              sessionsList.length === 0 &&
              <NoDataAvailable
                icon={nutritionOutline}
                text={noDataText}
              />
            }
            {
              documents.length !== 0 &&
              <MyActionSheet
                isOpen={isActionSheetOpen}
                onDidDismiss={() => setIsActionSheetOpen(false)}
                header="Descargar los ultimos reportes"
                buttons={documents}
              />
            }
          </IonList>

        </MyContent>

        <MyFloatingButton
            icon={trendingUp}
            onClick={goToProgress}
            isDisabled={sessionsList.length === 0}
            isActivated={sessionsList.length === 0}
          />
          <MyFloatingButton
            icon={archive}
            onClick={() => setIsActionSheetOpen(true)}
            isDisabled={documents.length === 0}
            isActivated={documents.length === 0}
            horizontal="start"
          />

        <MyLoading
          isOpen={isLoading}
          toggleLoading={toggleLoading}
        />
      </IonPage>
    </>
  );
};

export default Nutrition;
