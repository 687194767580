import { IonToast } from "@ionic/react";
import React from 'react';

interface MyToastProps {
  isOpen: boolean;
  message: string;
  color?: "primary" | 'secondary' | 'tertiary' | 'success' | 'warning' | 'danger' | 'light' | 'medium' | 'dark' | 'none' | undefined,
  position?: 'top' | 'bottom' | 'middle';
  onDismiss: () => void;
}

const MyToast: React.FC<MyToastProps> = ({
  message,
  color,
  isOpen,
  position = 'top',
  onDismiss

}) => {

  return (
    <IonToast
      isOpen={isOpen}
      onDidDismiss={onDismiss}
      message={message}
      color={color}
      duration={3000}
      position={position}
      buttons={[{
        text: 'Cerrar',
        role: 'cancel',
        handler: () => { onDismiss() },
      }]}
    />
  );
};

export default MyToast;
